/* eslint react/no-danger: 0, react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Katex from 'katex';
import 'katex/dist/katex.min.css';

import ScreenReader from './ScreenReader';

/** Renders [kate](https://katex.org/) math blocks */
const RenderMath = ({
  alt,
  math,
  children,
  katex,
  ...rest
}) => {
  const htmlMath = Katex.renderToString(math || children, katex);
  return (
    <>
      <ScreenReader>{alt}</ScreenReader>
      <div dangerouslySetInnerHTML={{ __html: htmlMath }} {...rest} />
    </>
  );
};

RenderMath.propTypes = {
  /** A Latex string to render */
  children: PropTypes.string,
  /** A Latex string to render */
  math: PropTypes.string,
  /** Screen reader text */
  alt: PropTypes.string.isRequired,
  /** Katex options to pass through */
  katex: PropTypes.object,
};

RenderMath.defaultProps = {
  math: undefined,
  children: undefined,
  katex: {},
};

export default RenderMath;
