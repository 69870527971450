/* eslint
 no-underscore-dangle: [
  "error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__", "__REDUX_LOGGER__"] }
  ]
  */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './reducers';
import httpCli, { errorInterceptor } from './utils/httpCli';

const configureStore = (initialState = {}) => {
  const middlewares = [
    thunk,
  ];
  if (typeof window === 'object' && window.__REDUX_LOGGER__) {
    middlewares.push(logger);
  }
  const composeEnhancers = (
    typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
      : compose
  );
  const enchancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(reducers, initialState, enchancer);

  httpCli.interceptors.response.use(
    (response) => (response),
    errorInterceptor(store),
  );

  return store;
};

export default configureStore;
