import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ExampleAnswer,
  HR,
  ImageCache,
  ScreenReader,
  B,
} from '../../components';

import {
  mechanicalReasoning,
  practice,
} from '../../utils/GlobalMessages';

import {
  MrItem,
} from './MrAssessment';

import SubTestInstructions from './SubTestInstructions';

const ExampleContainer = styled.div`
  margin: 1em 15%;
`;

const ExampleDiv = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const P = styled.p`
  margin: 1em 0em;
`;

const NoDiff = {
  ranking: 3,
  value: 3,
  text: (
    <FormattedMessage
      id="mr.ex1.noDiff"
      defaultMessage="No Difference"
    />
  ),
};

const MrDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  const intl = useIntl();
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ ex1, setEx1 ] = useState();

  const [ ex2, setEx2 ] = useState();

  const [ warmCache, setWarmCache ] = useState(false);

  if (!isEmpty(assessment)) {
    const images = assessment.testItems.map((it) => (`/mr/${it.item.image}`));
    images.push('/mr/mr_ex1.jpg');
    images.push('/mr/mr_ex2.jpg');
    ImageCache(images).then(() => {
      setWarmCache(true);
    });
  }

  const example1 = {
    id: 1,
    item: {
      image: 'mr_ex1.jpg',
      alt: (intl.formatMessage({
        id: 'mr.ex1.alt',
        defaultMessage: 'Two people are carrying a load of bricks on a carrier. The load of bricks is closer to person A. than to person B.',
      })),
      text: (intl.formatMessage({
        id: 'mr.ex1.text',
        defaultMessage: 'Which person is carrying the heavier load?',
      })),
    },
    responses: [
      {
        ranking: 1,
        value: 1,
        text: 'A',
      },
      {
        ranking: 2,
        value: 2,
        text: 'B',
      },
      NoDiff,
    ],
  };

  const example2 = {
    id: 2,
    item: {
      image: 'mr_ex2.jpg',
      alt: (intl.formatMessage({
        id: 'mr.ex2.alt',
        defaultMessage: 'A submarine is underwater.',
      })),
      text: intl.formatMessage({
        id: 'mr.ex2.text',
        defaultMessage: 'In which direction will the submarine move when the control fins are turned down?',
      }),
    },
    responses: [
      {
        ranking: 1,
        value: 1,
        text: (
          <FormattedMessage
            id="mr.ex2.a"
            defaultMessage="Dive"
          />
        ),
      },
      {
        ranking: 2,
        value: 2,
        text: (
          <FormattedMessage
            id="mr.ex2.b"
            defaultMessage="Surface"
          />
        ),
      },
      NoDiff,
    ],
  };

  return (
    <SubTestInstructions
      abbr="mr"
      busy={loading || !warmCache}
      startTimer={startTimer}
      tagLine={{
        id: 'mr.directions.tagline',
        defaultMessage: 'This is an assessment of mechanical facts and principles',
      }}
      title={mechanicalReasoning}
    >
      <p>
        <FormattedMessage
          id="mr.directions.select"
          defaultMessage="Look at each picture, read each question, and select
          the best answer. If you wish to change your answer, select the new
          one."
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practice}
          />
        </em>
      </p>
      <ExampleContainer>
        <ExampleDiv id="ex1_label">
          <ScreenReader>Example 1</ScreenReader>
          <MrItem
            testItem={example1}
            keyId={`example1_${warmCache}`}
            onChange={({ response }) => setEx1(response.value)}
          />
        </ExampleDiv>
        <ExampleAnswer
          value={ex1}
          correct={1}
        >
          <P>
            <FormattedMessage
              id="mr.ex1.correct"
              defaultMessage="The correct answer is <b>A</b>. A carries the
              heavier load because the bricks are closer to A, than to B."
              values={{ b: B }}
            />
          </P>
        </ExampleAnswer>
        <HR />
        <ExampleDiv id="ex2_label">
          <ScreenReader>Example 2</ScreenReader>
          <MrItem
            testItem={example2}
            keyId={`example2_${warmCache}`}
            onChange={({ response }) => setEx2(response.value)}
          />
        </ExampleDiv>
        <ExampleAnswer
          value={ex2}
          correct={2}
        >
          <P>
            <FormattedMessage
              id="mr.ex2.correct"
              defaultMessage="The correct answer is <b>Surface</b>.  The
              submarine will surface since the flow of water adds buoyancy by
              pushing up beneath the fins."
              values={{ b: B }}
            />
          </P>
        </ExampleAnswer>
      </ExampleContainer>
    </SubTestInstructions>
  );
};

export default MrDirections;
