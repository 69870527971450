import React, { useState } from 'react';
import styled from 'styled-components';
import { parseInt, isFinite } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Colors,
  Content,
  Fade,
  H1,
  ScreenReader,
  Select,
  SmallButton,
  UpperCaseMessage,
} from '../../components';

import {
  download,
  errorNumber,
  finish as finishMessage,
  msd,
  no,
  yes,
} from '../../utils/GlobalMessages';

let apiPath = process.env.REACT_APP_API || 'https://api.viasystem.com/v1/';
apiPath = apiPath.replace('/v1/', '');

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
`;

const CH1 = styled(H1)`
  text-align: center;
`;

const CP = styled.p`
  text-align: center;
`;

const SB = styled(SmallButton)`
  margin: 1em;
`;

const PaperContainer = styled.div`
  text-align: center;
`;

const FinishButton = styled(SmallButton)`
  margin: 1em auto;
`;

const MsdAssessment = ({
  assessment,
  finish,
  locale,
  saveItem,
  status,
}) => {
  const [ isPaper, setIsPaper ] = useState();

  const [ formError, setFormError ] = useState();

  const {
    userResponse: {
      choice: {
        value: initalValue,
      } = {},
    } = {},
  } = assessment.testItems[0];

  const [ value, setValue ] = useState(initalValue || '');

  const step1 = (
    <>
      <ScreenReader>
        If you are using a screen reader, click here:
        <Button onClick={() => isPaper(false)}>Screen Reader Skip</Button>
      </ScreenReader>
      <Fade
        appear
        timeout={1000}
      >
        <div>
          <CP>
            <FormattedMessage
              id="msd.assessment.printer"
              defaultMessage="Do you have a printer or did your administrator
              give you the paper assessment?"
            />
          </CP>
          <ButtonContainer>
            <SB
              onClick={() => setIsPaper(true)}
            >
              <UpperCaseMessage
                {...yes}
              />
            </SB>
            <SB
              secondary
              onClick={() => setIsPaper(false)}
            >
              <UpperCaseMessage
                {...no}
              />
            </SB>
          </ButtonContainer>
        </div>
      </Fade>
    </>
  );

  const paper = (
    <Fade
      appear
      timeout={1000}
    >
      <PaperContainer>
        <p>
          <FormattedMessage
            id="msd.assessment.link"
            defaultMessage="Click on the following link to download the
            assessment and print it out."
          />
        </p>
        <a href={`${apiPath}/${locale}-msd.pdf`} target="_blank" rel="noopener noreferrer">
          <FormattedMessage
            {...download}
          />
        </a>
        <p>
          <FormattedMessage
            id="msd.assessment.enter"
            defaultMessage="Once you have taken the assessment, enter your score here:"
          />
        </p>
        <input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            const raw = parseInt(e.target.value);
            const min = assessment.testItems[0].responses[0];
            const max = assessment.testItems[0].responses[
              assessment.testItems[0].responses.length - 1
            ];
            if (!isFinite(raw)) {
              return setFormError(
                <FormattedMessage
                  {...errorNumber}
                />,
              );
            }
            const response = assessment.testItems[0].responses[raw];
            if (!response) {
              return setFormError(
                <FormattedMessage
                  id="msd.assessment.between"
                  defaultMessage="Please enter a number between {messageMin} and {messageMax}"
                  values={{
                    messageMin: min.text,
                    messageMax: max.text,
                  }}
                />,
              );
            }
            setFormError(undefined);
            return saveItem({
              testStatus: status,
              testItem: assessment.testItems[0],
              response,
            });
          }}
        />
        {
          formError ? (<p>{formError}</p>) : ''
        }
        <FinishButton
          disabled={
            assessment.testItems[0].userResponse === undefined
            || formError !== undefined
          }
          onClick={() => {
            finish(status);
          }}
        >
          <UpperCaseMessage
            {...finishMessage}
          />
        </FinishButton>
        <Button
          link
          onClick={() => {
            setIsPaper(false);
            setFormError(undefined);
          }}
        >
          <FormattedMessage
            id="msd.assessment.noPaper"
            defaultMessage="I do not have a printer or the paper test."
          />
        </Button>
      </PaperContainer>
    </Fade>
  );

  const estimate = (
    <Fade
      appear
      timeout={1000}
    >
      <PaperContainer>
        <p>
          <FormattedMessage
            id="msd.assessment.estimate"
            defaultMessage="Please estimate your manual speed and dexterity score:"
          />
        </p>
        <Select
          allowEmpty={value === undefined}
          onChange={(e) => {
            const raw = parseInt(e.target.value);
            const response = assessment.testItems[0].responses[raw];
            return saveItem({
              testStatus: status,
              testItem: assessment.testItems[0],
              response,
            });
          }}
        >
          <FormattedMessage
            id="msd.assesssment.estiamte.below"
            defaultMessage="Below average"
          >
            {(txt) => (<option value="1">{txt}</option>)}
          </FormattedMessage>
          <FormattedMessage
            id="msd.assesssment.estiamte.average"
            defaultMessage="Average"
          >
            {(txt) => (<option value="235">{txt}</option>)}
          </FormattedMessage>
          <FormattedMessage
            id="msd.assesssment.estiamte.above"
            defaultMessage="Above average"
          >
            {(txt) => (<option value="300">{txt}</option>)}
          </FormattedMessage>
        </Select>
        {
          formError ? (<p>{formError}</p>) : ''
        }
        <FinishButton
          disabled={
            assessment.testItems[0].userResponse === undefined
            || formError !== undefined
          }
          onClick={() => {
            finish(status);
          }}
        >
          <UpperCaseMessage
            {...finishMessage}
          />
        </FinishButton>
        <Button
          link
          onClick={() => setIsPaper(true)}
        >
          <FormattedMessage
            id="msd.assessment.havePaper"
            defaultMessage="I would like to take the paper assessment."
          />
        </Button>
      </PaperContainer>
    </Fade>
  );

  return (
    <Content>
      <CH1 color={Colors.green}>
        <UpperCaseMessage
          {...msd}
        />
      </CH1>
      {
        isPaper === undefined
          ? step1 : ''
      }
      {
        isPaper
          ? paper : ''
      }
      {
        isPaper === false
          ? estimate : ''
      }
    </Content>
  );
};

export default MsdAssessment;
