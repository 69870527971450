/*
 * opts = {
 *  customOffset
 * }
 */
export const scrollTo = (
  ref,
  {
    customOffset = 0,
  } = {},
) => {
  if (!ref || !ref.current) {
    return;
  }
  const { top } = ref.current.getBoundingClientRect();
  const offset = 173;
  window.scrollTo({
    top: top + window.pageYOffset - offset + customOffset,
    behavior: 'smooth',
  });
};

export const elementScrollTo = (element, ref, opts = { offset: 150 }) => {
  if (!ref || !ref.current || !element || !element.current) {
    return;
  }
  const top = ref.current.offsetTop;
  element.current.scroll({
    top: top - opts.offset,
    behavior: 'smooth',
  });
};

export default scrollTo;
