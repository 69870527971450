import { connect } from 'react-redux';
import MrAssessmentComponent from '../components/MrAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const MrAssessmentContainer = connect(
  mapStateToProps({ ipp: 4 }),
  mapDispatchToProps(),
)(MrAssessmentComponent);

export default withRouter(MrAssessmentContainer);
