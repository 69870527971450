import { connect } from 'react-redux';
import PsaAssessmentComponent from '../components/PsaAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const PsaAssessmentContainer = connect(
  mapStateToProps({ ipp: 40 }),
  mapDispatchToProps(),
)(PsaAssessmentComponent);

export default withRouter(PsaAssessmentContainer);
