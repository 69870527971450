import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';

import ScreenReader from '../components/ScreenReader';
import { white } from '../components/Colors';

const TypeaheadContainer = styled.div`
  display: inline-block;
`;

const Suggestions = styled.fieldset`
  position: absolute;
  background-color: ${white};
  width: 100%;
`;

const MappedChoice = styled.div``;

const Typeahead = ({
  id,
  name,
  choices,
  onChange,
}) => {
  const [ inputText, setInputText ] = useState('');

  const handleChange = (e, c) => {
    setInputText(c.label);
    onChange(e, c);
  };

  const handleText = (e) => {
    setInputText(e.target.value);
  };

  const mappedChoices = choices.filter((c) => (
    c.label.toLowerCase().includes(inputText.toLowerCase())
  )).map((c) => (
    <MappedChoice>
      <label
        htmlFor={c.id}
        key={`${c.id}_key`}
      >
        <input
          type="radio"
          id={c.id}
          key={`${c.id}_choice`}
          value={c.value}
          onChange={(e) => handleChange(e, c)}
          name={name}
        />
        {c.label}
      </label>
    </MappedChoice>
  ));
  return (
    <TypeaheadContainer>
      <input
        id={id}
        aria-describedby={`${id}_help`}
        type="text"
        value={inputText}
        onChange={handleText}
      />
      <Suggestions>
        <legend><ScreenReader>Suggestions</ScreenReader></legend>
        {mappedChoices}
      </Suggestions>
      <div id={`${id}_help`}>
        Provides auto-suggestions when entering text
      </div>
    </TypeaheadContainer>
  );
};

export default Typeahead;
