import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  ExampleAnswer,
  HR,
  B,
} from '../../components';

import {
  WkItem,
} from './WkAssessment';

import {
  practice,
  wordKnowledge,
} from '../../utils/GlobalMessages';

import SubTestInstructions from './SubTestInstructions';

const WkDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ Ex1, setEx1 ] = useState();
  const [ Ex2, setEx2 ] = useState();

  return (
    <SubTestInstructions
      abbr="wk"
      busy={loading}
      startTimer={startTimer}
      tagLine={{
        id: 'wk.directions.tagline',
        defaultMessage: 'This is an assessment of word knowledge',
      }}
      title={wordKnowledge}
    >
      <p>
        <FormattedMessage
          id="wk.directions.directions"
          defaultMessage="Following are key words and definitions. Each key
          word is followed by five definition words. Select the definition word
          which has the closest meaning to the key word. If you wish to change
          your answer, select the new one."
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practice}
          />
        </em>
      </p>
      <WkItem
        testItem={{
          id: 1,
          item: {
            text: (
              <FormattedMessage
                id="wk.ex1.item"
                defaultMessage="MERRY"
              />
            ),
          },
          responses: [
            {
              text: (
                <FormattedMessage
                  id="wk.ex1.res1"
                  defaultMessage="sad"
                />
              ),
              value: 1,
              ranking: 1,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex1.res2"
                  defaultMessage="indifferent"
                />
              ),
              value: 2,
              ranking: 2,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex1.res3"
                  defaultMessage="disappointed"
                />
              ),
              value: 3,
              ranking: 3,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex1.res4"
                  defaultMessage="happy"
                />
              ),
              value: 4,
              ranking: 4,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex1.res5"
                  defaultMessage="successful"
                />
              ),
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex1"
        onChange={({ response: { value } }) => { setEx1(value); }}
      />
      <ExampleAnswer
        value={Ex1}
        correct={4}
      >
        <p>
          <FormattedMessage
            id="wk.ex1.correct"
            defaultMessage="You should have selected <b>happy</b>."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
      <HR />
      <WkItem
        testItem={{
          id: 2,
          item: {
            text: (
              <FormattedMessage
                id="wk.ex2.item"
                defaultMessage="ATTRACTIVE"
              />
            ),
          },
          responses: [
            {
              text: (
                <FormattedMessage
                  id="wk.ex2.res1"
                  defaultMessage="careful"
                />
              ),
              value: 1,
              ranking: 1,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex2.res2"
                  defaultMessage="beautiful"
                />
              ),
              value: 2,
              ranking: 2,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex2.res3"
                  defaultMessage="shy"
                />
              ),
              value: 3,
              ranking: 3,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex2.res4"
                  defaultMessage="ugly"
                />
              ),
              value: 4,
              ranking: 4,
            },
            {
              text: (
                <FormattedMessage
                  id="wk.ex2.res5"
                  defaultMessage="helpful"
                />
              ),
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex2"
        onChange={({ response: { value } }) => { setEx2(value); }}
      />
      <ExampleAnswer
        value={Ex2}
        correct={2}
      >
        <p>
          <FormattedMessage
            id="wk.ex2.correct"
            defaultMessage="You should have selected <b>beautiful</b>."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
    </SubTestInstructions>
  );
};

export default WkDirections;
