import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  KeyWrapper as KW,
  FinishButton,
  Content,
  HR,
} from '../../components';

const ItemContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  margin: 0em 1em;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  font-weight: 700;
  width: 200px;
`;

const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Response = styled.div`
`;

const ResponseLabel = styled.label`
  margin-left: .5em;
  cursor: pointer;
  &:hover {
    opacity: .5;
  }
`;

const ResponseInput = styled.input`
  cursor: pointer;
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

export const WkItem = ({ testItem, keyId, onChange }) => {
  const {
    id,
    item: {
      text,
    },
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res) => (
    <Response key={`${keyId}_res_${res.ranking}`}>
      <ResponseInput
        type="radio"
        name={`sr_res_${id}`}
        id={`sr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <ResponseLabel htmlFor={`sr_res_${id}_${res.ranking}`}>
        {res.text}
      </ResponseLabel>
    </Response>
  ));
  return (
    <ItemContainer>
      <Item>
        {text}
      </Item>
      <ResponseContainer
        key={keyId}
        id={keyId}
        role="radiogroup"
      >
        {responses}
      </ResponseContainer>
    </ItemContainer>
  );
};

const NaAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it, i) => (
    <React.Fragment key={`na_item_${it.ranking}`}>
      <WkItem
        keyId={`sr_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
      />
      { items.length === i + 1 ? '' : <HR /> }
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/wk/finish"
        secondary
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer will go here</p>
            : <Timer />
        }
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);

  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={4}
      />
    </Content>
  );
};

export default NaAssessment;
