import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AssessmentComplete,
} from './AssessmentComplete';

import AbilitiesCompletePng from '../../images/Abilities-Finished.png';

const AbilitiesComplete = ({
  ...rest
}) => (
  <AssessmentComplete
    {...rest}
    image={AbilitiesCompletePng}
    render={() => (
      <p>
        <FormattedMessage
          id="complete.abilities"
          defaultMessage="Congratulations! You have completed the abilities
          portion of your assessment."
        />
      </p>
    )}
  />
);

export default AbilitiesComplete;
