import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  blue,
} from './Colors';

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0rem 4rem;
`;

const HideButton = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;

  margin-left: 1em;

  color: ${blue};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
`;

const Digits = styled.div`
  font-weight: bold;
`;

/** The Abilities Timer */
const Timer = ({
  time,
  active,
  broadcastTimeout,
  sendTime,
  testDefinitionId,
  assignedProductId,
  ...rest
}) => {
  const [ inTime, setTime ] = useState(time);
  const [ showTimer, setShowTimer ] = useState(true);

  useEffect(() => {
    let handle;
    let ticked = false;
    if (active && inTime > 0) {
      handle = setInterval(() => {
        ticked = true;
        setTime((t) => (t - 1000));
        sendTime({
          time: inTime,
          testDefinitionId,
          assignedProductId,
        });
      }, 1000);
    } else if (active) {
      setTime(0);
      broadcastTimeout({
        testDefinitionId,
        assignedProductId,
      });
    }

    return () => {
      clearInterval(handle);
      if (!ticked && inTime > 0) {
        sendTime({
          time: inTime,
          testDefinitionId,
          assignedProductId,
        });
      }
    };
  }, [
    inTime,
    active,
    testDefinitionId,
    assignedProductId,
    broadcastTimeout,
    sendTime,
  ]);

  if (!active) {
    return '';
  }

  let minute = Math.floor(inTime / 1000 / 60);
  if (minute < 10) {
    minute = `0${minute}`;
  }
  let seconds = Math.floor((inTime - minute * 1000 * 60) / 1000);
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return (
    <TimerContainer
      {...rest}
    >
      {
        showTimer ? (
          <Digits>
            {`${minute} : ${seconds}`}
          </Digits>

        ) : ''
      }
      <HideButton
        onClick={() => setShowTimer(!showTimer)}
      >
        {
          showTimer
            ? (
              <FormattedMessage
                id="timer.hide"
                defaultMessage="Hide"
              />
            )
            : (
              <FormattedMessage
                id="timer.show"
                defaultMessage="Show Timer"
              />
            )
        }
      </HideButton>
    </TimerContainer>
  );
};

Timer.propTypes = {
  /** Time the counter will count down. */
  time: PropTypes.number.isRequired,
  /** Should the timer be running or not */
  active: PropTypes.bool,
  /** Function will be called when the timer runs out the following signature:
   *  `broadcastTimeout({ testDefinitionId, assignedProductId })`
   */
  broadcastTimeout: PropTypes.func,
  /** Function will be called every second with the following signature:
   *  `broadcastTimeout({ time, testDefinitionId, assignedProductId })`
   *  where time is the current time on the clock
   */
  sendTime: PropTypes.func,
  /** The test definition passed the callback functions */
  testDefinitionId: PropTypes.number,
  /** The assigned product id passed the callback functions */
  assignedProductId: PropTypes.number,
};

Timer.defaultProps = {
  active: undefined,
  broadcastTimeout: () => {},
  sendTime: () => {},
  testDefinitionId: null,
  assignedProductId: null,
};

export default Timer;
