import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import {
  FIND_PRODUCTS_REQUEST,
  FIND_PRODUCTS_SUCCESS,
} from '../actions/productActions';

const productsReducer = (
  state = {
    list: [],
    total: 0,
    dic: {},
    loading: false,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case FIND_PRODUCTS_REQUEST: {
      newState.loading = true;
      newState.list = [];
      newState.total = 0;
      newState.dic = {};
      return newState;
    }
    case FIND_PRODUCTS_SUCCESS: {
      newState.loading = false;
      newState.list = [];
      newState.total = action.payload.total;
      action
        .payload
        .list
        .filter((p) => (
          // TODO: Place this filter on server side
          !p.adminName.includes('EPQ-R') && !p.adminName.includes('POI')
        ))
        .forEach((avail) => {
          newState.list.push({
            ...avail,
            createdAt: DateTime.fromISO(avail.createdAt),
            updatedAt: DateTime.fromISO(avail.updatedAt),
          });
          newState.dic[avail.id] = {
            ...avail,
            createdAt: DateTime.fromISO(avail.createdAt),
            updatedAt: DateTime.fromISO(avail.updatedAt),
          };
        });
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default productsReducer;
