import {
  find,
  omitBy,
  isNull,
  cloneDeep,
} from 'lodash';

import {
  INIT_USER_SUCCESS,
} from '../actions/userActions';

import {
  CHANGE_ACTIVE_ASSIGNED,
} from '../actions/assignedProductActions';

import {
  LOAD_RESULTS_REQUEST,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAILURE,
  LOAD_RESULTS_COMPLETE,
  LOAD_ASSESSMENT_REQUEST,
  LOAD_ASSESSMENT_SUCCESS,
  LOAD_ASSESSMENT_FAILURE,
  SET_ASSESSMENT_CHAIN,
  SAVE_RESPONSE_REQUEST,
  FINISH_SUCCESS,
  FINISH_REQUEST,
  SET_KEY,
  CLEAR_KEY,
  SET_PUBLIC_REQUEST,
  SET_PUBLIC_SUCCESS,
} from '../actions/assessmentActions';

import {
  TIMER_TIMEOUT,
} from '../actions/timerActions';

const assignedProductReducer = (
  state = {
    chain: [],
    loading: false,
    active: {},
    isPublic: undefined,
    settingPublic: false,
    results: {
      clusters: {
        values: [],
        ranked: [],
        max: 0,
      },
    },
  },
  action,
) => {
  switch (action.type) {
    case INIT_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        active: {},
      };
    }
    case CHANGE_ACTIVE_ASSIGNED: {
      return {
        ...state,
        loading: false,
        active: {},
      };
    }
    case SET_ASSESSMENT_CHAIN: {
      const newState = cloneDeep(state);
      newState.chain = action.payload.filter((stat) => !stat.isFinished);
      return newState;
    }
    case LOAD_ASSESSMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        active: {},
      };
    }
    case LOAD_ASSESSMENT_SUCCESS: {
      let prunedItems;
      if (action.payload.testType.abbreviation === 'VR') {
        prunedItems = action.payload.vrFacts.map((fact) => ({
          ...fact,
          testItems: fact.testItems.map((it) => (omitBy(it, isNull))),
        }));
      } else {
        prunedItems = action.payload.testItems.map((it) => (
          omitBy(it, isNull)
        ));
      }
      return {
        ...state,
        loading: false,
        active: {
          ...action.payload,
          vrFacts: undefined,
          testDefinitionId: action.payload.id,
          testItems: prunedItems,
        },
      };
    }
    case LOAD_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        active: {},
      };
    }
    case SAVE_RESPONSE_REQUEST: {
      const newState = cloneDeep(state);
      let item;
      if (newState.active.testType.abbreviation === 'VR') {
        newState.active.testItems.forEach((vrItem) => {
          vrItem.testItems.forEach((it) => {
            if (it.id === action.payload.testItemId) {
              item = it;
            }
          });
        });
      } else {
        item = find(newState.active.testItems, { id: action.payload.testItemId });
      }
      item.userResponse = {
        choice: find(item.responses, { id: action.payload.choiceId }),
      };
      return newState;
    }
    case FINISH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FINISH_SUCCESS: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.chain.shift();
      return newState;
    }
    case TIMER_TIMEOUT: {
      const newState = cloneDeep(state);
      newState.chain.shift();
      return newState;
    }
    case LOAD_RESULTS_REQUEST: {
      const newState = cloneDeep(state);
      newState.loading = action.payload.loading;
      newState.results = {
        clusters: {
          values: [],
          ranked: [],
          max: 0,
        },
      };
      return newState;
    }
    case LOAD_RESULTS_SUCCESS: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.results = action.payload.data;
      if (action.payload.data.clusters) {
        newState.results.clusters = {
          values: action.payload.data.clusters.values,
          ranked: action.payload.data.clusters.values.sort((a, b) => (b.total - a.total)),
          max: action.payload.data.clusters.max,
        };
      } else {
        newState.results.clusters = {
          values: [],
          ranked: [],
          max: 0,
        };
      }
      newState.isPublic = action.payload.data.isPublic;
      delete newState.results.isPublic;
      return newState;
    }
    case LOAD_RESULTS_FAILURE: {
      const {
        payload: {
          response: {
            status,
          } = {},
        },
      } = action;
      const newState = cloneDeep(state);
      newState.loading = false;
      if (status === 403) {
        newState.isPublic = false;
      }
      return newState;
    }
    case LOAD_RESULTS_COMPLETE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_KEY: {
      return {
        ...state,
        renderKey: action.payload.render,
      };
    }
    case CLEAR_KEY: {
      return {
        ...state,
        renderKey: undefined,
      };
    }
    case SET_PUBLIC_REQUEST: {
      return {
        ...state,
        settingPublic: true,
      };
    }
    case SET_PUBLIC_SUCCESS: {
      return {
        ...state,
        settingPublic: false,
        isPublic: action.payload.isPublic,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assignedProductReducer;
