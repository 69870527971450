import Directions from './DirectionsContainer';
import VrDirectionsComponent from '../components/VrDirections';

const VrDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'VR',
  component: VrDirectionsComponent,
});

export default VrDirectionsContainer;
