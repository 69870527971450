import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import { MediumButton } from './Button';

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  children,
  title,
  confirmText,
  cancelText,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={title}
  >
    <div
      style={{
        margin: '1rem 3rem',
      }}
    >
      {children}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          margin: '1rem 0',
          alignItems: 'center',
        }}
      >
        <MediumButton onClick={onClose}>
          {cancelText}
        </MediumButton>
        <span
          style={{
            margin: '0 1rem',
          }}
        >
          &#8208; OR &#8208;
        </span>
        <MediumButton onClick={onConfirm}>
          {confirmText}
        </MediumButton>
      </div>
    </div>
  </Modal>
);

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

ConfirmModal.defaultProps = {
  isOpen: false,
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  title: '',
};

export default ConfirmModal;
