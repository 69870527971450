import React, {
  useMemo,
  useState,
  createContext,
} from 'react';

export const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const [ currentStepIndex, setCurrentStepIndex ] = useState(0);
  const [ state, setState ] = useState({});
  const [ stepLength, setStepLength ] = useState();

  const next = (nextState) => {
    const nextStep = currentStepIndex + 1;
    setState({ ...state, ...nextState });
    if (nextStep < stepLength) {
      setCurrentStepIndex(nextStep);
    }
  };

  const prev = () => {
    const nextStep = currentStepIndex - 1;
    if (nextStep >= 0) {
      setCurrentStepIndex(nextStep);
    }
  };

  const reset = () => {
    setCurrentStepIndex(0);
    setState({});
  };

  const context = useMemo(() => ({
    currentStepIndex,
    setCurrentStepIndex,
    stepLength,
    setStepLength,
    state,
    setState,
    next,
    prev,
    reset,
  }));

  return (
    <StepperContext.Provider value={context}>
      {children}
    </StepperContext.Provider>
  );
};
