import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { CenteredSpinner } from '../../components';

const Signin = () => {
  const auth = useAuth();
  useEffect(() => {
    auth
      .signinRedirect();
  }, []);
  return <CenteredSpinner />;
};

export default Signin;
