import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  isType,
} from '../../utils/products';

import {
  loadAssessment,
} from '../../actions/assessmentActions';

import {
  startTimer,
} from '../../actions/timerActions';

const Directions = ({
  type,
  subTest,
  component,
}) => {
  const mapStateToProps = (state) => {
    const {
      assessment: {
        active: activeAssessment,
        chain,
      },
    } = state;

    const [ status ] = chain;

    let active = activeAssessment;
    if (!isEmpty(active) && !isType(type, active)) {
      active = {};
    }
    const {
      testType: {
        abbreviation: abbr,
      } = {},
    } = active;
    if (subTest && subTest !== abbr) {
      active = {};
    }

    return {
      loading: state.assessment.loading,
      assessment: active,
      status,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    loadTest: (stat) => {
      dispatch(loadAssessment(stat));
    },
    startTimer: () => {
      dispatch(startTimer());
    },
  });

  const DirectionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component);

  return DirectionsContainer;
};

export default Directions;
