import { connect } from 'react-redux';
import {
  reduce,
} from 'lodash';

import SucceedComponent from '../components/Succeed';

import {
  isFinished as computeIsFinished,
} from '../../utils/products';

import {
  loadResults,
  cleanResults,
  setPublic,
} from '../../actions/assessmentActions';

import {
  clickDiscover,
} from '../../actions/displayActions';

import {
  toggleFavoriteJob,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    assignedProduct: {
      activeProduct,
      list: assignedProducts,
    },
    assessment: {
      loading,
      isPublic,
      results: {
        settingPublic,
        clusters: {
          ranked: clusters,
        },
        suppressed,
      },
    },
    jobs: {
      favorites: favoriteJobs,
    },
  } = state;

  const isFinished = computeIsFinished(activeProduct);
  const allFinished = reduce(isFinished, (acc, value) => {
    if (!value) {
      return false;
    }
    return acc;
  }, true);

  return {
    allFinished,
    activeProduct,
    assignedProducts,
    clusters,
    favoriteJobs,
    isPublic,
    loading,
    settingPublic,
    suppressed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (activeProduct) => (
    dispatch(loadResults(activeProduct, { loadJobs: true }))
  ),
  setPublic: (assignedProductId, isPublic) => {
    dispatch(setPublic({ assignedProductId, isPublic }));
  },
  toggleFavoriteJob: (params) => (dispatch(toggleFavoriteJob(params))),
  setManualDiscover: () => (dispatch(clickDiscover())),
  unloadResults: () => (dispatch(cleanResults())),
});

const SucceedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SucceedComponent);

export default SucceedContainer;
