import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useAuth } from 'react-oidc-context';

import {
  Wrapper,
} from './components';

const App = ({
  userLoaded,
  locale,
  messages,
  children,
}) => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isLoading) {
      userLoaded(auth.user);
    }
  }, [auth.user]);

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale="en">
      <Wrapper>
        {children}
      </Wrapper>
    </IntlProvider>
  );
};

export default App;
