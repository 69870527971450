/* eslint react/forbid-prop-types:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  FinishButton,
  Content,
  ApiImage,
  ScreenReader,
  KeyWrapper as KW,
  HR,
} from '../../components';

const ItemContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin: 0em .25em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex: 2 2 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 460px;
`;

const ItemImage = styled(ApiImage)`
`;

const ItemText = styled.p`
  margin: 1em;
`;

const ResponsesContainer = styled.div`
  margin: 0em 1em;

  @media (max-width: 768px) {
    margin: 0em auto;
  }

  label {
    margin-left: .5em;
  }
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

export const MrItem = ({ testItem, keyId, onChange }) => {
  const {
    id,
    item: {
      text,
      image,
      alt,
    },
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res) => (
    <React.Fragment key={`${keyId}_${res}_${res.ranking}`}>
      <input
        type="radio"
        name={`mr_res_${id}`}
        id={`mr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <label htmlFor={`mr_res_${id}_${res.ranking}`}>
        {res.text}
        { res.alt ? <ScreenReader>{res.alt}</ScreenReader> : '' }
      </label>
      <br />
    </React.Fragment>
  ));
  return (
    <ItemContainer key={keyId} id={keyId}>
      <ItemImage
        src={`/mr/${image}`}
        alt={alt}
        zoom={1.5}
      />
      <RightColumn>
        <ItemText>{text}</ItemText>
        <ResponsesContainer
          aria-labelledby={keyId}
          role="radiogroup"
        >
          {responses}
        </ResponsesContainer>
      </RightColumn>
    </ItemContainer>
  );
};

const MrAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it, i) => (
    <React.Fragment key={`mr_item_${it.ranking}`}>
      <MrItem
        keyId={`mr_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
      />
      { items.length === i + 1 ? '' : <HR /> }
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/mr/finish"
        secondary
      />,
    );
  }
  React.useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer Disabled in Test Mode</p>
            : <Timer />
        }
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [ setRenderKey, clearRenderKey, storyMode ]);

  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={4}
      />
    </Content>
  );
};

MrAssessment.propTypes = {
  testStatus: PropTypes.shape({
    assignedProductId: PropTypes.number,
    testDefinitionId: PropTypes.number,
  }).isRequired,
  saveItem: PropTypes.func.isRequired,
  /** These are the items for this page */
  items: PropTypes.array.isRequired,
  /** These are all the items in the test. */
  testItems: PropTypes.array.isRequired,
  /** Saves a testItem */
  isLastPage: PropTypes.bool,
  /** time left of the timer */
  time: PropTypes.number,
  /** hide the timer for storybook mode */
  storyMode: PropTypes.bool,
};

MrAssessment.defaultProps = {
  isLastPage: false,
  time: undefined,
  storyMode: false,
};

export default MrAssessment;
