import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';

export const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'CREATE_PAYMENT_ERROR';

export const getPaymentIntent = (paymentIntent) => (
  (dispatch) => {
    sendIt(dispatch, GET_PAYMENT_REQUEST, paymentIntent);
    return http.get(`/payment`, {
      stripeId: paymentIntent.id,
    }).then(({ data }) => {
      sendIt(dispatch, GET_PAYMENT_SUCCESS, data);
    }).catch((e) => {
      sendIt(dispatch, GET_PAYMENT_ERROR, e);
    });
  }
);

export const createPaymentIntent = () => (
  (dispatch) => {
    sendIt(dispatch, CREATE_PAYMENT_REQUEST);
    return http.put(`/payment`).then(({ data }) => {
      sendIt(dispatch, CREATE_PAYMENT_SUCCESS, data);
    }).catch((e) => {
      sendIt(dispatch, CREATE_PAYMENT_ERROR, e);
    });
  }
);

// params must have stripeId in the params argument
export const updatePaymentIntent = (params) => (
  (dispatch) => {
    sendIt(dispatch, CREATE_PAYMENT_REQUEST);
    if (!params.stripeId) {
      /* eslint-disable-next-line no-console */
      console.error('stripeId is required');
      sendIt(dispatch, CREATE_PAYMENT_ERROR, 'stripeId is required');
      return Promise.reject(new Error('stripeId is required'));
    }
    return http.post(`/payment`, params).then(({ data }) => {
      sendIt(dispatch, CREATE_PAYMENT_SUCCESS, data);
    }).catch((e) => {
      sendIt(dispatch, CREATE_PAYMENT_ERROR, e);
    });
  }
);
