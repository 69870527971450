import { connect } from 'react-redux';
import LuAssessmentComponent from '../components/LuAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const LuAssessmentContainer = connect(
  mapStateToProps({ ipp: 4 }),
  mapDispatchToProps(),
)(LuAssessmentComponent);

export default withRouter(LuAssessmentContainer);
