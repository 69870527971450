import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  FinishButton,
  Content,
} from '../../components';

import ValuesInput, { ValueMessage } from './ValuesInputs';

import Nav from './NavBar';

const ItemContainer = styled.div`
  max-width: 750px;
  margin: auto;
`;

const Statement = styled.p`
  font-weight: bold;
`;

const ValuesFinish = styled(FinishButton)`
  margin: 2em auto;
`;

const ValuesAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
}) => {
  const displayItems = items.map((it) => (
    <ValuesInput
      key={`interstsInput_${it.id}`}
      testItem={it}
      onChange={(params) => saveItem({ testStatus, ...params })}
    />
  ));
  if (isLastPage) {
    displayItems.push(
      <ValuesFinish
        danger
        to="/assessment/values/finish"
        key="finish"
      />,
    );
  }
  return (
    <Content>
      <ItemContainer>
        <Statement>
          <ValueMessage />
        </Statement>
        {displayItems}
        <Nav
          testItems={testItems}
          ipp={7}
        />
      </ItemContainer>
    </Content>
  );
};

ValuesAssessment.propTypes = {
  testStatus: PropTypes.shape({
    assignedProductId: PropTypes.number,
    testDefinitionId: PropTypes.number,
  }).isRequired,
  saveItem: PropTypes.func.isRequired,
  /** These are the items for this page */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    })),
    userResponse: PropTypes.shape({
      choice: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  })),
  /** These are all the items in the test. */
  testItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    })),
    userResponse: PropTypes.shape({
      choice: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  })),
};

ValuesAssessment.defaultProps = {
  items: [],
  testItems: [],
};

export default ValuesAssessment;
