import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  KeyWrapper as KW,
  FinishButton,
  Content,
  RenderMath,
  HR,
} from '../../components';

const colW = (1 / 5) * 100;

const ItemContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0em .5em;
`;

const MathBlock = styled(RenderMath)`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: ${colW * 3}%;
`;

const MathResponse = styled(RenderMath)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const TextQuestion = styled.p`
  display: flex;
  align-items: center;
  width: ${colW * 4}%;
  font-family: 'Times New Roman', serif;
  margin: 0em 2em;
  font-size: 1.21em;
`;

const ResponsesContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  width: ${colW}%;
`;

const Response = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResponseLabel = styled.label`
  margin-left: .5em;
  &:hover {
    cursor: pointer;
    opacity: .5;
  }
`;

const Text = styled.span`
  width: ${colW}%;
  text-align: right;
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

export const NaItem = ({
  testItem,
  keyId,
  example = false,
  onChange,
}) => {
  const {
    id,
    item: {
      text,
      image,
      alt,
    },
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res) => (
    <Response key={`${keyId}_res_${res.ranking}`}>
      <input
        type="radio"
        name={`sr_res_${id}`}
        id={`sr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <ResponseLabel htmlFor={`sr_res_${id}_${res.ranking}`}>
        {
          res.image
            ? (
              <MathResponse
                alt={res.alt}
                katex={{
                  minRuleThickness: 0.08,
                }}
                className="heebo-me"
              >
                {res.image}
              </MathResponse>
            )
            : res.text
        }
      </ResponseLabel>
    </Response>
  ));
  return (
    <ItemContainer key={keyId} id={keyId} data-testid={`na_item_${id}`}>
      {
        image
          ? (
            <>
              <Text>
                {text && example ? text : ''}
                {text && !example ? text.toUpperCase() : ''}
              </Text>
              <MathBlock
                alt={alt}
                katex={{
                  minRuleThickness: 0.08,
                }}
              >
                {image}
              </MathBlock>
            </>
          )
          : <TextQuestion>{text}</TextQuestion>
      }
      <ResponsesContainer
        aria-labelledby={keyId}
        role="radiogroup"
      >
        {responses}
      </ResponsesContainer>
    </ItemContainer>
  );
};

const NaAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it, i) => (
    <React.Fragment key={`na_item_${it.ranking}`}>
      <NaItem
        keyId={`na_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
      />
      { items.length === i + 1 ? '' : <HR /> }
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/na/finish"
        secondary
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer will go here</p>
            : <Timer />
        }
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);
  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={4}
      />
    </Content>
  );
};

export default NaAssessment;
