import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import * as messageDefs from '../../utils/GlobalMessages';

import {
  Colors,
} from '../../components';

const StyledTestButton = styled(Link)`
  background-color: white;
  max-width: 330px;
  min-width: 330px;
  width: 330px;
  height: 140px;
  flex: 1 1 auto;
  display: flex;
  text-align:center;
  justify-content:center;
  flex-direction:column;
  border-radius: 15px;

  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color} !important;

  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: .7;
  }

  margin: .5rem 0;

  text-decoration: none;
`;

const StartStyle = styled.p`
  line-height: normal;
  font-size: 12pt;
  margin: 0px;
  color: ${Colors.grey};
`;

const Start = ({ isFinished }) => (
  <StartStyle>
    <FormattedMessage
      {...(isFinished ? messageDefs.completed : messageDefs.start)}
    />
  </StartStyle>
);

const StartTestButton = ({
  children,
  to,
  startAssessment,
  testStatus = [],
  color,
  isFinished,
}) => {
  const disabled = testStatus.length === 0;
  return (
    <StyledTestButton
      color={isFinished ? Colors.grey : color}
      disabled={disabled}
      to={isFinished ? to.replace('start', 'complete') : to}
      onClick={() => {
        if (!isFinished) {
          startAssessment(testStatus);
        }
      }}
    >
      {children}
      {
        disabled
          ? <StartStyle>Not Available</StartStyle>
          : <Start isFinished={isFinished} />
      }
    </StyledTestButton>
  );
};

StartTestButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  isFinished: PropTypes.bool,
  startAssessment: PropTypes.func.isRequired,
  testStatus: PropTypes.arrayOf(PropTypes.shape({
    isFinished: PropTypes.bool,
  })),
  color: PropTypes.string,
};

StartTestButton.defaultProps = {
  isFinished: false,
  testStatus: [],
  color: Colors.grey,
};

export default StartTestButton;
