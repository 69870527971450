import { connect } from 'react-redux';
import {
  filter,
  each,
  chain,
} from 'lodash';

import DiscoverComponent from '../components/PrintDiscover';
import { isFinished, getStatus } from '../../utils/products';
import {
  loadResults,
  cleanResults,
} from '../../actions/assessmentActions';
import {
  loadClusters,
} from '../../actions/clusterActions';
import {
  loadJob,
  toggleFavoriteJob,
  updateFavoriteJob,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    oidc: {
      user: {
        profile: user,
      },
    },
    assignedProduct: {
      activeProduct,
    },
    assignedProduct: {
      list: assignedProducts,
    },
    assessment: {
      loading: loadingAssessments,
      results: {
        values,
        interests,
        abilities,
        clusters: inClusters,
      },
    },
    cluster: {
      clusters: allClusters,
      subClusters: allSubClusters,
    },
    jobs: {
      obj: allJobs,
      favorites: allFavoriteJobs,
    },
  } = state;

  // sort the clusters by total and map for the graph
  const rankedClusters = {
    values: inClusters ? inClusters.values : [],
    ranked: inClusters ? inClusters.values.sort((a, b) => (b.total - a.total)) : [],
    max: inClusters ? inClusters.max : 0,
  };

  // join the clusters, sub clusters, and jobs together
  const allFullClusters = {};
  each(allClusters, (clust) => {
    const subClusters = chain(allSubClusters)
      .filter({ clusterId: clust.id })
      .map((sub) => ({
        ...sub,
        jobs: filter(allJobs, { subClusterId: sub.id }),
      }))
      .value();

    allFullClusters[clust.id] = {
      ...clust,
      subClusters,
      // if it's professional, we don't have subclusters
      jobs: subClusters.length === 0 ? filter(allJobs, { clusterId: clust.id }) : undefined,
    };
  });

  /*
   * TODO: Move the scores into allFullClusters
  if (clusters.ranked && clusters.ranked.length > 0) {
    clusters.ranked.forEach((c, i) => {
      const clustersWithJobs = allClusters[c.id];
      if (clustersWithJobs && (clustersWithJobs.subClusters || clustersWithJobs.jobs)) {
        clusters.ranked[i].subClusters = clustersWithJobs.subClusters;
        clusters.ranked[i].jobs = clustersWithJobs.jobs;
        clusters.ranked[i].scales = {
          values: [],
          abilities: clustersWithJobs.scales.abilities,
        };
        if (values && values.conversion) {
          clusters.ranked[i].scales.values = clustersWithJobs.scales.values.map((scale) => {
            const foundResult = find(values.conversion, { id: scale.id });
            let match = false;
            if (foundResult) {
              if (foundResult.norm < 0 && scale.direction === 'left') {
                match = true;
              }
              if (foundResult.norm > 0 && scale.direction === 'right') {
                match = true;
              }
            }
            return {
              ...scale,
              match,
            };
          });
        }
      }
    });
  }
  */

  let type;
  if (activeProduct.professional) {
    type = 'professional';
  } else if (activeProduct.language === 'spanish') {
    type = 'spanish';
  } else {
    type = 'cluster';
  }

  const favoriteJobs = allFavoriteJobs
    .map((j) => {
      const clust = allClusters[j.clusterId];
      if (clust) {
        return {
          ...j,
          clusterName: clust.name,
        };
      }
      return j;
    });
  return {
    activeProduct: {
      ...activeProduct,
      ...isFinished(activeProduct),
      values: getStatus('values', activeProduct),
      interests: getStatus('interests', activeProduct),
      abilities: getStatus('abilities', activeProduct),
    },
    allClusters: allFullClusters,
    type,
    assignedProducts,
    loading: loadingAssessments,
    user,
    values,
    interests,
    abilities,
    rankedClusters,
    favoriteJobs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (activeProduct, user) => (
    dispatch(loadResults(activeProduct, user))
  ),
  unloadResults: () => (dispatch(cleanResults())),
  loadClusters: (clusters, favoritesFor) => (dispatch(loadClusters(clusters, favoritesFor))),
  loadJob: (job) => (dispatch(loadJob(job))),
  toggleFavoriteJob: (params) => (dispatch(toggleFavoriteJob(params))),
  updateFavoriteJob: (params) => (dispatch(updateFavoriteJob(params))),
});

const DiscoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverComponent);

export default DiscoverContainer;
