/* eslint no-console:0, jsx-a11y/label-has-associated-control:0 */
import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  chain,
} from 'lodash';

import {
  useResize,
} from '../../utils/sizing';

import ValuesGraph from './DiscoverValues';
import InterestsGraph from './DiscoverInterests';
import AbilitiesGraph from './DiscoverAbilities';
import FullGraph from './FullGraph';

import {
  H1,
  SmallButton as Button,
  Colors,
} from '../../components';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const GraphButtonContainer = styled.div`
  margin: 1rem 2rem;
`;

const GraphSelect = styled.select`
  margin-left: 1rem;
`;

const GraphNextButton = styled(Button)`
  margin: 1rem 1rem 1rem auto;
`;

const GraphContainer = ({
  values,
  interests,
  abilities,
  rankedClusters,
  onNext,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const [ ranked, setRanked ] = useState(rankedClusters.ranked);
  const [ sort, setSort ] = useState('high');
  const [ showGraph, setShowGraph ] = useState('combined');
  const [ sortedInterests, setSortedInterests ] = useState(interests);

  useEffect(() => {
    if (values && !interests && !abilities) {
      setShowGraph('values');
    }
    if (!values && interests && !abilities) {
      setShowGraph('interests');
    }
    if (!values && !interests && abilities) {
      setShowGraph('abilities');
    }
  }, [ values, interests, abilities]);

  useEffect(() => {
    let rnkd;
    switch (sort) {
      case 'high': {
        rnkd = chain(rankedClusters.values)
          .sortBy(['total'])
          .reverse()
          .value();
        break;
      }
      case 'low': {
        rnkd = chain(rankedClusters.values)
          .sortBy(['total'])
          .value();
        break;
      }
      case 'clusters': {
        rnkd = chain(rankedClusters.values)
          .sortBy(['id'])
          .value();
        break;
      }
      default: {
        console.log(`unkown sort ${sort}`);
        break;
      }
    }
    setRanked(rnkd);
  }, [ sort, rankedClusters, setRanked ]);

  useEffect(() => {
    if (!interests) {
      return;
    }
    let rnkd;
    switch (sort) {
      case 'high': {
        rnkd = chain(interests)
          .sortBy([ 'norm', 'rawScore' ])
          .reverse()
          .value();
        break;
      }
      case 'low': {
        rnkd = chain(interests)
          .sortBy([ 'norm', 'rawScore' ])
          .value();
        break;
      }
      case 'clusters': {
        rnkd = chain(interests)
          .sortBy([ 'id' ])
          .value();
        break;
      }
      default: {
        console.log(`unkown sort ${sort}`);
        break;
      }
    }
    setSortedInterests(rnkd);
  }, [ sort, interests, setSortedInterests ]);

  let graph;
  switch (showGraph) {
    case 'values':
      if (values) {
        graph = (
          <div data-testid="graph-values">
            <ValuesGraph
              values={values}
              width={width}
            />
            <p>
              This graph shows what clusters you value. Each scale has two
              sides, left or right. The closer you are to one side, the more
              you value jobs that have that trait.
            </p>
            <p>
              The three bars in darker read, show your highest 3 values.
            </p>
          </div>
        );
      } else if (values === null) {
        graph = (
          <p>Assessment incomplete.</p>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'interests':
      if (interests) {
        graph = (
          <div data-testid="graph-interests">
            <InterestsGraph
              interests={sortedInterests}
              width={width}
            />
            <p>
              This graph shows you which clusters interest you. The higher the
              score the more jobs in this cluster interest you.
            </p>
          </div>
        );
      } else if (interests === null) {
        graph = (
          <p>Assessment incomplete.</p>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'abilities':
      if (abilities) {
        graph = (
          <div data-testid="graph-abilities">
            <AbilitiesGraph
              abilities={abilities}
              width={width}
            />
            <p>
              This graph shows your abilities scores. These represent the 8
              assessments you took earlier. The higher the score the more
              ability you have in these areas.
            </p>
            <p>
              If you are weak in certain areas, do not worry! Many areas can be
              improved through training or education.
            </p>
          </div>
        );
      } else if (abilities === null) {
        graph = (
          <p>Assessment incomplete.</p>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'combined':
      graph = (
        <div data-testid="graph-full">
          <FullGraph
            clusters={ranked}
            clusterMax={rankedClusters.max}
            width={width}
          />
          <p>
            This graph shows how well you match to each cluster. The higher the
            score, the better the match. Your Values, Interests, and Abilities
            are represented by red, blue, and green rectangles within each
            cluster. The number on the far right shows your percent match to
            this cluster.
          </p>
        </div>
      );
      break;
    default:
      graph = <div />;
  }
  return (
    <div ref={ref}>
      <H1>Your Results Profile</H1>
      <GraphContainerDiv>
        <GraphButtonContainer>
          <label htmlFor="results">Results:</label>
          <GraphSelect
            data-testid="select-graph"
            name="results"
            onChange={(e) => {
              if (e.target.value === 'interests') {
                setSort('clusters');
              }
              setShowGraph(e.target.value);
            }}
            value={showGraph}
          >
            <option value="combined" data-testid="option-graph-combind">COMBINED</option>
            {
              values || values === null ? <option value="values" data-testid="option-graph-values">VALUES</option> : ''
            }
            {
              interests || interests === null ? <option value="interests" data-testid="option-graph-interests">INTERESTS</option> : ''
            }
            {
              values || values === null ? <option value="abilities" data-testid="option-graph-abilities">ABILITIES</option> : ''
            }
          </GraphSelect>
          { showGraph === 'combined' || showGraph === 'interests' ? (
            <GraphSelect
              data-testid="select-sort"
              onChange={(e) => setSort(e.target.value)}
              value={sort}
            >
              <option value="high" data-testid="option-sort-high">HIGH TO LOW</option>
              <option value="low" data-testid="option-sort-low">LOW TO HIGH</option>
              <option value="clusters" data-testid="option-sort-clusters">CLUSTERS</option>
            </GraphSelect>
          ) : '' }
        </GraphButtonContainer>
        { graph }
        <GraphNextButton
          onClick={() => onNext()}
        >
          Next
        </GraphNextButton>
      </GraphContainerDiv>
    </div>
  );
};

export default GraphContainer;
