import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  CenteredSpinner,
  CenteredText,
  Colors,
  Content,
  H1,
  MediumButton,
  ScreenReader,
  UpperCaseMessage,
} from '../../components';

import {
  Label,
  Input,
  ExampleButton,
} from './Inputs';

import {
  msd,
  next,
  pleaseWait,
  question,
} from '../../utils/GlobalMessages';

const KeyContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const Key = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const KeyText = styled.p`
  text-align:center;
  margin: 1em .5em;
`;

const KeyDirections = styled.p`
  margin: 1em .5em;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  &:nth-child(odd) {
    background-color: ${Colors.superLightGrey};
  }
`;

const RadioGroup = styled.div`
  margin-left: auto;
  min-width: 285px;
  width: 285px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: .77em;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin: 1em auto;
`;

const MsdItem = ({
  testItem,
  onChange,
}) => {
  const {
    id,
    item: {
      text,
    },
    userResponse: {
      choice: {
        value: initValue,
      } = {},
    } = {},
  } = testItem;
  const [ value, setValue ] = useState(initValue);
  const buttons = testItem.responses.map((res) => (
    <React.Fragment key={`msd_survey_${id}_${res.value}`}>
      <Input
        type="radio"
        id={`question_${id}_${res.value}`}
        name={`question_${id}_res`}
        value={res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
        checked={value === res.value}
      />
      <Label
        htmlFor={`question_${id}_${res.value}`}
      >
        { res.text }
      </Label>
    </React.Fragment>
  ));

  return (
    <RadioWrapper>
      <QuestionWrapper tabIndex="0" id={`question_${id}`}>
        <ScreenReader>
          <FormattedMessage
            {...question}
          />
          {id}
        </ScreenReader>
        {text}
      </QuestionWrapper>

      <RadioGroup
        role="radiogroup"
        aria-labelledby={`question_${id}`}
      >
        { buttons }
      </RadioGroup>
    </RadioWrapper>
  );
};

const MsdDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  saveItem,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  let allAnswered = false;
  let items = (
    <div>
      <p>
        <FormattedMessage
          {...pleaseWait}
        />
      </p>
      <CenteredSpinner />
    </div>
  );

  if (!loading && assessment && assessment.testItems && assessment.testItems.length > 0) {
    items = assessment.testItems.slice(1).map((it) => (
      <MsdItem
        key={`msd_item_${it.id}`}
        testItem={it}
        onChange={(params) => { saveItem({ testStatus: status, ...params }); }}
      />
    ));
    allAnswered = assessment.testItems.slice(1).reduce((acc, it) => {
      if (it.userResponse === undefined) {
        return false;
      }
      return acc;
    }, true);
  }

  return (
    <Content>
      <CenteredText>
        <H1 color={Colors.green}>
          <UpperCaseMessage
            {...msd}
          />
        </H1>
        <p>
          <em>
            <FormattedMessage
              id="msd.directions.tagline"
              defaultMessage="This is a test of manaul speed and dexterity"
            />
          </em>
        </p>
      </CenteredText>
      <KeyDirections>
        <FormattedMessage
          id="msd.directions.fivePoint"
          defaultMessage="Use the following five-point scale to answer the questions below."
        />
      </KeyDirections>
      <KeyContainer>
        <Key>
          <ExampleButton>1</ExampleButton>
          <KeyText>
            <FormattedMessage
              id="msd.directions.muchWorse"
              defaultMessage="Much worse than others"
            />
          </KeyText>
        </Key>
        <Key>
          <ExampleButton>2</ExampleButton>
          <KeyText>
            <FormattedMessage
              id="msd.directions.somewhatWorse"
              defaultMessage="Somewhat worse than others"
            />
          </KeyText>
        </Key>
        <Key>
          <ExampleButton>3</ExampleButton>
          <KeyText>
            <FormattedMessage
              id="msd.directions.aboutSame"
              defaultMessage="About the same as others"
            />
          </KeyText>
        </Key>
        <Key>
          <ExampleButton>4</ExampleButton>
          <KeyText>
            <FormattedMessage
              id="msd.directions.somewhatBetter"
              defaultMessage="Somewhat better than others"
            />
          </KeyText>
        </Key>
        <Key>
          <ExampleButton>5</ExampleButton>
          <KeyText>
            <FormattedMessage
              id="msd.directions.muchBetter"
              defaultMessage="Much better than others"
            />
          </KeyText>
        </Key>
      </KeyContainer>
      {items}
      <ButtonContainer>
        <MediumButton
          secondary
          to="/assessment/abilities/msd/assessment"
          disabled={!allAnswered}
        >
          <UpperCaseMessage
            {...next}
          />
        </MediumButton>
      </ButtonContainer>
    </Content>
  );
};

export default MsdDirections;
