import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  CenteredText,
  Colors,
  Content,
  ExampleAnswer,
  H1,
  StartButton,
  UpperCaseMessage,
  Strong,
  B,
} from '../../components';

import {
  LuItem,
} from './LuAssessment';

import {
  fiveMins,
  languageUsage,
  practice,
  quick,
} from '../../utils/GlobalMessages';

const Correct = (
  <FormattedMessage
    id="lu.correct"
    defaultMessage="Correct"
  />
);

const LuDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ Ex1, setEx1 ] = useState();
  const [ Ex2, setEx2 ] = useState();
  const [ Ex3, setEx3 ] = useState();

  return (
    <Content>
      <CenteredText>
        <H1 color={Colors.green}>
          <UpperCaseMessage
            {...languageUsage}
          />
        </H1>
        <p>
          <em>
            <FormattedMessage
              id="lu.directions.tagline"
              defaultMessage="This is an assessment of language usage and English grammar"
            />
          </em>
        </p>
      </CenteredText>
      <p>
        <FormattedMessage
          id="lu.directions.directions"
          defaultMessage="Each of the sentences are divided into four
          parts. In many of the sentences, one of the four parts contains an
          error in grammar, punctuation, or capitalization. Find which part, if
          any, contains the error.  Select the part corresponding to the best
          answer. There is no more than one incorrect part in each sentence. If
          none of the parts contains an error, select <strong>Correct</strong>
          located to the right of the sentence. If you wish to change your
          answer, select the new one."
          values={{
            strong: Strong,
          }}
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practice}
          />
        </em>
      </p>
      <LuItem
        testItem={{
          id: 1,
          responses: [
            {
              text: (
                <FormattedMessage
                  id="lu.ex1.text1"
                  defaultMessage="We wasn't"
                />
              ),
              value: 1,
              ranking: 1,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex1.text2"
                  defaultMessage="going to"
                />
              ),
              value: 2,
              ranking: 2,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex1.text3"
                  defaultMessage="go until"
                />
              ),
              value: 3,
              ranking: 3,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex1.text4"
                  defaultMessage="Thursday."
                />
              ),
              value: 4,
              ranking: 4,
            },
            {
              text: Correct,
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex1"
        onChange={({ response: { value } }) => { setEx1(value); }}
      />
      <ExampleAnswer
        value={Ex1}
        correct={1}
      >
        <p>
          <FormattedMessage
            id="lu.ex1.correct"
            defaultMessage="You should have selected the first answer, <b>We wasn't</b>. <b>We weren't</b> is the correct verb."
            values={{
              b: B,
            }}
          />
        </p>
      </ExampleAnswer>
      <LuItem
        testItem={{
          id: 2,
          responses: [
            {
              text: (
                <FormattedMessage
                  id="lu.ex2.text1"
                  defaultMessage="Mary"
                />
              ),
              value: 1,
              ranking: 1,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex2.text2"
                  defaultMessage="asked, What"
                />
              ),
              value: 2,
              ranking: 2,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex2.text3"
                  defaultMessage="did you"
                />
              ),
              value: 3,
              ranking: 3,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex2.text4"
                  defaultMessage='say?"'
                />
              ),
              value: 4,
              ranking: 4,
            },
            {
              text: Correct,
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex2"
        onChange={({ response: { value } }) => { setEx2(value); }}
      />
      <ExampleAnswer
        value={Ex2}
        correct={2}
      >
        <p>
          <FormattedMessage
            id="lu.ex2.correct"
            defaultMessage="You should have selected the second answer, <b>asked, What</b>. The
            opening quotation marks before <b>What</b> are missing."
            values={{
              b: B,
            }}
          />
        </p>
      </ExampleAnswer>
      <LuItem
        testItem={{
          id: 3,
          responses: [
            {
              text: (
                <FormattedMessage
                  id="lu.ex3.text1"
                  defaultMessage="Bill and Sue"
                />
              ),
              value: 1,
              ranking: 1,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex3.text2"
                  defaultMessage="are going"
                />
              ),
              value: 2,
              ranking: 2,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex3.text3"
                  defaultMessage="to the movies"
                />
              ),
              value: 3,
              ranking: 3,
            },
            {
              text: (
                <FormattedMessage
                  id="lu.ex3.text4"
                  defaultMessage="tomorrow night."
                />
              ),
              value: 4,
              ranking: 4,
            },
            {
              text: Correct,
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex3"
        onChange={({ response: { value } }) => { setEx3(value); }}
      />
      <ExampleAnswer
        value={Ex3}
        correct={5}
      >
        <p>
          <FormattedMessage
            id="lu.ex3.correct"
            defaultMessage="You should have selected the last answer, <b>Correct</b>. The
            sentence is correct."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
      <p>
        <FormattedMessage
          {...quick}
        />
      </p>
      <p>
        <FormattedMessage
          {...fiveMins}
        />
      </p>
      <StartButton
        busy={loading}
        to="/assessment/abilities/lu/1"
        onClick={() => startTimer()}
        secondary
      />
    </Content>
  );
};

export default LuDirections;
