import { cloneDeep, find, chain } from 'lodash';

import {
  parseProduct,
} from '../utils/products';

import {
  INIT_USER_SUCCESS,
  ENTER_PASSCODE_SUCCESS,
} from '../actions/userActions';

import {
  LOAD_EXAMINEE_SUCCESS,
} from '../actions/examineeActions';

import {
  CHANGE_ACTIVE_ASSIGNED,
} from '../actions/assignedProductActions';

import {
  FINISH_SUCCESS,
} from '../actions/assessmentActions';

import {
  TIMER_TIMEOUT,
} from '../actions/timerActions';

const assignedProductReducer = (
  state = {
    list: [],
    activeProduct: undefined,
    examineeList: [],
  },
  action,
) => {
  switch (action.type) {
    case INIT_USER_SUCCESS: {
      const list = chain(action.payload.assignedProducts)
        .map((prod) => parseProduct(prod, action.payload.examineeGroups))
        .compact()
        .sortBy((o) => o.updatedAt)
        .reverse()
        .value();
      return {
        ...state,
        list,
        activeProduct: list[0],
      };
    }
    case CHANGE_ACTIVE_ASSIGNED: {
      return {
        ...state,
        activeProduct: find(state.list, { id: action.payload.id }),
      };
    }
    case ENTER_PASSCODE_SUCCESS: {
      let list = cloneDeep(state.list);
      list.push(parseProduct(action.payload.assignedProduct, action.payload.examineeGroup));
      list = chain(list)
        .uniqBy('id')
        .sortBy((o) => o.updatedAt)
        .reverse()
        .value();
      return {
        ...state,
        list,
        activeProduct: list[0],
      };
    }
    case LOAD_EXAMINEE_SUCCESS: {
      const newState = cloneDeep(state);
      newState.examineeList = action
        .payload
        .assignedProducts
        .map((prod) => parseProduct(prod, action.payload.examineeGroups));
      return newState;
    }
    case TIMER_TIMEOUT: {
      const newState = cloneDeep(state);
      const changeState = find(newState.activeProduct.testStatus, (stat) => (
        stat.testDefinitionId === action.payload.testDefinitionId
      ));
      changeState.isFinished = true;
      return newState;
    }
    case FINISH_SUCCESS: {
      const newState = cloneDeep(state);
      const changeState = find(newState.activeProduct.testStatus, (stat) => (
        stat.testDefinitionId === action.payload.testDefinitionId
      ));
      changeState.isFinished = true;
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default assignedProductReducer;
