import http from '../utils/httpCli';
import { dispatchWrapper as sendIt } from '../utils/shortHand';

export const CHANGE_ACTIVE_ASSIGNED = 'CHANGE_ACTIVE_ASSIGNED';

export const MOVE_ASSIGNED_REQUEST = 'MOVE_ASSIGNED_REQUEST';
export const MOVE_ASSIGNED_SUCCESS = 'MOVE_ASSIGNED_SUCCESS';
export const MOVE_ASSIGNED_ERROR = 'MOVE_ASSIGNED_ERROR';

export const DELETE_SUPER_ASSIGNED_REQUEST = 'DELETE_SUPER_ASSIGNED_REQUEST';
export const DELETE_SUPER_ASSIGNED_SUCCESS = 'DELETE_SUPER_ASSIGNED_SUCCESS';
export const DELETE_SUPER_ASSIGNED_ERROR = 'DELETE_SUPER_ASSIGNED_ERROR';

export const changeActive = (newProduct) => (
  (dispatch) => {
    sendIt(dispatch, CHANGE_ACTIVE_ASSIGNED, newProduct);
  }
);

export const moveAssignedProducts = ({ sources, group }) => (
  (dispatch) => {
    sendIt(dispatch, MOVE_ASSIGNED_REQUEST, { sources, group });
    return http
      .post(
        '/assessment/move',
        {
          assignedProducts: sources.map((source) => source.assignedProductId),
          destinationId: group.groupId,
        },
      )
      .then(({ data }) => {
        sendIt(dispatch, MOVE_ASSIGNED_SUCCESS, {
          sources: sources.map((source) => {
            const newData = data.find((d) => d.assignedProductId === source.assignedProductId);
            if (newData) {
              return {
                ...source,
                ...newData,
              };
            }
            return source;
          }),
          group,
        });
      })
      .catch((e) => {
        sendIt(dispatch, MOVE_ASSIGNED_ERROR, e);
        return Promise.reject(e);
      });
  }
);

export const superDeleteAssignedProducts = (assignedProducts, { keepUser } = {}) => (
  (dispatch) => {
    sendIt(dispatch, DELETE_SUPER_ASSIGNED_REQUEST, assignedProducts);
    return http
      .post('/assessment/super-delete', {
        assignedProducts,
        keepUser,
      })
      .then(() => {
        sendIt(dispatch, DELETE_SUPER_ASSIGNED_SUCCESS, assignedProducts);
      })
      .catch((e) => {
        sendIt(dispatch, DELETE_SUPER_ASSIGNED_ERROR, e);
        return Promise.reject(e);
      });
  }
);
