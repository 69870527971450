import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Banner from './Banner';
import ScreenReader from './ScreenReader';
import Button from './Button';
import { H1 } from './Headers';
import { background } from './Colors';

const Content = styled.div`
  display: flex;
  background-color: ${background};
  flex: 1 1 auto;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const ErrorContent = styled.div`
  margin: 1em;
  text-align:center;
  align-self: center;
`;

/** Basic ViaSystem Layout */
const Wrapper = ({
  user,
  children,
  fatalError,
  renderKey,
  showBannerMenu,
  openBannerMenu,
  closeBannerMenu,
}) => (
  <>
    <Banner
      user={user}
      renderKey={renderKey}
      showMenu={showBannerMenu}
      openMenu={openBannerMenu}
      closeMenu={closeBannerMenu}
    />
    <ScreenReader tabIndex="-1">
      <h1>Content</h1>
    </ScreenReader>
    { fatalError ? (
      <Content onClick={() => closeBannerMenu()}>
        <ErrorContent>
          <H1>Error</H1>
          <p>Code: {fatalError.status || -1}. {fatalError.message}</p>
          <p>Please refresh your screen.</p>
          <div>
            <Button
              link
              onClick={() => {
                window.location = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`;
              }}
            >
              Refresh
            </Button>
          </div>
        </ErrorContent>
      </Content>
    ) : (
      <Content
        onClick={() => {
          if (showBannerMenu) { closeBannerMenu(); }
        }}
      >
        {children}
      </Content>
    )}
  </>
);

Wrapper.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  showBannerMenu: PropTypes.bool,
  openBannerMenu: PropTypes.func,
  closeBannerMenu: PropTypes.func,
};

Wrapper.defaultProps = {
  user: undefined,
  showBannerMenu: false,
  openBannerMenu: () => {},
  closeBannerMenu: () => {},
};

export default Wrapper;
