import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1 as RawH1 } from './Headers';
import {
  white,
  blue,
} from './Colors';

const H1 = styled(RawH1)`
  background-color: ${blue};
  min-height: 1.5rem;
  padding: 1rem;
  margin: 0px;
  text-align: center;
`;

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  ...rest
}) => (
  <ReactModal
    isOpen={isOpen}
    appElement={document.getElementById('root')}
    style={{
      overlay: {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        top: '6.5rem',
        display: 'flex',
        flex: '0 1 auto',
        flexDirection: 'column',
        inset: 'unset',
        padding: '0px',
      },
    }}
    {...rest}
  >
    <H1
      color={white}
    >
      {title}
    </H1>
    {children}
  </ReactModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  title: '',
};

export default Modal;
