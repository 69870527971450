import moment from 'moment';

import http from '../utils/httpCli';
import { dispatchWrapper as sendIt } from '../utils/shortHand';

export const CREATE_EXAMINEES_REQUEST = 'CREATE_EXAMINEES_REQUEST';
export const CREATE_EXAMINEES_SUCCESS = 'CREATE_EXAMINEES_SUCCESS';
export const CREATE_EXAMINEES_ERROR = 'CREATE_EXAMINEES_ERROR';

export const SWITCH_TO_EXAMINEE_REQUEST = 'SWITCH_TO_EXAMINEE_REQUEST';
export const SWITCH_TO_EXAMINEE_SUCCESS = 'SWITCH_TO_EXAMINEE_SUCCESS';

export const LOAD_EXAMINEE_REQUEST = 'LOAD_EXAMINEE_REQUEST';
export const LOAD_EXAMINEE_SUCCESS = 'LOAD_EXAMINEE_SUCCESS';

export const UPDATE_EXAMINEE_REQUEST = 'UPDATE_EXAMINEE_REQUEST';
export const UPDATE_EXAMINEE_SUCCESS = 'UPDATE_EXAMINEE_SUCCESS';
export const UPDATE_EXAMINEE_ERROR = 'UPDATE_EXAMINEE_ERROR';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const DELETE_EXAMINEE_REQUEST = 'DELETE_EXAMINEE_REQUEST';
export const DELETE_EXAMINEE_SUCCESS = 'DELETE_EXAMINEE_SUCCESS';
export const DELETE_EXAMINEE_ERROR = 'DELETE_EXAMINEE_ERROR';

export const DELETE_EXAMINEES_REQUEST = 'DELETE_EXAMINEES_REQUEST';
export const DELETE_EXAMINEES_SUCCESS = 'DELETE_EXAMINEES_SUCCESS';
export const DELETE_EXAMINEES_ERROR = 'DELETE_EXAMINEES_ERROR';

/**
 * Params:
 *  users: [{
 *    username,
 *    email,
 *    firstName,
 *    lastName,
 *    password
 *  }],
 *  sendEmail,
 *  groupId,
 *  availableProductId,
 */
export const createExaminees = (params) => (
  (dispatch) => {
    sendIt(dispatch, CREATE_EXAMINEES_REQUEST, params);
    return http
      .post('/examinees/create', params)
      .then(({ data }) => {
        sendIt(dispatch, CREATE_EXAMINEES_SUCCESS, data.users);
        return data.users;
      })
      .catch((e) => {
        if (e.response && (e.response.status === 400 || e.response.status === 404)) {
          sendIt(dispatch, CREATE_EXAMINEES_ERROR, {
            message: e.response.data.message,
          });
          return Promise.reject(new Error(e.response.data.message));
        }
        throw e;
      });
  }
);

export const superCreateExaminees = (params) => (
  (dispatch) => {
    sendIt(dispatch, CREATE_EXAMINEES_REQUEST, params);
    return http
      .post('/examinees/super-create', params)
      .then(({ data }) => {
        sendIt(dispatch, CREATE_EXAMINEES_SUCCESS, data.users);
        return data.users;
      })
      .catch((e) => {
        if (e.response && (e.response.status === 400 || e.response.status === 404)) {
          sendIt(dispatch, CREATE_EXAMINEES_ERROR, {
            message: e.response.data.message,
          });
          return Promise.reject(new Error(e.response.data.message));
        }
        throw e;
      });
  }
);

/**
  * Loads all information about an examinee
  * @param {integer} examineeId
  */
export const loadExaminee = (examineeId) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_EXAMINEE_REQUEST, examineeId);
    return http
      .get(`/user/${examineeId}`, {
        params: {
          full: true,
        },
      })
      .then(({ data }) => {
        sendIt(dispatch, LOAD_EXAMINEE_SUCCESS, data);
      });
  }
);

/**
  * Removes the cached examinee data
  */
export const clearExaminee = () => (
  (dispatch) => {
    sendIt(dispatch, LOAD_EXAMINEE_SUCCESS, {
      user: {},
      assignedProducts: [],
      groups: [],
    });
  }
);

/**
 * Switches to the examinee account
 * @param {integer} examineeId
 */
export const switchToExaminee = (examineeId) => (
  (dispatch) => {
    sendIt(dispatch, SWITCH_TO_EXAMINEE_REQUEST, examineeId);
    return http
      .post(`/examinees/takeTest`, { examineeId })
      .then(() => {
        sendIt(dispatch, SWITCH_TO_EXAMINEE_SUCCESS, { examineeId });
      });
  }
);

/**
 * Updates the examinee information
 * @param {object} params
 * @param {integer} params.id
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.email
 * @param {string} params.username
 * @param {string} params.password
 * @param {string} params.dob
 */
export const updateExaminee = (params) => (
  (dispatch) => {
    sendIt(dispatch, UPDATE_EXAMINEE_REQUEST, params);
    return http.post(`/user/${params.id}`, {
      ...params,
      grade: params.grade ? parseInt(params.grade, 10) : undefined,
      dob: moment.isMoment(params.dob) ? params.dob.format('YYYY-MM-DD') : undefined,
    }).then(() => {
      sendIt(dispatch, UPDATE_EXAMINEE_SUCCESS, params);
    }).catch((e) => {
      sendIt(dispatch, UPDATE_EXAMINEE_ERROR, e);
      return Promise.reject(e);
    });
  }
);

/**
 * Updates the examinee password
 * @param {object} params
 * @param {integer} params.examineeId
 * @param {string} params.password
 * @param {string} params.matchPassword
 * @param {boolean} params.sendEmail
 */
export const updatePassword = (params) => (
  (dispatch) => {
    sendIt(dispatch, UPDATE_PASSWORD_REQUEST, params);
    return http.post(`/examinees/updatePassword`, {
      examineeId: params.examineeId,
      password: params.password,
      matchPassword: params.matchPassword,
      sendEmail: params.sendEmail,
    }).then(() => {
      sendIt(dispatch, UPDATE_PASSWORD_SUCCESS, params);
    }).catch((e) => {
      sendIt(dispatch, UPDATE_PASSWORD_ERROR, e);
      return Promise.reject(e);
    });
  }
);

/**
  * Deletes an examinee
  * @param {integer} examineeId
  */
export const deleteExaminee = (examineeId) => (
  (dispatch) => {
    sendIt(dispatch, DELETE_EXAMINEE_REQUEST, examineeId);
    return http
      .post(`/examinees/delete`, {
        examineeId,
      })
      .then(() => {
        sendIt(dispatch, DELETE_EXAMINEE_SUCCESS, examineeId);
      })
      .catch((e) => {
        const newError = new Error(e.response.data.error);
        newError.responseType = e.response.data.type;
        sendIt(dispatch, DELETE_EXAMINEE_ERROR, newError);
        return Promise.reject(newError);
      });
  }
);

export const superDeleteExaminee = (examinees) => (
  (dispatch) => {
    sendIt(dispatch, DELETE_EXAMINEES_REQUEST, examinees);
    return http
      .post(`/examinees/super-delete`, {
        examinees,
      })
      .then(() => {
        sendIt(dispatch, DELETE_EXAMINEES_SUCCESS, examinees);
      })
      .catch((e) => {
        const newError = new Error(e.response.data.error);
        newError.responseType = e.response.data.type;
        sendIt(dispatch, DELETE_EXAMINEES_ERROR, newError);
        return Promise.reject(newError);
      });
  }
);
