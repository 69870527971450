/* eslint react/forbid-prop-types:0 */
/* eslint react-hooks/exhaustive-deps:0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  StartButton,
  Colors,
  H1,
  CenteredText,
  Content,
  UpperCaseMessage,
} from '../../components';

import * as globalMessages from '../../utils/GlobalMessages';

import {
  Input as Radio,
} from './Inputs';

import {
  InterestsLabel as Label,
  L,
  LowL,
  LowD,
  D,
  LikeVeryMuch,
  LikeMod,
  DislikeMod,
  DislikeVeryMuch,
} from './InterestsInputs';

const Example = styled.ul`
  list-style-type: none;
  display: inline-block
  margin: 0px;
  padding: 0px;

  li {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  li:nth-of-type(even){
    background-color: ${Colors.superLightGrey};
  }
`;

const ExampleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px;
`;

const InterestsDirectionsComponent = ({
  loading,
  status,
  loadTest,
}) => {
  useEffect(() => {
    loadTest(status);
  }, []);
  return (
    <Content>
      <CenteredText>
        <H1 color={Colors.blue}>
          <UpperCaseMessage
            {...globalMessages.interests}
          />
        </H1>
      </CenteredText>
      <p>
        <FormattedMessage
          id="intrests.directions1"
          defaultMessage="This inventory will help you find out what work activities interest you."
        />
      </p>
      <p>
        <FormattedMessage
          id="intrests.directions2"
          defaultMessage="You will be given a series of statements that describe
          common job activities. Mark your level of interest in these activities
          according to the scale below:"
        />
      </p>
      <ExampleWrapper>
        <Example>
          <li>
            <Radio type="radio" name="ex1_1" id="ex1_1" value="1" />
            <Label htmlFor="ex1_1">
              <L />
            </Label>
            <LikeVeryMuch />
          </li>
          <li>
            <Radio type="radio" name="ex1_2" id="ex1_2" value="1" />
            <Label htmlFor="ex1_2">
              <LowL />
            </Label>
            <LikeMod />
          </li>
          <li>
            <Radio type="radio" name="ex1_3" id="ex1_3" value="1" />
            <Label htmlFor="ex1_3">
              <LowD />
            </Label>
            <DislikeMod />
          </li>
          <li>
            <Radio type="radio" name="ex1_4" id="ex1_4" value="1" />
            <Label htmlFor="ex1_4">
              <D />
            </Label>
            <DislikeVeryMuch />
          </li>
        </Example>
      </ExampleWrapper>
      <p>
        <FormattedMessage
          id="interests.directions2"
          defaultMessage="
            You might find some of the statements describe activities or
            situations that are unfamiliar to you. Do your best to indicate your
            level of interest regardless of how much experience you have with the
            tasks, activities, or situations described. Remember you are
            exploring your interests.
          "
        />
      </p>
      <p>
        <FormattedMessage
          {...globalMessages.finishAll}
        />
      </p>
      <CenteredText>
        <StartButton
          busy={loading}
          to="/assessment/interests/1"
        />
      </CenteredText>
    </Content>
  );
};

InterestsDirectionsComponent.propTypes = {
  /** If the assessment is being loaded */
  loading: PropTypes.bool,
  /** Test Status object passed to `loadTest` */
  status: PropTypes.object,
  /** Callback function to load the test.
   * `loadTest(status)`
   */
  loadTest: PropTypes.func.isRequired,

};

InterestsDirectionsComponent.defaultProps = {
  loading: false,
  status: undefined,
};

export default InterestsDirectionsComponent;
