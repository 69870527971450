import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  grey,
} from './Colors';

export const H1Template = `
  font-family: anisette-std, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  letter-spacing: .04em;
  line-height: 1em;
  margin: .5em 0em;
`;

const SH1 = styled.h1`
  ${H1Template}
  color: ${(props) => props.color};
  text-align: ${(props) => (props.centered ? 'centered' : '')};
`;

const SH2 = styled.h2`
  font-family: Heebo;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2em;
  letter-spacing: .01em;
  line-height: 1.7em;
  color: ${(props) => props.color};
`;

/**
 * VIA System Headers.
 *
 * Note: If the children are strings, they will be capalitized.
 * All other types will be passed through untouched.
 */
export const H1 = ({ children, color, ...rest }) => (
  <SH1
    color={color}
    {...rest}
  >
    {typeof children === 'string' ? children.toUpperCase() : children}
  </SH1>
);

H1.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

H1.defaultProps = {
  color: grey,
};

/**
 * VIA System Headers.
 *
 * Note: If the children are strings, they will be capalitized.
 * All other types will be passed through untouched.
 */
export const H2 = ({
  children,
  color,
  noMod,
  ...rest
}) => (
  <SH2
    color={color}
    {...rest}
  >
    { (typeof children === 'string' && !noMod) ? children.toUpperCase() : children}
  </SH2>
);

H2.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  noMod: PropTypes.bool,
};

H2.defaultProps = {
  noMod: false,
  color: grey,
};
