import Directions from './DirectionsContainer';
import WkDirectionsComponent from '../components/WkDirections';

const WkDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'WK',
  component: WkDirectionsComponent,
});

export default WkDirectionsContainer;
