import { connect } from 'react-redux';
import Timer from '../components/Timer';

import {
  timeout,
  tick,
} from '../../actions/timerActions';

const mapStateToProps = (state) => {
  const {
    timer: {
      time,
      active,
    },
    assessment: {
      active: {
        testStatus: {
          testDefinitionId,
          assignedProductId,
        } = {},
      } = {},
    },
  } = state;

  return {
    time,
    active,
    testDefinitionId,
    assignedProductId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  broadcastTimeout: (params) => {
    dispatch(timeout(params));
  },
  sendTime: (params) => {
    dispatch(tick(params));
  },
});

const TimerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Timer);

export default TimerContainer;
