import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Elements,
} from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

import stripePromise, { stripeOptions } from '../../../utils/stripe';
import {
  CenteredSpinner,
} from '../../../components';

import SetupPurchaseForm from './SetupPurchaseForm';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SetupPurchase = ({
  user,
  assignedProducts,
  payments,
  updateUser,
  createPaymentIntent,
  updatePaymentIntent,
}) => {
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ clientSecret, setClientSecret ] = useState('');

  useEffect(() => {
    if (assignedProducts.length > 0) {
      navigate('/dashboard/setup/bio');
    } else if (payments.length > 0
      && (
        payments[0].status === 'succeeded' || payments[0].status === 'processing')
    ) {
      navigate('/dashboard/setup/purchase-complete');
    } else if (payments.length > 0
      && payments[0]?.stripeMetadata?.client_secret
    ) {
      setIsLoading(false);
      setClientSecret(payments[0].stripeMetadata.client_secret);
    } else {
      setIsLoading(true);
      createPaymentIntent();
    }
  }, []);

  if (isLoading || !clientSecret) {
    return (
      <CenteredSpinner>
        <p>Preparing your purchase</p>
        <p>Please wait.</p>
      </CenteredSpinner>
    );
  }

  return (
    <Container>
      <Elements
        stripe={stripePromise}
        options={{
          ...stripeOptions,
          clientSecret,
        }}
      >
        <SetupPurchaseForm
          user={user}
          updateUser={updateUser}
          purchase={payments[0]}
          updatePaymentIntent={updatePaymentIntent}
        />
      </Elements>
    </Container>
  );
};

export default SetupPurchase;
