import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import {
  H1,
} from '../../components';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

function AdminRouteComponent({
  children, user, path, adminGroups, errorHandler, ...rest
}) {
  const noAdmin = (
    <Container>
      <H1>Forbidden</H1>
      <p>You must be an administrator to view this page.</p>
      <Link to="/">Return to Dashboard</Link>
    </Container>
  );

  return (
    <AuthRoute
      user={user}
      path={path}
      errorHandler={errorHandler}
      {...rest}
    >
      {adminGroups.length === 0 ? noAdmin : children}
    </AuthRoute>
  );
}

AdminRouteComponent.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
  children: PropTypes.node,
};

AdminRouteComponent.defaultProps = {
  user: undefined,
  children: undefined,
};

export default AdminRouteComponent;
