import React from 'react';
import { FormattedMessage } from 'react-intl';

/** Brings the formatted message to upper case.
 * NOTE: You must use defineMessages() function for the message extraction to work
 */
const UpperCaseMessage = ({ id, defaultMessage, values }) => (
  <FormattedMessage
    id={id}
    defaultMessage={defaultMessage}
    values={values}
  >
    { (chunks) => (chunks.map((chunk) => chunk.toUpperCase()))}
  </FormattedMessage>
);

export default UpperCaseMessage;
