import { connect } from 'react-redux';
import SucceedComponent from '../components/PublicSucceed';

import {
  loadResults,
  cleanResults,
} from '../../actions/assessmentActions';

const mapStateToProps = (state) => {
  const {
    assignedProduct: {
      activeProduct,
      list: assignedProducts,
    },
    assessment: {
      loading,
      isPublic,
      results: {
        clusters,
        suppressed,
      },
    },
    jobs: {
      favorites: favoriteJobs,
    },
  } = state;

  return {
    activeProduct,
    assignedProducts,
    clusters: clusters.values,
    favoriteJobs,
    loading,
    isPublic,
    suppressed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (activeProductId) => (
    dispatch(loadResults({ id: activeProductId }, { loadJobs: true }))
  ),
  unloadResults: () => (dispatch(cleanResults())),
});

const SucceedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SucceedComponent);

export default SucceedContainer;
