import React from 'react';

const countries = [
  { country: 'United States', code: 'us' },
  { country: 'Argentina', code: 'ar' },
  { country: 'Australia', code: 'au' },
  { country: 'Austria', code: 'at' },
  { country: 'Bahrain', code: 'bh' },
  { country: 'Belgium', code: 'be' },
  { country: 'Brazil', code: 'br' },
  { country: 'Canada', code: 'ca' },
  { country: 'Chile', code: 'cl' },
  { country: 'China', code: 'cn' },
  { country: 'Colombia', code: 'co' },
  { country: 'Czech Republic', code: 'cz' },
  { country: 'Denmark', code: 'dk' },
  { country: 'Finland', code: 'fi' },
  { country: 'France', code: 'fr' },
  { country: 'Germany', code: 'de' },
  { country: 'Greece', code: 'gr' },
  { country: 'Hong Kong', code: 'hk' },
  { country: 'Hungary', code: 'hu' },
  { country: 'India', code: 'in' },
  { country: 'Indonesia', code: 'id' },
  { country: 'Ireland', code: 'ie' },
  { country: 'Israel', code: 'il' },
  { country: 'Italy', code: 'it' },
  { country: 'Japan', code: 'jp' },
  { country: 'Korea', code: 'kr' },
  { country: 'Kuwait', code: 'kw' },
  { country: 'Luxembourg', code: 'lu' },
  { country: 'Malaysia', code: 'my' },
  { country: 'Mexico', code: 'mx' },
  { country: 'Netherlands', code: 'nl' },
  { country: 'New Zealand', code: 'nz' },
  { country: 'Norway', code: 'no' },
  { country: 'Oman', code: 'om' },
  { country: 'Pakistan', code: 'pk' },
  { country: 'Peru', code: 'pe' },
  { country: 'Philippines', code: 'ph' },
  { country: 'Poland', code: 'pl' },
  { country: 'Portugal', code: 'pt' },
  { country: 'Qatar', code: 'qa' },
  { country: 'Romania', code: 'ro' },
  { country: 'Russia', code: 'ru' },
  { country: 'Saudi Arabia', code: 'sa' },
  { country: 'Singapore', code: 'sg' },
  { country: 'South Africa', code: 'za' },
  { country: 'Spain', code: 'es' },
  { country: 'Sweden', code: 'se' },
  { country: 'Switzerland', code: 'ch' },
  { country: 'Taiwan', code: 'tw' },
  { country: 'Turkey', code: 'tr' },
  { country: 'United Arab Emirates', code: 'ae' },
  { country: 'United Kingdom', code: 'gb' },
  { country: 'Venezuela', code: 've' },
];

const CountryPicker = ({
  value,
  handleChange,
  ...rest
}) => {
  const mappedCountires = countries.map((x) => (
    <option value={x.code}>{x.country}</option>
  ));
  return (
    <select
      value={value}
      onChange={(e) => handleChange(e)}
      {...rest}
    >
      {mappedCountires}
    </select>
  );
};

export default CountryPicker;
