import Directions from './DirectionsContainer';
import PsaDirectionsComponent from '../components/PsaDirections';

const PsaDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'PSA',
  component: PsaDirectionsComponent,
});

export default PsaDirectionsContainer;
