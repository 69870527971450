import React from 'react';
import PropTypes from 'prop-types';

import { blue } from './Colors';

const SucceedIcon = ({ width, height, style }) => (
  <svg
    id="SucceedIcon"
    data-name="Explore Icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.53 65.22"
    width={width}
    height={height}
    style={style}
  >
    <title>Succeed Icon</title>
    <path d="M32.76,44.85a1.2,1.2,0,0,1-.7-.23L29,42.4l-3.64.89a1.17,1.17,0,0,1-1.28-.53l-1.95-3.23L18.43,39a1.19,1.19,0,0,1-1-1l-.55-3.72-3.21-2A1.17,1.17,0,0,1,13.14,31l.9-3.65-2.23-3a1.17,1.17,0,0,1,0-1.38l2.23-3-.9-3.64A1.2,1.2,0,0,1,13.67,15l3.22-1.94.55-3.73a1.19,1.19,0,0,1,1-1l3.73-.56,1.95-3.21a1.16,1.16,0,0,1,1.28-.53L29,4.94l3-2.22a1.17,1.17,0,0,1,1.39,0l3,2.23,3.65-.89a1.18,1.18,0,0,1,1.28.53l1.94,3.21,3.71.56a1.18,1.18,0,0,1,1,1l.56,3.74L51.85,15a1.17,1.17,0,0,1,.53,1.29l-.9,3.64,2.23,3a1.15,1.15,0,0,1,0,1.39l-2.23,3,.9,3.65a1.15,1.15,0,0,1-.53,1.28l-3.23,2L48.06,38a1.17,1.17,0,0,1-1,1l-3.71.56-1.94,3.23a1.18,1.18,0,0,1-1.28.53l-3.65-.89-3,2.22A1.19,1.19,0,0,1,32.76,44.85ZM29.29,40a1.17,1.17,0,0,1,.69.23l2.78,2,2.78-2a1.21,1.21,0,0,1,1-.19l3.34.82,1.78-3a1.21,1.21,0,0,1,.83-.56l3.4-.51.52-3.41a1.2,1.2,0,0,1,.55-.83l3-1.78-.83-3.33a1.18,1.18,0,0,1,.2-1l2-2.79-2-2.77a1.18,1.18,0,0,1-.2-1l.83-3.34-3-1.78a1.15,1.15,0,0,1-.55-.83l-.52-3.42L42.47,10a1.17,1.17,0,0,1-.83-.55L39.86,6.53l-3.34.82a1.18,1.18,0,0,1-1-.19l-2.79-2L30,7.15a1.16,1.16,0,0,1-1,.2l-3.34-.82-1.79,3a1.15,1.15,0,0,1-.83.55l-3.41.51L19.13,14a1.18,1.18,0,0,1-.56.83l-2.94,1.78.81,3.34a1.14,1.14,0,0,1-.19,1l-2,2.77,2,2.79a1.13,1.13,0,0,1,.19,1l-.81,3.34,2.94,1.79a1.16,1.16,0,0,1,.56.83l.51,3.4,3.42.51a1.23,1.23,0,0,1,.83.56l1.78,3L29,40A.86.86,0,0,1,29.29,40Z" />
    <path d="M32.76,36.25A12.58,12.58,0,1,1,45.34,23.67,12.6,12.6,0,0,1,32.76,36.25Zm0-22.82A10.24,10.24,0,1,0,43,23.67,10.24,10.24,0,0,0,32.76,13.43Z" />
    <path d="M45.41,62.73a1.18,1.18,0,0,1-1-.58l-11-19.08a1.17,1.17,0,0,1,.32-1.53l1.84-1.36a1.21,1.21,0,0,1,1-.19l3.34.82,1.78-3a1.21,1.21,0,0,1,.83-.56l3.4-.51.52-3.41a1.2,1.2,0,0,1,.55-.83l2.89-1.74a1.18,1.18,0,0,1,.9-.13,1.15,1.15,0,0,1,.72.55L63.09,51.35A1.17,1.17,0,0,1,62,53.1L51,52,46.48,62a1.17,1.17,0,0,1-1,.69ZM35.94,42.8,45.28,59l3.92-8.74a1.15,1.15,0,0,1,1.19-.69l9.52,1L50,33.43l-1.4.84L48.06,38a1.17,1.17,0,0,1-1,1l-3.71.56-1.94,3.23a1.18,1.18,0,0,1-1.28.53l-3.65-.89Z" />
    <path d="M20.11,62.73h-.06A1.17,1.17,0,0,1,19,62L14.52,52,3.57,53.1a1.16,1.16,0,0,1-1.11-.53,1.19,1.19,0,0,1,0-1.22L14.06,31.21a1.2,1.2,0,0,1,.73-.55,1.16,1.16,0,0,1,.89.13l2.89,1.76a1.16,1.16,0,0,1,.56.83l.51,3.4,3.42.51a1.23,1.23,0,0,1,.83.56l1.78,3L29,40a1.16,1.16,0,0,1,1,.19l1.83,1.34a1.16,1.16,0,0,1,.32,1.53l-11,19.1A1.18,1.18,0,0,1,20.11,62.73ZM15.25,49.56a1.16,1.16,0,0,1,1.07.69L20.24,59l9.34-16.2L29,42.4l-3.64.89a1.17,1.17,0,0,1-1.28-.53l-1.95-3.23L18.43,39a1.19,1.19,0,0,1-1-1l-.55-3.72-1.4-.85L5.61,50.54l9.52-1Z" />
  </svg>
);

SucceedIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

SucceedIcon.defaultProps = {
  width: 45,
  height: 45,
  style: {
    fill: blue,
  },
};

export default SucceedIcon;
