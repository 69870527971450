import React from 'react';
import PropTypes from 'prop-types';

const Email = ({
  name,
  fieldName,
  formik,
  ...rest
}) => (
  <input
    type="email"
    name={name}
    {...formik.getFieldProps(fieldName)}
    {...rest}
  />
);

Email.propTypes = {
  name: PropTypes.string,
  fieldName: PropTypes.string,
  formik: PropTypes.shape({
    getFieldProps: PropTypes.func,
  }).isRequired,
};

Email.defaultProps = {
  name: 'email',
  fieldName: 'email',
};

export default Email;
