import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  ExampleAnswer,
  HR,
  B,
} from '../../components';

import {
  correctAnswer,
  practice,
  numericalAbility,
} from '../../utils/GlobalMessages';

import {
  NaItem,
} from './NaAssessment';

import SubTestInstructions from './SubTestInstructions';

const NaDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ ex1, setEx1 ] = useState();
  const [ ex2, setEx2 ] = useState();

  return (
    <SubTestInstructions
      abbr="na"
      busy={loading}
      startTimer={startTimer}
      tagLine={{
        id: 'na.directions.tagline',
        defaultMessage: 'This is an assessment of numerical reasoning containing math problems.',
      }}
      title={numericalAbility}
    >
      <p>
        <FormattedMessage
          id="na.directions.directions"
          defaultMessage="Solve each math problem and select the best
          answer. You are not to use a calculator, however you may use a
          separate sheet of paper to help you solve each problem. If you
          wish to change your answer, select the new one."
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practice}
          />
        </em>
      </p>
      <NaItem
        example
        testItem={{
          id: 1,
          item: {
            example: true,
            text: (
              <FormattedMessage
                id="na.directions.ex1.text"
                defaultMessage="Add"
              />
            ),
            image: `
              \\begin{array}{cc}
                & 14 \\\\
              + & 11 \\\\
              \\hline
              \\end{array}
            `,
            alt: '14 plus 11',
          },
          responses: [
            { text: 15, value: 1, ranking: 1 },
            { text: 16, value: 2, ranking: 2 },
            { text: 25, value: 3, ranking: 3 },
            { text: 26, value: 4, ranking: 4 },
            { text: 27, value: 5, ranking: 5 },
          ],
        }}
        keyId="ex1"
        onChange={({ response }) => { setEx1(response.value); }}
      />
      <ExampleAnswer
        value={ex1}
        correct={3}
      >
        <p>
          <FormattedMessage
            {...correctAnswer}
          />
          &nbsp;25.
        </p>
      </ExampleAnswer>
      <HR />
      <NaItem
        example
        testItem={{
          id: 2,
          item: {
            text: (
              <FormattedMessage
                id="na.directions.ex2.text"
                defaultMessage="Multiply"
              />
            ),
            image: `
              \\begin{array}{cc}
                & 11 \\\\
              \\times & 4 \\\\
              \\hline
              \\end{array}
            `,
            alt: '11 times 4',
          },
          responses: [
            { text: 34, value: 1, ranking: 1 },
            { text: 40, value: 2, ranking: 2 },
            { text: 43, value: 3, ranking: 3 },
            { text: 46, value: 4, ranking: 4 },
            {
              text: (
                <FormattedMessage
                  id="na.directions.ex2.notGiven"
                  defaultMessage="Not Given"
                />
              ),
              value: 5,
              ranking: 5,
            },
          ],
        }}
        keyId="ex2"
        onChange={({ response }) => { setEx2(response.value); }}
      />
      <ExampleAnswer
        value={ex2}
        correct={5}
      >
        <p>
          <FormattedMessage
            id="na.directions.ex2.correct"
            defaultMessage="The correct answer is 44. Since 44 is not in the list you should have selected <b>Not Given</b>."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
    </SubTestInstructions>
  );
};

export default NaDirections;
