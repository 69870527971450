import { connect } from 'react-redux';

//import { broadcastFatalError } from '../../actions/errorActions';

import PrivateRouteComponent from '../components/PrivateRouteComponent';

const mapStateToProps = (state, props) => ({
  user: state.user.profile,
  loading: state.user.loading,
  ...props,
});

const mapDispatchToProps = () => ({});

const PrivateRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRouteComponent);

export default PrivateRouteContainer;
