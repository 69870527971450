import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Button,
  FormikError,
} from '../../components';

const Input = styled.input`
  display: block;
  width: 100%;
`;

const EnterPasscode = ({
  className,
  done,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      passcode: '',
    },
    validationSchema: Yup.object({
      passcode: Yup.string().required('Please enter a passcode'),
    }),
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      onSubmit(values.passcode).then(() => {
        done();
      }).catch((e) => {
        setFieldError('passcode', e.message);
      }).finally(() => {
        setSubmitting(false);
      });
    },
  });
  return (
    <form
      className={className}
      onSubmit={formik.handleSubmit}
    >
      <Input
        type="text"
        name="passcode"
        placeholder="Enter your Passcode"
        className="pure-u-1-1"
        {...formik.getFieldProps('passcode')}
      />
      <FormikError formik={formik} accessor="passcode" />
      <Button
        type="submit"
        full
        busy={formik.isSubmitting}
      >
        Enter
      </Button>
    </form>
  );
};

EnterPasscode.propTypes = {
  /** This function is called once the form successfuly submits. No parameters. */
  done: PropTypes.func,
  /** Appy a css class to the form */
  className: PropTypes.string,
  /** Private: Handles the submit action. This should be the redux call */
  onSubmit: PropTypes.func.isRequired,
};

EnterPasscode.defaultProps = {
  done: () => {},
  className: '',
};

export default EnterPasscode;
