import { connect } from 'react-redux';
import { filter } from 'lodash';

import SetupComponent from '../components/SetupPurchase';
import { updateUser } from '../../../actions/userActions';
import {
  getPaymentIntent,
  createPaymentIntent,
  updatePaymentIntent,
} from '../../../actions/paymentActions';

const mapStateToProps = (state) => ({
  user: state.user.profile,
  assignedProducts: filter(state.assignedProduct.list, { userId: state.user.profile.id }),
  payments: state.payment.list,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (values) => (
    dispatch(updateUser(values))
  ),
  getPaymentIntent: (values) => (
    dispatch(getPaymentIntent(values))
  ),
  createPaymentIntent: (values) => (
    dispatch(createPaymentIntent(values))
  ),
  updatePaymentIntent: (values) => (
    dispatch(updatePaymentIntent(values))
  ),
});

const SetupPurchaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupComponent);

export default SetupPurchaseContainer;
