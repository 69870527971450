import { cloneDeep } from 'lodash';
import {
  INIT_USER_SUCCESS,
} from '../actions/userActions';
import {
  GET_PAYMENT_SUCCESS,
} from '../actions/paymentActions';

const PurchaseReducer = (
  state = {
    list: [],
    loading: false,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case INIT_USER_SUCCESS: {
      newState.list = action.payload.purchases;
      return newState;
    }
    case GET_PAYMENT_SUCCESS: {
      //Returns data from the server, so the ids are different
      newState.list = newState.list.map((purchase) => {
        if (purchase.stripeId === action.payload.stripeId) {
          return action.payload;
        }
        return purchase;
      });
      return newState;
    }
    default:
      return state;
  }
};

export default PurchaseReducer;
