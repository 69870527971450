import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import {
  CenteredSpinner,
} from '../../components';

const Signup = () => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.signinRedirect({ prompt: 'create' });
    }
  }, []);
  return (
    <CenteredSpinner>
      <p>Preparing your account.</p>
      <p>Please wait.</p>
    </CenteredSpinner>
  );
};

export default Signup;
