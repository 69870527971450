import { connect } from 'react-redux';
import {
  reduce,
} from 'lodash';

import ExploreComponent from '../components/ExploreComponent';

import {
  isFinished as computeIsFinished,
  getStatus,
} from '../../utils/products';

import {
  setChain,
} from '../../actions/assessmentActions';

const mapStateToProps = (state) => {
  const {
    assignedProduct: {
      activeProduct,
    },
  } = state;

  const isFinished = computeIsFinished(activeProduct);
  const allFinished = reduce(isFinished, (acc, value) => {
    if (!value) {
      return false;
    }
    return acc;
  }, true);

  return {
    allFinished,
    activeProduct: {
      ...activeProduct,
      isFinished,
      values: getStatus('values', activeProduct),
      interests: getStatus('interests', activeProduct),
      abilities: getStatus('abilities', activeProduct),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  startAssessment: (statusArray) => {
    dispatch(setChain(statusArray));
  },
});

const ExploreContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExploreComponent);

export default ExploreContainer;
