/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import {
  Button,
  FormikError,
  InputDOB,
  InputGender as InptGender,
  InputGrade as InptGrade,
  UsernameSchema,
  UniqueUsernameSchema,
  InputUsername as InptUsername,
  OptionalEmailSchema,
  RequiredEmailSchema,
  UniqueEmailSchema,
  InputEmail as InptEmail,
} from '../../components';

const Label = styled.label`
  display: block;
  width: 100%;
`;

const InlineLabel = styled.label`
`;

const Input = styled.input`
  display: block;
  width: 100%;
`;

const Checkbox = styled.input`
  margin-left: .2em;
`;

const InputGender = styled(InptGender)`
  display: block;
  width: 100%;
`;

const InputGrade = styled(InptGrade)`
  display: block;
  width: 100%;
`;

const InputUsername = styled(InptUsername)`
  display: block;
  width: 100%;
`;

const InputEmail = styled(InptEmail)`
  display: block;
  width: 100%;
`;

/** The Examinee Setup form. Values help determine the norm group. */
const EditProfile = ({
  user: {
    id,
    username,
    email,
    firstName,
    lastName,
    gender,
    grade,
    dob,
  },
  isAdmin,
  isBusy,
  onSubmit,
  onClose,
}) => {
  const [ genderLabel, setGenderLabel] = useState('Gender');
  const [ noEmail, setNoEmail ] = useState(isEmpty(email));
  const formik = useFormik({
    initialValues: {
      username,
      email,
      firstName,
      lastName,
      gender,
      grade,
      dob,
    },
    validationSchema: Yup.object({
      username: UsernameSchema
        .concat(UniqueUsernameSchema(username)),
      email: noEmail ? (
        OptionalEmailSchema
      ) : (
        RequiredEmailSchema
          .concat(UniqueEmailSchema(email))
      ),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      gender: Yup.string().nullable().required('Required'),
      grade: Yup.number().nullable().required('Required'),
      dob: Yup.object().nullable().test('validDate', 'Please enter a valid date.', (value) => (
        moment.isMoment(value)
      )),
    }),
    onSubmit: (values) => {
      if (!isBusy) { onSubmit({ id, ...values }); }
    },
  });

  useEffect(() => {
    if (noEmail) {
      formik.setFieldValue('email', '', true);
    } else {
      formik.setFieldValue('email', email, true);
    }
  }, [ noEmail ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Label htmlFor="username">Username</Label>
      <InputUsername
        formik={formik}
      />
      <FormikError formik={formik} accessor="username" />
      {
        !isAdmin
          ? (
            <Link
              to="/profile/password"
            >
              Update Password
            </Link>
          )
          : ''
      }
      <Label htmlFor="email">Email</Label>
      <InputEmail
        disabled={noEmail}
        formik={formik}
      />
      <FormikError formik={formik} accessor="email" />
      <div>
        <InlineLabel htmlFor="noEmail">
          {
            isAdmin
              ? 'Examinee does not have an email'
              : 'I do not have an email'
          }
        </InlineLabel>
        <Checkbox
          name="noEmail"
          type="checkbox"
          checked={noEmail}
          onChange={() => setNoEmail(!noEmail)}
        />
      </div>
      <Label htmlFor="firstName">First Name</Label>
      <Input
        type="text"
        name="firstName"
        {...formik.getFieldProps('firstName')}
      />
      <FormikError formik={formik} accessor="firstName" />
      <Label htmlFor="lastName">Last Name</Label>
      <Input
        type="text"
        name="lastName"
        {...formik.getFieldProps('lastName')}
      />
      <FormikError formik={formik} accessor="lastName" />
      <Label htmlFor="gender">{genderLabel}</Label>
      <InputGender
        className="pure-input-1"
        name="gender"
        allowEmpty={!formik.values.gender}
        allowOther
        value={formik.values.gender}
        onChange={(e) => {
          if (e.target.value === 'O') {
            setGenderLabel('Norm Group');
          }
          formik.handleChange(e);
        }}
      />
      <FormikError formik={formik} accessor="gender" />
      {
        formik.values.gender === 'O'
          && (
          <>
            <p>
              Gender influences which types of careers interest men and women.
              We try to remove this influence and really get to know you, not
              your gender. In order to do this, we create a &quot;norm group&quot;. This a
              grouping that you identify with and have similar interests. We know
              it&apos;s not specific, but we&apos;re trying to &quot;average out&quot; the
              differences.
            </p>
            <label htmlFor="additionalGender">
              <p>
                Knowing that, which gender group do you share the most in common
                with?
              </p>
            </label>
            <InputGender
              name="additionalGender"
              value={formik.values.gender}
              allowEmpty
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
            />
          </>
          )
      }
      <Label htmlFor="grade">Grade</Label>
      <InputGrade
        className="pure-input-1"
        name="grade"
        allowEmpty={!formik.values.grade}
        {...formik.getFieldProps('grade')}
      />
      <FormikError formik={formik} accessor="grade" />
      <Label htmlFor="dob">Date of Birth</Label>
      <InputDOB
        name="dob"
        value={formik.values.dob}
        onChange={(e) => formik.setFieldValue('dob', e)}
      />
      <FormikError formik={formik} accessor="dob" />
      <Button type="submit" full busy={isBusy}>Update</Button>
      {
        isAdmin
          ? (
            <Button
              type="button"
              link
              onClick={() => onClose()}
              style={{
                display: 'flex',
                margin: 'auto',
              }}
            >
              Cancel
            </Button>
          )
          : ''
      }
    </form>
  );
};

EditProfile.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dob: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
  }),
  isBusy: PropTypes.bool,
  onClose: PropTypes.func,
};

EditProfile.defaultProps = {
  user: {
    username: '',
    email: '',
    gender: '',
    grade: '',
    dob: '',
  },
  isBusy: false,
  onClose: () => {},
};

export default EditProfile;
