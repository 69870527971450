import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import httpClient from '../../utils/httpCli';

export const RequiredEmailSchema = Yup
  .string()
  .required('Please enter an email.')
  .email('Please enter a valid email');

export const OptionalEmailSchema = Yup
  .string()
  .nullable()
  .email('Please enter a valid email');

export const UniqueEmailSchema = (initialEmail) => (Yup
  .string()
  .test(
    'unique-email',
    'This email is already associated to an account',
    (value, context) => (
      new Promise((resolve, reject) => {
        // FYI:  true === valid in the Yup sense
        if (value === initialEmail) {
          return resolve(true);
        }
        if (isEmpty(value)) {
          return resolve(context.createError({ message: 'Please enter an email address' }));
        }
        return httpClient.get('/check/email', { params: { email: value } })
          .then((response) => resolve(response.data === true))
          .catch((err) => {
            const isStorybook = window.location.href.includes('localhost:9009');
            /*eslint-disable-next-line*/
            console.log(isStorybook ? 'Email lookup can NOT work in storybook' : err.message);
            if (isStorybook) {
              return resolve();
            }
            return reject(err);
          });
      })),
  )
);
