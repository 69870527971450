import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  FinishButton,
  Content,
  ApiImage,
  KeyWrapper as KW,
  HR,
} from '../../components';

const ItemContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  margin: 0em .25em;
`;

const ItemImageContainer = styled.div`
  width: 220px;
`;

const ItemImage = styled(ApiImage)``;

const ResponsesContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: space-evenly;
`;

const Response = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

const ResponseLabel = styled.label`
  &:hover {
    cursor: pointer;
    opacity: .5;
  }
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

export const SrItem = ({ testItem, keyId, onChange }) => {
  const {
    id,
    item: {
      image,
    },
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res) => (
    <Response key={`${keyId}_${res}_${res.ranking}`}>
      <input
        type="radio"
        name={`sr_res_${id}`}
        id={`sr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <ResponseLabel htmlFor={`sr_res_${id}_${res.ranking}`}>
        <ApiImage
          src={`/sr/${res.text}`}
        />
      </ResponseLabel>
    </Response>
  ));
  return (
    <ItemContainer key={keyId} id={keyId}>
      <ItemImageContainer>
        <ItemImage
          src={`/sr/${image}`}
          alt={image}
        />
      </ItemImageContainer>
      <ResponsesContainer
        aria-labelledby={keyId}
        role="radiogroup"
      >
        {responses}
      </ResponsesContainer>
    </ItemContainer>
  );
};

const SrAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it, i) => (
    <React.Fragment key={`sr_item_${it.ranking}`}>
      <SrItem
        keyId={`sr_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
      />
      { items.length === i + 1 ? '' : <HR /> }
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/sr/finish"
        secondary
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer Disabled in Test Mode</p>
            : <Timer />
        }
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);
  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={4}
      />
    </Content>
  );
};

export default SrAssessment;
