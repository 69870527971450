/* eslint react/forbid-prop-types:0 */
/* eslint react-hooks/exhaustive-deps:0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  StartButton,
  Colors,
  H1,
  CenteredText,
  Content,
  UpperCaseMessage,
} from '../../components';

import * as globalMessages from '../../utils/GlobalMessages';

import ValuesInput, { ValueMessage } from './ValuesInputs';

const Statement = styled.p`
  font-weight: bold;
  text-align: center;
`;

const ExampleContainer = styled.div`
  margin: 1em 15%;
`;

/** Values Assessment Directions */
const ValuesDirectionsComponent = ({
  loading,
  status,
  loadTest,
}) => {
  useEffect(() => {
    loadTest(status);
  }, []);

  return (
    <Content>
      <CenteredText>
        <H1 color={Colors.red}>
          <UpperCaseMessage
            {...globalMessages.values}
          />
        </H1>
      </CenteredText>
      <p>
        <FormattedMessage
          id="values.directions1"
          defaultMessage="This inventory will help you find out what is important to you in a work setting."
        />
      </p>
      <p>
        <FormattedMessage
          id="values.directions2"
          defaultMessage="You will be shown pairs of statements. These
          statements describe values that are important in different work
          activities. Choose one of the two statements that best describes what
          is more important to you. Practice on the following example by choosing the statement that is more important to you."
        />
      </p>
      <Statement>
        <ValueMessage />
      </Statement>
      <ExampleContainer>
        <ValuesInput
          testItem={{
            id: 1,
            responses: [
              {
                value: '1',
                text: (
                  <FormattedMessage
                    id="values.example1A"
                    defaultMessage="Do my tasks in an orderly manner"
                  />
                ),
              },
              {
                value: '2',
                text: (
                  <FormattedMessage
                    id="values.example1B"
                    defaultMessage="take things as they come"
                  />
                ),
              },
            ],
          }}
          onChange={() => {}}
        />
        <ValuesInput
          testItem={{
            id: 2,
            responses: [
              {
                value: '1',
                text: (
                  <FormattedMessage
                    id="values.example2A"
                    defaultMessage="am free from rules in my own work area"
                  />
                ),
              },
              {
                value: '2',
                text: (
                  <FormattedMessage
                    id="values.example2B"
                    defaultMessage="have clear-cut rules to follow in my work area"
                  />
                ),
              },
            ],
          }}
          onChange={() => {}}
        />
      </ExampleContainer>
      <p>
        <FormattedMessage
          {...globalMessages.finishAll}
        />
      </p>
      <StartButton
        danger
        busy={loading}
        to="/assessment/values/1"
      />
    </Content>
  );
};

ValuesDirectionsComponent.propTypes = {
  /** If the assessment is loading */
  loading: PropTypes.bool,
  /** The test status object */
  status: PropTypes.object,
  /** function to load the assessments */
  loadTest: PropTypes.func.isRequired,
};

ValuesDirectionsComponent.defaultProps = {
  loading: false,
  status: undefined,
};

export default ValuesDirectionsComponent;
