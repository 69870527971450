import { connect } from 'react-redux';
import AbilitiesComponent from '../components/Abilities';

import { getStatus } from '../../utils/products';

const mapStateToProps = (state) => {
  const stat = getStatus('abilities', state.assessment.chain)
    .filter((s) => !s.isFinishedtypeArray);
  return {
    subTests: getStatus('abilities', state.assignedProduct.activeProduct),
    nextTest: stat[0],
  };
};

const mapDispatchToProps = () => ({});

const AbilitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AbilitiesComponent);

export default AbilitiesContainer;
