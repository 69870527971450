import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { red } from '../components/Colors';

const FormErrorDiv = styled.div`
  color: ${red};
  display: ${(props) => (props.$show ? 'block' : 'none')};
`;

/** An div of form errors */
const FormError = ({ children, show, ...rest }) => (
  <FormErrorDiv
    $show={show}
    {...rest}
  >
    {children}
  </FormErrorDiv>
);

FormError.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

FormError.defaultProps = {
  show: false,
};

export default FormError;
