import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  width: 1rem;
  height: 1rem;
  margin: 0 0.35rem 0 0;
`;

const Checkbox = (props) => (
  <StyledCheckbox type="checkbox" {...props} />
);

export default Checkbox;
