import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import {
  H1,
} from '../../components';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

function SuperRouteComponent({
  children,
  user,
  path,
  errorHandler,
  ...rest
}) {
  const NoAdmin = (
    <Container>
      <H1>Forbidden</H1>
      <p>You must be an administrator to view this page.</p>
      <Link to="/">Return to Dashboard</Link>
    </Container>
  );

  return (
    <AuthRoute
      user={user}
      path={path}
      errorHandler={errorHandler}
      {...rest}
    >
      {user && user?.isSuperAdmin === true ? children : (NoAdmin)}
    </AuthRoute>
  );
}

SuperRouteComponent.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
  children: PropTypes.node,
};

SuperRouteComponent.defaultProps = {
  user: undefined,
  children: undefined,
};

export default SuperRouteComponent;
