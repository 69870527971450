import Directions from './DirectionsContainer';
import LuDirectionsComponent from '../components/LuDirections';

const LuDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'LU',
  component: LuDirectionsComponent,
});

export default LuDirectionsContainer;
