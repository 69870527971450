import {
  QBO_REQUEST,
  QBO_SUCCESS,
  QBO_ERROR,
} from '../actions/settingsActions';

const SettingsReducer = (
  state = {
    qbo: {},
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case QBO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case QBO_SUCCESS: {
      return {
        ...state,
        qbo: action.payload,
        loading: false,
      };
    }
    case QBO_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default SettingsReducer;
