/* eslint react/forbid-prop-types:0 */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { isFinite } from 'lodash';

import {
  H1,
  Colors,
  CenteredSpinner,
  Icons,
} from '../../components';
import {
  scrollTo,
} from '../../utils/scrollTo';

import Cluster from './Cluster';

const ClusterContainer = styled.div`
  margin-bottom: 2rem;
`;

const P = styled.p`
  margin: .25rem 0;
`;

const TopClustersContainer = styled.div`
  margin: 1rem 0;
`;

const TopCluster = styled.span`
  color: ${(props) => (props.$color)};
  margin-right: 1rem;
`;

const ClusterControlContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: ${Colors.white};

  border: 1px solid ${Colors.grey};
  border-bottom: none;
  border-radius: 15px 15px 0 0;
  padding: .25rem 2rem 2rem;
  margin: 0;
`;
const ClusterControlCarousel = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  border-bottom: 3px solid ${Colors.lightGrey};
`;
const ClusterControl = styled.button`
  font-size: 1.5rem;
  color: ${(props) => (props.$color || Colors.grey)};
  background-color: ${Colors.white};
  white-space: nowrap;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: .5;
  }
  text-decoration: none;
`;

const Content = styled.div`
  background-color: ${Colors.white};
  padding: 0rem 2rem 2rem;
  margin: 0;

  border: 1px solid ${Colors.grey};
  border-top: none;
  border-radius: 0 0 15px 15px;
`;

const Loading = () => (
  <Content>
    <H1 style={{ margin: 0, padding: 0 }}>Please Wait</H1>
    <CenteredSpinner />
  </Content>
);

const ClusterTabs = ({
  allClusters,
  clusters,
  favoriteJobs,
  loadClusters,
  toggleFavoriteJob,
  user,
}) => {
  const { clusterIndex } = useParams();
  const navigate = useNavigate();
  let initialIndex = parseInt(clusterIndex, 10);
  if (!isFinite(initialIndex) || initialIndex < 0 || initialIndex > clusters.ranked.length) {
    initialIndex = 0;
  }
  const [ cIndex, setCIndex ] = useState(initialIndex);
  const [ firstRunDone, setFirstRunDone ] = useState(false);
  const clusterControlContainerRef = useRef();
  const currClusterRef = React.useRef();
  const clusterControlRefs = new Array(clusters.ranked.length).fill(0).map(() => useRef());
  const activeCluster = allClusters[clusters.ranked[cIndex].id];

  // initial load of the clusters
  useEffect(() => {
    // default case where on the top cluster
    // load this cluster and the next cluster
    if (cIndex === 0) {
      loadClusters(clusters.ranked.slice(0, 2), user.sub);
    // were in a middle cluster on initial load, load one prev and one next
    } else if (cIndex === clusters.ranked.length - 1) {
      loadClusters(clusters.ranked.slice(cIndex - 1, cIndex), user.sub);
    } else {
      loadClusters(clusters.ranked.slice(cIndex - 1, cIndex + 2), user.sub);
    }
    setFirstRunDone(true);
  }, []);

  // load each cluster on change
  useEffect(() => {
    if (!firstRunDone) {
      return;
    }
    const curr = clusterControlRefs[cIndex].current;
    scrollTo(clusterControlContainerRef);
    clusterControlContainerRef.current.scroll({
      left: curr.offsetLeft - 100,
      behavior: 'smooth',
    });
    if (!allClusters[clusters.ranked[cIndex].id]) {
      loadClusters([clusters.ranked[cIndex]], user.sub);
    } else if (cIndex + 1 < clusters.ranked.length) {
      loadClusters([clusters.ranked[cIndex + 1]], user.sub);
    }
  }, [ cIndex, user.sub ]); // omit activeCluster

  const top3 = clusters
    .ranked
    .slice(0, 3)
    .map((c, i) => (
      <TopCluster
        key={`top_${c.id}`}
        $color={c.color}
      >
        {i + 1}. {c.name}
      </TopCluster>
    ));

  const tabHeaders = clusters
    .ranked
    .map((c, i) => (
      <ClusterControl
        key={`clusterControl_${c.id}`}
        ref={clusterControlRefs[i]}
        $color={cIndex === i ? c.color : ''}
        $active={cIndex === i}
        onClick={() => {
          navigate(`/dashboard/examinee/discover/cluster/${i}`);
          setCIndex(i);
        }}
      >
        {i + 1}. {c.name}
      </ClusterControl>
    ));

  return (
    <ClusterContainer>
      <H1>Discover your clusters</H1>
      <P>
        Based on your results, your top three Career Clusters are:
      </P>
      <TopClustersContainer>
        {top3}
      </TopClustersContainer>
      <P>
        Below you will find a list of jobs within each cluster. Clicking on a job
        title will provide a short description, and option to mark that career as
        one of your favorites. We recommend choosing at least three jobs from
        each of your top clusters. Once you have selected 10 jobs, you will be
        prompted to sort through your list of favorites.
      </P>
      <ClusterControlContainer>
        <ClusterControl
          data-testid="back-cluster-carousel"
          disabled={cIndex === 0}
          onClick={() => {
            if (cIndex > 0) {
              setCIndex(cIndex - 1);
              navigate(`/dashboard/examinee/discover/cluster/${cIndex - 1}`);
            }
          }}
        >
          <Icons.LeftArrow size="2x" />
        </ClusterControl>
        <ClusterControlCarousel
          ref={clusterControlContainerRef}
        >
          {tabHeaders}
        </ClusterControlCarousel>
        <ClusterControl
          data-testid="forward-cluster-carousel"
          disabled={cIndex === clusters.ranked.length - 1}
          onClick={() => {
            if (cIndex < tabHeaders.length - 1) {
              setCIndex(cIndex + 1);
              navigate(`/dashboard/examinee/discover/cluster/${cIndex + 1}`);
            }
          }}
        >
          <Icons.RightArrow size="2x" />
        </ClusterControl>
      </ClusterControlContainer>
      <div>
        {
          !activeCluster
            ? <Loading />
            : (
              <Cluster
                ref={currClusterRef}
                key={`cluster_${activeCluster.id || 'cluster_unknown'}`}
                {...activeCluster}
                favoriteJobs={favoriteJobs}
                toggleFavorite={(job) => {
                  toggleFavoriteJob({ job, favoriteJobs });
                }}
                showJobSorter={cIndex >= 2}
                lastCluster={cIndex === clusters.ranked.length - 1}
                nextCluster={(action) => {
                  if (action === 'sortJobs') {
                    navigate('/dashboard/examinee/discover/jobs');
                  } else {
                    setCIndex(cIndex + 1);
                    navigate(`/dashboard/examinee/discover/cluster/${cIndex + 1}`);
                  }
                }}
              />
            )
        }
      </div>
    </ClusterContainer>
  );
};

ClusterTabs.propTypes = {
  allClusters: PropTypes.object,
  clusters: PropTypes.shape({
    ranked: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  loadClusters: PropTypes.func.isRequired,
  user: PropTypes.shape({
    sub: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  favoriteJobs: PropTypes.arrayOf(PropTypes.object),
  toggleFavoriteJob: PropTypes.func.isRequired,
};

ClusterTabs.defaultProps = {
  allClusters: {},
  clusters: [],
  user: {},
  favoriteJobs: [],
};

export default ClusterTabs;
