import { uniqBy, cloneDeep } from 'lodash';

import {
  INIT_USER_SUCCESS,
  ENTER_PASSCODE_SUCCESS,
} from '../actions/userActions';

const examineeGroupReducer = (
  state = {
    list: [],
  },
  action,
) => {
  switch (action.type) {
    case INIT_USER_SUCCESS: {
      const list = cloneDeep(state.list);
      list.push(action.payload.examineeGroups);
      return {
        ...state,
        list: uniqBy(list, 'id'),
      };
    }
    case ENTER_PASSCODE_SUCCESS: {
      const list = cloneDeep(state.list);
      list.push(action.payload.examineeGroup);
      return {
        ...state,
        list: uniqBy(list, 'id'),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default examineeGroupReducer;
