import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  CenteredText,
  Colors,
  Content,
  H1,
  H2,
  StartButton,
  UpperCaseMessage,
} from '../../components';

import {
  fiveMins,
  quick,
  instructions,
} from '../../utils/GlobalMessages';

const SubTestInstructions = ({
  abbr,
  title,
  tagLine,
  busy,
  startTimer,
  children,
}) => (
  <Content>
    <CenteredText>
      <H1
        color={Colors.green}
      >
        <UpperCaseMessage
          {...title}
        />
      </H1>
      <p>
        <em>
          <FormattedMessage
            {...tagLine}
          />
        </em>
      </p>
    </CenteredText>
    <H2>
      <CenteredText>
        <FormattedMessage
          {...instructions}
        />
      </CenteredText>
    </H2>
    {children}
    <p>
      <FormattedMessage
        {...quick}
      />
    </p>
    <p>
      <FormattedMessage
        {...fiveMins}
      />
    </p>
    <CenteredText>
      <StartButton
        busy={busy}
        to={`/assessment/abilities/${abbr}/1`}
        onClick={() => startTimer()}
        secondary
      />
    </CenteredText>
  </Content>
);

SubTestInstructions.propTypes = {
  abbr: PropTypes.string.isRequired,
  busy: PropTypes.bool.isRequired,
  startTimer: PropTypes.func.isRequired,
  tagLine: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }).isRequired,
};

export default SubTestInstructions;
