import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  process.env.STRIPE_PUBLIC_KEY
  || 'pk_test_51Q2ySAGfHI7KAUqY5pobq7J4pgBjuoNeo9caoFF27hE5JPn1J0RJaLgkEmqbDtEClWXLR9X4tcxb5DmweUrng1AC00zW2FV3gl',
);

export const stripeOptions = {};

export default stripePromise;
