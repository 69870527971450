import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { ExamineeCircle, DownArrow, RightArrow } from './icons';
import UpperCaseMessage from './UpperCaseMessage';
import * as globalMessages from '../../utils/GlobalMessages';
import RawLogo from './ViaLogo';
import {
  blue,
  lightGrey,
  superLightGrey,
  white,
  grey,
  background,
} from './Colors';

const BannerWrapper = styled.div`
  z-index: 2;
  position: sticky;
  top: -1px;

  background-color: ${white};

  .pure-menu {
    display: flex;
  }

  .pure-menu-horizontal {
    margin: 1em;
    padding: 0px;
    width: auto;
  }

  .pure-menu-link {
    color: ${blue};
    a {
      color: ${blue};
      text-decoration: none;
      &:visited {
        color: ${blue}
      }
    }
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 2rem;
`;

const LogoLink = styled(Link)`
  &:hover {
    opacity: 1;
  }
`;

const Logo = styled(RawLogo)`
  padding: .5rem 0rem;
  height: 3.5em;
  width: 11em;
`;

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  padding: .5em;
  color: ${blue};
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
`;

const Bar = styled.div`
  background-color: ${blue};
  height: 1.65em;
  width: 100%;
  box-shadow: 0px 6px 6px ${lightGrey};
  z-index: 2;
  position: relative;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DisplayMenu = styled.ul`
  display: ${(props) => (props.$show ? 'block' : 'none')};
  z-index: 4;
  position: absolute;
  background-color: white;
  box-shadow: 0 6px 6px 3px ${lightGrey};
  margin: 0px;
  padding: 1em;
  list-style: none;
  top: 3.5rem;
  right: 0rem; /* This forces it not to wrap */
`;

const MenuLink = styled(Link)`
  padding: .5em;
  color: ${grey};
  text-decoration: none;
  width: 100%;
  text-align: center; 
`;

const MenuItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => {
    if (props.$noHover) {
      return '';
    }
    return `
      &:hover {
        opacity: .5;
        background-color: ${superLightGrey};
      }
    `;
  }};

  border-bottom: ${(props) => (props.$border ? `1px solid ${grey}` : 'none')};
`;

const KeyContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  width: 100%;
  background-color: ${background};
`;

export const KeyWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  background-color: ${white};
  box-shadow: 0 3px 6px -3px ${grey};
  width: 100%;
  max-width: 1400px;
  z-index: 3;
  flex-wrap: wrap;

  flex-direction: row;
  padding: 1em 0;
  justify-content: space-evenly;
`;

export const UserIcon = styled(ExamineeCircle)`
  height: auto !important;
  color: ${blue};
`;

/** The VIA System Banner */
const Banner = ({
  user,
  renderKey,
  showMenu,
  openMenu,
  closeMenu,
}) => {
  let path = '/signup';
  let menu = '';

  if (!isEmpty(user)) {
    path = '/';
    menu = (
      <MenuContainer>
        <TransparentButton
          type="button"
          onClick={() => (showMenu ? closeMenu() : openMenu())}
        >
          <FormattedMessage
            id="banner.hello"
            defaultMessage="Hello,"
          />
          &nbsp;{user.firstName}&nbsp;
          {
            showMenu
              ? (
                <DownArrow />
              ) : (
                <RightArrow />
              )
          }
        </TransparentButton>
        <DisplayMenu $show={showMenu}>
          <MenuItem $noHover>
            <UserIcon />
            <p>
              {user.username}
            </p>
          </MenuItem>
          <MenuItem $border>
            <MenuLink
              to="/"
              onClick={() => closeMenu()}
            >
              <UpperCaseMessage
                {...globalMessages.dashboard}
              />
            </MenuLink>
          </MenuItem>
          <MenuItem $border>
            <MenuLink
              to="/profile"
              onClick={() => closeMenu()}
            >
              <UpperCaseMessage
                {...globalMessages.editSignin}
              />
            </MenuLink>
          </MenuItem>
          <MenuItem $border>
            <MenuLink
              to="/profile/password"
              onClick={() => closeMenu()}
            >
              <UpperCaseMessage
                {...globalMessages.editPassword}
              />
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              to="/signout"
              onClick={() => closeMenu()}
            >
              <UpperCaseMessage
                {...globalMessages.signout}
              />
            </MenuLink>
          </MenuItem>
        </DisplayMenu>
      </MenuContainer>
    );
  }

  return (
    <BannerWrapper role="banner">
      <BannerContent>
        <LogoLink
          to={path}
          className=""
        >
          <Logo />
        </LogoLink>
        {menu}
      </BannerContent>
      <Bar onClick={() => closeMenu()} />
      <KeyContainer onClick={() => closeMenu()}>
        { renderKey() }
      </KeyContainer>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  /** User object. Will display drop down menu if not empty. Only the `firstName` is displayed. */
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  /** A render function that will hold "key" data. For an example, see the Interets assessment */
  renderKey: PropTypes.func,
  /** Displays the menu when true */
  showMenu: PropTypes.bool.isRequired,
  /** Funciton to open the menu */
  openMenu: PropTypes.func.isRequired,
  /** Funciton to close the menu */
  closeMenu: PropTypes.func.isRequired,
};

Banner.defaultProps = {
  user: {},
  renderKey: () => {},
};

export default Banner;
