/* eslint no-console:0 */
import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const EXAMINEE_FEED_REQUEST = 'EXAMINEE_FEED_REQUEST';
export const EXAMINEE_FEED_SUCCESS = 'EXAMINEE_FEED_SUCCESS';
export const EXAMINEE_FEED_ERROR = 'EXAMINEE_FEED_ERROR';

export const ADMINS_LIST_REQUEST = 'ADMINS_LIST_REQUEST';
export const ADMINS_LIST_SUCCESS = 'ADMINS_LIST_SUCCESS';
export const ADMINS_LIST_ERROR = 'ADMINS_LIST_ERROR';

export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_ERROR = 'ADD_ADMIN_ERROR';

export const ADMIN_RESET_PASSWORD_REQUEST = 'ADMIN_RESET_PASSWORD_REQUEST';
export const ADMIN_RESET_PASSWORD_SUCCESS = 'ADMIN_RESET_PASSWORD_SUCCESS';
export const ADMIN_RESET_PASSWORD_ERROR = 'ADMIN_RESET_PASSWORD_ERROR';

export const UPDATE_ADMIN_PERMISSION_REQUEST = 'UPDATE_ADMIN_PERMISSION_REQUEST';
export const UPDATE_ADMIN_PERMISSION_SUCCESS = 'UPDATE_ADMIN_PERMISSION_SUCCESS';
export const UPDATE_ADMIN_PERMISSION_ERROR = 'UPDATE_ADMIN_PERMISSION_ERROR';

export const ADMINS_DELETE_REQUEST = 'ADMINS_DELETE_REQUEST';
export const ADMINS_DELETE_SUCCESS = 'ADMINS_DELETE_SUCCESS';
export const ADMINS_DELETE_ERROR = 'ADMINS_DELETE_ERROR';

const list = ({
  url,
  request,
  success,
  failure,
}) => (
  (queryParams) => (
    (dispatch) => {
      sendIt(dispatch, request);
      return http.post(url, queryParams).then(({ data }) => {
        sendIt(dispatch, success, data);
        return data;
      }).catch((e) => {
        console.error(e);
        sendIt(dispatch, failure, e);
      });
    }
  )
);

export const loadExamineeFeed = list({
  url: '/examinees/list',
  request: EXAMINEE_FEED_REQUEST,
  success: EXAMINEE_FEED_SUCCESS,
  failure: EXAMINEE_FEED_ERROR,
});

export const loadAdmins = list({
  url: '/admins/list',
  request: ADMINS_LIST_REQUEST,
  success: ADMINS_LIST_SUCCESS,
  failure: ADMINS_LIST_ERROR,
});

export const addAdmin = ({
  admins,
  groups,
  permissions,
  sendEmail = true,
}) => ((dispatch) => {
  sendIt(dispatch, ADD_ADMIN_REQUEST);
  return http.put('/admins', {
    admins,
    groups,
    permissions,
    sendEmail,
  }).then(() => {
    sendIt(dispatch, ADD_ADMIN_SUCCESS);
    return true;
  }).catch((e) => {
    console.error(e);
    sendIt(dispatch, ADD_ADMIN_ERROR, e);
  });
});

export const updateAdminPermission = ({
  adminGroupIds,
  permissions,
}) => ((dispatch) => {
  sendIt(dispatch, UPDATE_ADMIN_PERMISSION_REQUEST);
  return http.post('/admins', {
    adminGroupIds,
    permissions,
  }).then(() => {
    sendIt(dispatch, UPDATE_ADMIN_PERMISSION_SUCCESS, { adminGroupIds, permissions });
    return true;
  }).catch((e) => {
    console.error(e);
    sendIt(dispatch, UPDATE_ADMIN_PERMISSION_ERROR, e);
  });
});

export const resetAdminPassword = (userId) => ((dispatch) => {
  sendIt(dispatch, ADMIN_RESET_PASSWORD_REQUEST, { userId });
  return http.post('/admins/reset-password', {
    userId,
  }).then(() => {
    sendIt(dispatch, ADMIN_RESET_PASSWORD_SUCCESS, { userId });
    return true;
  }).catch((e) => {
    console.error(e);
    sendIt(dispatch, ADMIN_RESET_PASSWORD_ERROR, e);
  });
});

export const deleteAdmins = (adminGroupIds) => ((dispatch) => {
  sendIt(dispatch, ADMINS_DELETE_REQUEST);
  return http.delete('/admins', {
    data: {
      adminGroupIds,
    },
  }).then(() => {
    sendIt(dispatch, ADMINS_DELETE_SUCCESS, { adminGroupIds });
    return true;
  }).catch((e) => {
    console.error(e);
    sendIt(dispatch, ADMINS_DELETE_ERROR, e);
  });
});
