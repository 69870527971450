import { connect } from 'react-redux';
import { find } from 'lodash';
import ExamineeChangeProductComponent from '../components/ExamineeChangeProductComponent';

import { isFinished } from '../../utils/products';

import { changeActive } from '../../actions/assignedProductActions';

const mapStateToProps = (state) => {
  const products = state.assignedProduct.list.map((prod) => {
    const group = find(state.group.list, { id: prod.groupId });
    return {
      ...prod,
      ...isFinished(prod),
      groupName: group.name,
    };
  });
  return {
    products,
  };
};

const mapDispatchToProps = (dispatch) => ({
  change: (newProduct) => {
    dispatch(changeActive(newProduct));
  },
});

const ExamineeChangeProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExamineeChangeProductComponent);

export default ExamineeChangeProductContainer;
