import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import httpClient from '../../utils/httpCli';

const UniqueName = (initialName) => (Yup
  .string()
  .required('Required')
  .test(
    'unique-group-name',
    'This group name is taken',
    (value, context) => (
      new Promise((resolve, reject) => {
        // FYI:  true === valid in the Yup sense
        if (value === initialName) {
          return resolve(true);
        }
        if (isEmpty(value)) {
          return resolve(context.createError({ message: 'Please enter an email address' }));
        }
        return httpClient.get('/check/group', { params: { name: value } })
          .then((response) => resolve(response.data === true))
          .catch((err) => {
            const isStorybook = window.location.href.includes('localhost:9009');
            /*eslint-disable-next-line*/
            console.log(isStorybook ? 'Email lookup can NOT work in storybook' : err.message);
            if (isStorybook) {
              return resolve();
            }
            return reject(err);
          });
      })),
  )
);

export default UniqueName;
