import { connect } from 'react-redux';
import FinishComponent from '../components/FinishComponent';

import withRouter from '../../utils/withRouter';
import {
  finish,
  setKey,
  clearKey,
} from '../../actions/assessmentActions';

const mapStateToProps = (state, props) => {
  const {
    user: {
      profile: user,
    },
    assessment: {
      chain,
      active: {
        testType: {
          name,
        },
        testStatus,
        testItems,
        time,
      },
      loading,
    },
  } = state;

  const {
    requireAllItems,
    ipp = 8,
    router: {
      location: {
        pathname,
      },
    },
    color,
    skipResults,
  } = props;

  let firstMissedPage;
  const allMarked = testItems.reduce((acc, it, i) => {
    if (!it.userResponse) {
      if (firstMissedPage === undefined) {
        firstMissedPage = Math.ceil(i / ipp);
        if (i % ipp === 0) {
          firstMissedPage += 1;
        }
      }
      return false;
    }
    return acc;
  }, true);

  let nextTest;
  if (skipResults && chain[1]) {
    const abilities = pathname.indexOf('abilities') >= 0 ? '/abilities' : '';
    const type = chain[1].testDefinition.testType;
    nextTest = `/assessment${abilities}/${type.abbreviation.toLowerCase() || type.name.toLowerCase()}/directions`;
  } else {
    nextTest = '/';
  }

  return {
    user,
    color,
    name,
    requireAllItems,
    allMarked,
    timeLeft: time > 0,
    backUrl: pathname.replace('finish', firstMissedPage || '1'),
    testStatus,
    loading,
    nextTest,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  finish: (testStatus, user, nextTest) => {
    dispatch(finish({
      assignedProductId: testStatus.assignedProductId,
      testDefinitionId: testStatus.testDefinitionId,
    })).then(() => {
      const {
        skipResults,
        router: {
          navigate,
          location: {
            pathname,
          },
        },
      } = props;

      if (skipResults) {
        navigate(nextTest);
        return;
      }

      navigate(pathname.replace('finish', 'complete'));
    });
  },
  setRenderKey: (render) => {
    dispatch(setKey({
      render,
    }));
  },
  clearRenderKey: () => {
    dispatch(clearKey());
  },
});

const FinishContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinishComponent);

export default withRouter(FinishContainer);
