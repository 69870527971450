/* eslint react/forbid-prop-types:0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  green,
  red,
} from './Colors';

const AnswerDiv = styled.div`
  color: ${(props) => (props.$correct ? green : red)}
`;

/** Shows the children if value is defined. Colors children if value === correct */
const ExampleAnswer = ({
  value,
  correct,
  children,
}) => {
  if (value !== undefined) {
    return (
      <AnswerDiv
        $correct={value === correct}
      >
        {children}
      </AnswerDiv>
    );
  }
  return '';
};

ExampleAnswer.propTypes = {
  value: PropTypes.any,
  correct: PropTypes.any,
  children: PropTypes.node.isRequired,
};

ExampleAnswer.defaultProps = {
  value: undefined,
  correct: undefined,
};

export default ExampleAnswer;
