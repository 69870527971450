import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { filter } from 'lodash';

import * as Icons from '../components/icons';
import {
  MediumButton as Button,
} from '../components/Button';
import * as Colors from '../components/Colors';
import DefaultFilter from './Filter';
import ScreenReader from '../components/ScreenReader';

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background-color: ${Colors.white};
  border-radius: 10px;
`;

const FilterButton = styled(Button)`
  text-decoration: none;
  width: 100%;
  color: ${Colors.grey};
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0rem 1rem !important;
`;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1rem;
`;

const FilterForm = ({ columns, filters, setFilters }) => {
  const [ showFilter, setShowFilter ] = useState(false);
  return (
    <ButtonContainer>
      <FilterButton
        link
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setShowFilter(!showFilter);
          setFilters([]);
        }}
      >
        {
          showFilter
            ? <Icons.CloseSearch />
            : <Icons.Search />
        }
        &nbsp;
        Filter/Search
        <ScreenReader> the following table:</ScreenReader>
        {
          showFilter
            ? <Icons.DownArrow style={{ marginLeft: 'auto' }} />
            : <Icons.RightArrow style={{ marginLeft: 'auto' }} />
        }
      </FilterButton>
      {
        showFilter ? (
          <FilterContainer>
            {
              columns.map((column) => {
                if (column.disableFilter || column.disableFilters) {
                  return '';
                }
                const Comp = column.Filter || DefaultFilter;
                const id = column.id || column.accessor;
                const setFilterCallback = (value) => {
                  const newFilters = filter(filters, (c) => (c.id !== id));
                  if (value === undefined) {
                    return setFilters(newFilters);
                  }
                  newFilters.push({ id, value });
                  return setFilters(newFilters);
                };
                return (
                  <Comp
                    setFilter={setFilterCallback}
                    column={column}
                    key={`filterForm_${id}`}
                  />
                );
              })
            }
          </FilterContainer>
        ) : ''
      }
    </ButtonContainer>
  );
};

FilterForm.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    accessor: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
    Filter: PropTypes.oneOfType([ PropTypes.node, PropTypes.elementType ]),
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  })),
  setFilters: PropTypes.func.isRequired,
};

FilterForm.defaultProps = {
  columns: [],
  filters: [],
};

export default FilterForm;
