import { connect } from 'react-redux';
import VrAssessmentComponent from '../components/VrAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const VrAssessmentContainer = connect(
  mapStateToProps({ ipp: 1 }),
  mapDispatchToProps(),
)(VrAssessmentComponent);

export default withRouter(VrAssessmentContainer);
