import { find } from 'lodash';
import { DateTime } from 'luxon';

export const testTypes = [
  { id: 1, name: 'Interests' },
  { id: 2, name: 'Mechanical Reasoning' },
  { id: 3, name: 'Spatial Relations' },
  { id: 4, name: 'Verbal Reasoning' },
  { id: 5, name: 'Numerical Ability' },
  { id: 6, name: 'Language Usage' },
  { id: 7, name: 'Word Knowledge' },
  { id: 8, name: 'Perceptual Speed and Accuracy' },
  { id: 9, name: 'Manual Speed and Dexterity' },
  { id: 10, name: 'Values' },
  { id: 11, name: 'Interests Professional' },
  { id: 12, name: 'Interés' },
  { id: 13, name: 'Razonamiento Mecánico' },
  { id: 14, name: 'Relaciones De Espacio' },
  { id: 15, name: 'Razonamiento Verbal' },
  { id: 16, name: 'Habilidad Numérica' },
  { id: 17, name: 'Uso Del Lenguaje' },
  { id: 18, name: 'Conocimiento de Palabras' },
  { id: 19, name: 'Rapidez y Precisión de Percepción' },
  { id: 20, name: 'Velocidad Manual y Destreza' },
  { id: 21, name: 'Valors' },
];

export const products = [
  {
    id: 1,
    name: 'VIA System',
    language: 'english',
  },
  {
    id: 2,
    name: 'VIA System Professional',
    language: 'english',
    professional: true,
  },
  {
    id: 3,
    name: 'VIA System Español',
    language: 'spanish',
  },
  {
    id: 4,
    name: 'VIA System - Time and One Half',
    language: 'english',
  },
  {
    id: 5,
    name: 'VIA System Professional - Time and One Half',
    language: 'english',
    professional: true,
  },
  {
    id: 6,
    name: 'VIA System Español - Time and One Half',
    language: 'spanish',
  },
  {
    id: 7,
    name: 'VIA System - Double Time',
    language: 'english',
  },
  {
    id: 8,
    name: 'VIA System Professional - Double Time',
    language: 'english',
    professional: true,
  },
  {
    id: 9,
    name: 'VIA System Español - Double Time',
    language: 'spanish',
  },
];
export const productMap = new Map();
products.forEach((product) => {
  productMap.set(product.id, product);
});

export const interestsIds = [1, 11, 12];
export const abilitiesIds = [2, 3, 4, 5, 6, 7, 8, 9, 13, 14, 15, 16, 17, 18, 19, 20];
export const valuesIds = [10, 21];

/** Parses and formats an assignedProduct */
export const parseProduct = (product, examineeGroups) => {
  let examineeGroup = examineeGroups;
  if (Array.isArray(examineeGroups)) {
    examineeGroup = find(examineeGroups, { id: product.userGroupId });
  }
  const prodConfigs = productMap.get(product.productId);
  if (!prodConfigs) {
    throw new Error(`Unkown product id: ${product.productId}`);
  }

  const rtn = {
    id: product.id,
    productId: product.productId,
    professional: prodConfigs.professional,
    suppressed: false, // TODO: implment this from server side
    status: product.status,
    language: prodConfigs.language,
    examineeGroupId: product.userGroupId,
    userId: examineeGroup.userId,
    groupId: examineeGroup.groupId,
    testStatus: product.testStatus.map((stat) => ({
      ...stat,
      isFinished: stat.isFinished === 1,
    })),
    createdAt: DateTime.fromISO(product.createdAt),
    updatedAt: DateTime.fromISO(product.updatedAt),
  };
  return rtn;
};

export const isType = (type, product) => {
  switch (type) {
    case 'interests': {
      return interestsIds.indexOf(product.testType.id) > -1;
    }
    case 'abilities': {
      return abilitiesIds.indexOf(product.testType.id) > -1;
    }
    case 'values': {
      return valuesIds.indexOf(product.testType.id) > -1;
    }
    default: {
      return false;
    }
  }
};

export const pluckStatus = (typeArray, product) => {
  if (Array.isArray(product)) {
    return product.filter((stat) => typeArray.indexOf(stat.testDefinition.testType.id) >= 0);
  }
  return product
    .testStatus
    .filter((stat) => typeArray.indexOf(stat.testDefinition.testType.id) >= 0);
};

export const getStatus = (type, product) => {
  switch (type) {
    case 'interests': {
      return pluckStatus(interestsIds, product);
    }
    case 'abilities': {
      return pluckStatus(abilitiesIds, product);
    }
    case 'values': {
      return pluckStatus(valuesIds, product);
    }
    default: {
      return [];
    }
  }
};

export const isFinished = (product) => {
  const rtn = {};

  product.testStatus.forEach((stat) => {
    if (find(valuesIds, (i) => i === stat.testDefinition.testType.id)) {
      // undefined => set to isFinished
      // false => leave alone
      // true => set to isFinshed
      if (rtn.values !== false) {
        rtn.values = stat.isFinished;
      }
    }
    if (find(interestsIds, (i) => i === stat.testDefinition.testType.id)) {
      if (rtn.interersts !== false) {
        rtn.interests = stat.isFinished;
      }
    }
    if (find(abilitiesIds, (i) => i === stat.testDefinition.testType.id)) {
      if (rtn.abilities !== false) {
        rtn.abilities = stat.isFinished;
      }
    }
  });
  return rtn;
};
