/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import FormError from './FormError';

/** Wraps FormError with formik values */
const FormikError = ({ formik, accessor, message }) => (
  <FormError
    show={formik.touched[accessor] && formik.errors[accessor]}
  >
    {message || formik.errors[accessor] || ''}
  </FormError>
);

FormikError.propTypes = {
  formik: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  accessor: PropTypes.string.isRequired,
  message: PropTypes.string,
};

FormikError.defaultProps = {
  message: undefined,
};

export default FormikError;
