import Directions from './DirectionsContainer';

import InterestsDirectionsComponent from '../components/InterestsDirectionsComponent';

const InterestsDirectionsContainer = Directions({
  type: 'interests',
  component: InterestsDirectionsComponent,
});

export default InterestsDirectionsContainer;
