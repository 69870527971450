/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  useAsyncDebounce,
} from 'react-table';
import { v4 as uuid } from 'uuid';

import { CenteredSpinner as Spinner } from '../components/Spinner';
import { MediumButton as Button } from '../components/Button';
import * as Colors from '../components/Colors';
import * as Icons from '../components/icons';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;
`;

const Resizer = styled.div`
  display:inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  cursor: resizer;
`;

const HeaderRow = styled.tr`
`;

const Thead = styled.thead`
`;

const Th = styled.th`
  background-color: ${Colors.blue};
  color: ${Colors.white};
  &:first-child {
    border-radius: 10px 0 0 0;
  }
  &:last-child {
    border-radius: 0 10px 0 0;
  }
  &:hover {
    border-left: 1px solid ${Colors.white};
    border-right: 1px solid ${Colors.white};
  }
  padding: .25rem 0;
  overflow:hidden;

  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 0 0.5rem;
  min-height: 40px;

  &:hover {
    span:last-child  {
      display: flex;
    }
  }
`;

const CaretContainer = styled.span`
  display: none;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 13px;
  margin-left: .5rem;
`;

const Tr = styled.tr`
  background-color: ${Colors.white};
  &:nth-child(even) {
    background-color: ${Colors.superLightGrey};
  }
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
`;

const Td = styled.td`
  color: ${Colors.black};
  min-height: 1rem;
  min-width: 2rem;
  overflow: hidden;
  padding: .5rem;
`;

const Footer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: .25rem 1rem;
`;

const PageButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const renderPaddedPage = (page, pageSize, headerGroups, prepareRow) => (
  page.map((row) => {
    prepareRow(row);
    const cells = [];
    row.cells.forEach((cell) => {
      const cellProps = cell.column.getCustomProps ? cell.column.getCustomProps() : undefined;
      cells.push(
        <Td
          {...cell.getCellProps()}
        >
          {cell.value === null ? '' : cell.render('Cell', cellProps)}
        </Td>,
      );
    });
    return (
      <Tr {...row.getRowProps()}>
        {cells}
      </Tr>
    );
  })
);

const Container = styled.div`
`;

const ActionsContainer = styled.div`
  position: absolute;
  padding: .5rem;
  margin: 0;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  z-index: 1;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  transform: translate(0, 50px);
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input type="checkbox" ref={resolvedRef} {...rest} />
    );
  },
);

const ToggleAllCheckbox = ({ showGroupActions }) => (
  ({
    getToggleAllPageRowsSelectedProps,
    showActions,
    setShowActions,
  }) => (
    <>
      <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
      {
        showGroupActions && (
          <Button
            clear
            onClick={() => {
              setShowActions(!showActions);
            }}
          >
            {
              showActions
                ? <Icons.DownArrow style={{ color: Colors.white, paddingLeft: '.25rem' }} />
                : <Icons.RightArrow style={{ color: Colors.white, paddingLeft: '.25rem' }} />
            }
          </Button>
        )
      }
    </>
  )
);

const CheckboxColumn = ({ row }) => (
  <div
    style={{ textAlign: 'center' }}
  >
    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  </div>
);

/**
 * A table used to display data. If you want to use filtering use the
 * `FilterTable` component. However, the filter table documentation is placed
 * here.
 */
export const StaticEapTable = ({
  columns = [],
  data,
  total,
  loading,
  loadData,
  onSelectedRows,
  groupActions,
  filters: extFilters,
  onClick,
  emptyContent,
  showGroupActions,
}) => {
  const isManual = typeof loadData === 'function';
  const selectRows = typeof onSelectedRows === 'function';
  const [pageCount, setPageCount ] = useState(Math.ceil(total / 10));
  let loadDataDebounce = () => {};
  if (isManual) {
    loadDataDebounce = useAsyncDebounce(loadData, 100);
  }
  const [ showActions, setShowActions ] = useState(false);

  const controlledState = extFilters !== undefined ? (
    (state) => (
      useMemo(
        () => ({
          ...state,
          filters: extFilters,
        }),
        [state, extFilters],
      )
    )
  ) : undefined;

  const instance = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      autoResetSortBy: false,
      manualSortBy: isManual,
      manualFilterBy: true,
      manualFilters: true,
      manualPagination: isManual,
      pageSize: 10,
      pageCount,
      disableMultiSort: true,
      useControlledState: controlledState,
      showActions,
      setShowActions,
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      if (!selectRows && groupActions.length === 0) {
        return;
      }
      const displayGroupActions = groupActions.length > 0;
      hooks.visibleColumns.push((cols) => [
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          width: 50,
          disableSorting: true,
          style: {
            justifyContent: 'center',
            marginLeft: displayGroupActions ? '17px' : '0px',
          },
          Header: ToggleAllCheckbox({ showGroupActions: displayGroupActions }),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: CheckboxColumn,
        },
        ...cols,
      ]);
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters,
    },
    previousPage,
    nextPage,
    setPageSize,
    canNextPage,
    canPreviousPage = true,
    selectedFlatRows,
  } = instance;

  React.useEffect(() => {
    setPageCount(Math.ceil(total / pageSize));
  }, [ total, pageSize ]);

  React.useEffect(() => {
    loadDataDebounce({
      filterBy: filters,
      sortBy: sortBy[0],
      limit: pageSize,
      page: pageIndex,
    });
  }, [
    loadData,
    sortBy,
    filters,
    pageSize,
    pageIndex,
  ]);

  React.useEffect(() => {
    if (selectRows) {
      onSelectedRows(selectedFlatRows.map((row) => row.original));
    }
  }, [ selectRows, selectedFlatRows ]);

  React.useEffect(() => {
    setShowActions(showGroupActions);
  }, [ showGroupActions ]);

  const pageOptions = [
    5,
    10,
    20,
    25,
    50,
    100,
  ];

  const Actions = (
    <ActionsContainer>
      {
        groupActions.map((action) => (
          <Button
            link
            key={uuid()}
            disabled={selectedFlatRows.length === 0}
            onClick={() => action.action(selectedFlatRows)}
          >
            {
              action.render ? action.render() : ''
            }
            {
              typeof action.display === 'string'
                ? action.display.toUpperCase()
                : action.display
            }
          </Button>
        ))
      }
    </ActionsContainer>
  );

  let content = renderPaddedPage(page, pageSize, headerGroups, prepareRow);
  if (loading) {
    content = (
      <Tr>
        <td colSpan={columns.length}>
          <Spinner />
        </td>
      </Tr>
    );
  } else if (data.length === 0 && emptyContent) {
    content = (
      <Tr>
        <td colSpan={columns.length}>{emptyContent()}</td>
      </Tr>
    );
  }

  return (
    <Container
      onClick={onClick}
    >
      { showActions && Actions }
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <HeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {
                    ...column.getHeaderProps()
                  }
                >
                  {
                    column.disableSorting || column.disableSort
                      ? (
                        <HeaderContainer style={column.style}>
                          {
                            typeof column.render('Header') === 'string'
                              ? <span>{column.render('Header').toUpperCase()}</span>
                              : column.render('Header')
                          }
                        </HeaderContainer>
                      ) : (
                        <HeaderContainer
                          {...column.getSortByToggleProps()}
                          style={column.style}
                        >
                          {
                            typeof column.render('Header') === 'string'
                              ? <span>{column.render('Header').toUpperCase()}</span>
                              : column.render('Header')
                          }
                          <CaretContainer
                            style={{
                              display: column.isSorted ? 'flex' : 'none',
                            }}
                          >
                            <Icons.UpArrow
                              style={{
                                color: column.isSorted && !column.isSortedDesc
                                  ? Colors.white
                                  : Colors.black,
                              }}
                            />
                            <Icons.DownArrow
                              style={{
                                color: column.isSorted && column.isSortedDesc
                                  ? Colors.white
                                  : Colors.black,
                              }}
                            />
                          </CaretContainer>
                        </HeaderContainer>
                      )
                  }
                  <Resizer {...column.getResizerProps()} />
                </Th>
              ))}
            </HeaderRow>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {content}
        </tbody>
      </Table>
      <Footer>
        <div>
          Show:
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageOptions.map((pZ) => (
              <option key={pZ} value={pZ}>
                {pZ}
              </option>
            ))}
          </select>
        </div>
        <PageButtonContainer>
          <span>
            {
              `${pageSize * pageIndex + 1} - ${(pageIndex + 1) < pageCount ? pageSize * (pageIndex + 1) : total} of ${total}`
            }
          </span>
          <Button
            clear
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            <Icons.LeftArrow size="2x" />
          </Button>
          &nbsp;
          <Button
            clear
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            <Icons.RightArrow size="2x" />
          </Button>
        </PageButtonContainer>
      </Footer>
    </Container>
  );
};

StaticEapTable.propTypes = {
  /** The react-table columns. */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /** An optional filter component. */
    Filter: PropTypes.oneOfType([ PropTypes.node, PropTypes.elementType ]),
    Header: PropTypes.string,
    accessor: PropTypes.string,
    disableSort: PropTypes.bool,
    disableSorting: PropTypes.bool,
    /** Optional props to pass to the filter component. */
    getCustomProps: PropTypes.func,
    /** Optional props to pass to the filter component. */
    customFilterProps: PropTypes.object,
  })).isRequired,
  /** The data to be rendered */
  data: PropTypes.arrayOf(PropTypes.object),
  /** An array of filters used by FilterTable. Use that component rather than this prop. */
  filters: PropTypes.arrayOf(PropTypes.object),
  /** An array of objects with a display and action key. Enables checkboxs. */
  groupActions: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    action: PropTypes.func,
  })),
  /** Show the group actions. The value must change to trigger.*/
  showGroupActions: PropTypes.bool,
  /** A function to dynamically load data. Called each time the table is
   * filtered or storted. Called with an object containing filterBy, sortBy,
  * limit, and page.
  */
  loadData: PropTypes.func,
  /** Displays a loading spinner, if true*/
  loading: PropTypes.bool,
  /** A function to call when the table container is clicked. */
  onClick: PropTypes.func,
  /** A function to call when the selected rows change. */
  onSelectedRows: PropTypes.func,
  /** The total number of elemnts. */
  total: PropTypes.number,
  /** Empty content to display when there is no data. */
  emptyContent: PropTypes.oneOfType([ PropTypes.node, PropTypes.element, PropTypes.elementType ]),
};

StaticEapTable.defaultProps = {
  data: [],
  groupActions: [],
  total: 0,
  loadData: undefined,
  loading: false,
  onClick: () => {},
  onSelectedRows: undefined,
  emptyContent: undefined,
  filters: undefined,
  showGroupActions: undefined,
};

export default StaticEapTable;
