import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import {
  Colors,
} from '../../components';

const ChartLabel = styled.g`
  font-size: 1rem;
`;

const DiscoverInterests = ({
  interests,
  width: inWidth,
}) => {
  let width = inWidth;
  if (width <= 0) {
    width = 500;
  }
  const margin = {
    left: rem(13),
    right: rem(2),
    top: rem(1),
    bottom: rem(1),
  };
  const barHeight = rem(2);
  const barPadding = 0.1;
  const height = interests.length * (barHeight + barPadding);

  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right ]);

  const y = d3
    .scaleBand()
    .domain(interests.map((c) => (`${c.name} ${c.rawScore}`)))
    .rangeRound([margin.top, height - margin.bottom])
    .padding(barPadding);

  const yAxis = d3.axisLeft(y);
  const topAxis = d3.axisTop(x);
  const bottomAxis = d3.axisBottom(x);

  const G = interests.map((c) => {
    let delay = 0;
    const duration = 1000;
    const startWidth = x(0);
    return (
      <g
        key={`interestsBar_${c.id}`}
      >
        <rect
          ref={(node) => {
            d3
              .select(node)
              .transition()
              .delay(delay)
              .ease(d3.easeLinear)
              .duration(duration)
              .attr('width', x(c.norm) - startWidth);
            delay += duration;
          }}
          x={startWidth}
          y={y(`${c.name} ${c.rawScore}`)}
          width={0}
          height={y.bandwidth()}
          fill={Colors.blue}
        />
        <text
          x={x(c.norm)}
          y={y(`${c.name} ${c.rawScore}`)}
          dy={(barHeight + barPadding) / 2 + rem(0.25)}
          ref={(node) => {
            if (node) {
              const bbox = node.getBBox();
              d3
                .select(node)
                .attr(
                  'x',
                  x(c.norm) - bbox.width,
                );
            }
          }}
          fill={Colors.white}
        >
          {c.norm}%
        </text>
      </g>
    );
  });

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      <ChartLabel ref={(node) => d3.select(node).call(yAxis)} transform={`translate(${rem(13)},0)`} />
      {G}
      <g ref={(node) => d3.select(node).call(topAxis)} transform={`translate(0,${margin.top})`} />
      <g ref={(node) => d3.select(node).call(bottomAxis)} transform={`translate(0,${height - margin.bottom})`} />
    </svg>
  );
};

DiscoverInterests.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    norm: PropTypes.number.isRequired,
  })),
  width: PropTypes.number,
};

DiscoverInterests.defaultProps = {
  interests: [],
  width: 500,
};

export default DiscoverInterests;
