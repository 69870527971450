import styled from 'styled-components';

import {
  grey,
} from './Colors';

const HR = styled.hr`
  margin: 3.25rem 0rem;
  border-top: none;
  border-bottom: 1px solid ${grey};
`;

export default HR;
