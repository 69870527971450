import React, { useEffect } from 'react';
import styled from 'styled-components';
/*
import PropTypes from 'prop-types';
*/
import { useParams } from 'react-router-dom';

import {
  H1 as InH1,
  ClusterButton,
  ClusterContainer,
  CenteredSpinner as Spinner,
} from '../../components';

import JobTile from './JobTile';

const CustomWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 1400px;
  justify-content: flex-start;
  align-items: center;
`;

const CustomContent = styled.div`
  padding: 2em 8em;

  @media (max-width: 768px) {
    padding: .5em .5em;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    padding: 2em 2em;
  }
`;

const H1 = styled(InH1)`
  text-align: center;
`;

const Centered = styled.p`
  text-align: center;
`;

const PublicSucceed = ({
  favoriteJobs,
  clusters,
  loadResults,
  unloadResults,
  loading,
  isPublic,
  suppressed,
}) => {
  const { assignedProductId } = useParams();

  useEffect(() => {
    loadResults(assignedProductId);
    return () => { unloadResults(); };
  }, [ assignedProductId ]);

  if (loading) {
    return (
      <CustomWrapper>
        <CustomContent
          id="content"
        >
          <H1>Welcome to the Succeed phase</H1>
          <Spinner />
          <Centered>Please wait while we load your results</Centered>
        </CustomContent>
      </CustomWrapper>
    );
  }

  if (!isPublic || suppressed) {
    return (
      <CustomWrapper>
        <CustomContent
          id="content"
        >
          <H1>Welcome to the Succeed phase</H1>
          <Centered>
            These results are no longer public.
          </Centered>
        </CustomContent>
      </CustomWrapper>
    );
  }

  const Jobs = favoriteJobs.slice(0, 3).map((j, i) => (
    <JobTile
      key={`jobTile_${j.jobId}`}
      index={i}
      {...j}
    />
  ));

  const Clusters = clusters.slice(0, 3).map((clust, i) => (
    <ClusterButton
      key={`cluster_${clust.id}`}
      index={i}
      name={clust.name}
      color={clust.color}
    />
  ));

  return (
    <CustomWrapper>
      <CustomContent
        id="content"
      >
        <H1>Welcome to the Succeed phase</H1>
        <p>
          Here you will see your top three jobs. We encourage you to explore
          job listings in your local area.
        </p>
        {Jobs}
        <p>
          Below are your top three career clusters. If you wish to explore more
          jobs, feel free to click on any of these clusters.
        </p>
        <ClusterContainer>
          {Clusters}
        </ClusterContainer>
      </CustomContent>
    </CustomWrapper>
  );
};

PublicSucceed.defaultProps = {
  favoriteJobs: [],
  rankedClusters: {
    ranked: [],
  },
  clusters: {},
};

export default PublicSucceed;
