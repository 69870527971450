import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const CSSWrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: ${(props) => {
    let time = 500;
    if (props.timeout) {
      time = props.timeout.enter || props.timeout;
    }
    return `opacity ${time}ms ease-in`;
  }}
  }

  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: ${(props) => {
    let time = 500;
    if (props.timeout) {
      time = props.timeout.exit || props.timeout;
    }
    return `opacity ${time}ms ease-in`;
  }}
  }

  .fade-appear {
    opacity: 0.01;
  }
  .fade-appear-active {
    opacity: 1;
    transition: ${(props) => {
    let time = 500;
    if (props.timeout) {
      time = props.timeout.appear || props.timeout.enter || props.timeout;
    }
    return `opacity ${time}ms ease-in`;
  }}
  }
  .fade-appear-done {
    opacity: 1;
  }
`;

/** A fade wrapper around the [react-transition-group](https://reactcommunity.org/react-transition-group/). Additional props are passed through. */
const Fade = ({
  children,
  appear,
  show,
  timeout,
  name,
  mountOnEnter,
  wrapperStyle,
  ...rest
}) => (
  <CSSWrapper timeout={timeout} style={wrapperStyle}>
    <CSSTransition
      appear={appear}
      in={show === undefined ? appear : show}
      timeout={timeout}
      classNames="fade"
      mountOnEnter={mountOnEnter}
      {...rest}
    >
      {children}
    </CSSTransition>
  </CSSWrapper>
);

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  /** Alias for react-transition-group `in` */
  show: PropTypes.bool,
  /** Will run the animation on mount, even if show is undefined */
  appear: PropTypes.bool,
  /** Adjustiable timeouts */
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      enter: PropTypes.number,
      exit: PropTypes.number,
      appear: PropTypes.number,
    }),
  ]),
  /** Will mount the compontent before the animiation triggers */
  mountOnEnter: PropTypes.bool,
  /* eslint-disable */
  /** css styles for the wrapper */
  wrapperStyle: PropTypes.object,
  /* eslint-enable */
};

Fade.defaultProps = {
  show: undefined,
  appear: true,
  timeout: 500,
  mountOnEnter: false,
  wrapperStyle: {},
};

export default Fade;
