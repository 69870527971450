/* eslint react-hooks/exhaustive-deps:0, react/forbid-prop-types:0, react/jsx-no-useless-fragment:0
*/
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useNavigate,
  Route,
  Routes,
  Outlet,
} from 'react-router-dom';

import {
  scrollTo,
} from '../../utils/scrollTo';

import ClusterTabs from './ClusterTabs';

import {
  H1 as RawH1,
  SmallButton as Button,
  CenteredSpinner,
  Fade,
  ExamineeMenu,
  ScrollToSelf,
} from '../../components';

import CareerWheel from './DiscoverCareerWheel';
import GraphContainer from './GraphContainer';
import JobSorter from './JobSorter';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredButton = styled(Button)`
  margin: auto;
`;

const Content = styled.div`
  margin-right: .1rem;
`;

const H1 = styled(RawH1)`
  margin: 1rem;
`;

const Loading = () => (
  <>
    <H1>Please Wait</H1>
    <CenteredSpinner />
  </>
);

const DiscoverComponent = ({
  abilities,
  activeProduct,
  allClusters,
  allFinished,
  rankedClusters,
  favoriteJobs,
  interests,
  loadClusters,
  loadResults,
  loading,
  toggleFavoriteJob,
  type,
  unloadResults,
  updateFavoriteJob,
  user,
  values,
  manualDiscover,
  suppressed,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    loadResults(activeProduct).then((data) => {
      if (data && data.jobs && data.jobs.length >= 3 && !manualDiscover) {
        navigate('/dashboard/examinee/succeed', { replace: true });
      }
    });
    return () => {
      unloadResults();
    };
  }, []);

  const resultsRef = useRef();
  const clusterButtonsRef = useRef();
  const jobSorterRef = useRef();

  if (!loading && rankedClusters.ranked.length === 0) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>You have no results. Please finish an assessment.</p>
            <CenteredButton
              to="/dashboard/examinee/explore"
            >
              Back
            </CenteredButton>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  if (loading || rankedClusters.ranked.length === 0) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <div>
            <Loading />
          </div>
        </ExamineeMenu>
      </>
    );
  }

  if (!allFinished) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>You must finish all your assessments before you can Discover careers.</p>
            <CenteredButton
              to="/dashboard/examinee/explore"
            >
              Back
            </CenteredButton>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  if (suppressed) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <p>
              Your administrator has not released your results. Please contact
              them to receive your results.
            </p>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  return (
    <>
      <ExamineeMenu
        assigned={activeProduct}
        id="content"
      >
        <Content>
          <Routes>
            <Route
              path="/"
              element={(
                <Fade
                  appear
                  timeout={1000}
                >
                  <>
                    <CareerWheel
                      type={type}
                      onClick={() => {
                        navigate('/dashboard/examinee/discover/graph');
                      }}
                    />
                    <Outlet />
                  </>
                </Fade>
              )}
            >
              <Route
                path="graph"
                element={(
                  <div ref={resultsRef}>
                    <Fade
                      show
                      appear
                      timeout={1000}
                    >
                      <ScrollToSelf
                        delay={100}
                      >
                        <>
                          <GraphContainer
                            values={values}
                            interests={interests}
                            abilities={abilities}
                            rankedClusters={rankedClusters}
                            onNext={() => {
                              navigate('/dashboard/examinee/discover/cluster/0');
                            }}
                          />
                          <Outlet />
                        </>
                      </ScrollToSelf>
                    </Fade>
                  </div>
                )}
              />
              <Route
                path="cluster/:clusterIndex"
                element={(
                  <div
                    ref={clusterButtonsRef}
                  >
                    <GraphContainer
                      values={values}
                      interests={interests}
                      abilities={abilities}
                      rankedClusters={rankedClusters}
                      onNext={() => {
                        navigate('/dashboard/examinee/discover/cluster/0');
                      }}
                    />
                    <Fade
                      show
                      appear
                      timeout={1000}
                      onExit={() => {
                        scrollTo(clusterButtonsRef);
                      }}
                    >
                      <ScrollToSelf
                        delay={100}
                      >
                        <ClusterTabs
                          clusters={rankedClusters}
                          allClusters={allClusters}
                          user={user}
                          loadClusters={loadClusters}
                          favoriteJobs={favoriteJobs}
                          toggleFavoriteJob={toggleFavoriteJob}
                        />
                      </ScrollToSelf>
                    </Fade>
                  </div>
                )}
              />
              <Route
                path="/jobs"
                element={(
                  <div
                    ref={jobSorterRef}
                  >
                    <GraphContainer
                      values={values}
                      interests={interests}
                      abilities={abilities}
                      rankedClusters={rankedClusters}
                      onNext={() => {
                        navigate('/dashboard/examinee/discover/cluster/0');
                      }}
                    />
                    <ClusterTabs
                      clusters={rankedClusters}
                      allClusters={allClusters}
                      user={user}
                      loadClusters={loadClusters}
                      favoriteJobs={favoriteJobs}
                      toggleFavoriteJob={toggleFavoriteJob}
                    />
                    <Fade
                      show
                      appear
                      timeout={1000}
                    >
                      <ScrollToSelf
                        delay={100}
                      >
                        <JobSorter
                          professional={activeProduct.professional}
                          favoriteJobs={favoriteJobs}
                          toggleFavoriteJob={(job) => {
                            toggleFavoriteJob({ job, favoriteJobs });
                          }}
                          updateFavoriteJob={updateFavoriteJob}
                          finished={() => { navigate('/dashboard/examinee/succeed'); }}
                        />
                      </ScrollToSelf>
                    </Fade>
                  </div>
                )}
              />
            </Route>
          </Routes>
        </Content>
      </ExamineeMenu>
    </>
  );
};

DiscoverComponent.propTypes = {
  abilities: PropTypes.shape({
    ability: PropTypes.arrayOf(PropTypes.object),
    career: PropTypes.arrayOf(PropTypes.object),
  }),
  activeProduct: PropTypes.object,
  /** all the clusters in order of cluster ordering (1-14) */
  allClusters: PropTypes.object,
  /** The clusters ranked in order of results */
  rankedClusters: PropTypes.shape({
    ranked: PropTypes.array,
  }),
  /*
  favoriteJobs,
  interests,
  loadClusters,
  loadResults,
  loading,
  toggleFavoriteJob,
  type,
  unloadResults,
  updateFavoriteJob,
  user,
  values,
  */
};

DiscoverComponent.defaultProps = {
  abilities: {},
  activeProduct: {},
  allClusters: {},
  rankedClusters: [],
};

export default DiscoverComponent;
