import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Centered = styled.div`
  text-align:center;
`;

/** Wraps children in a div with `text-align: center` prop */
const CenteredText = ({ children }) => (
  <Centered>
    {children}
  </Centered>
);

CenteredText.propTypes = {
  /** Children */
  children: PropTypes.node.isRequired,
};

export default CenteredText;
