import { connect } from 'react-redux';
import NaAssessmentComponent from '../components/NaAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const NaAssessmentContainer = connect(
  mapStateToProps({ ipp: 4 }),
  mapDispatchToProps(),
)(NaAssessmentComponent);

export default withRouter(NaAssessmentContainer);
