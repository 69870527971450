import { connect } from 'react-redux';
import SrAssessmentComponent from '../components/SrAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const SrAssessmentContainer = connect(
  mapStateToProps({ ipp: 4 }),
  mapDispatchToProps(),
)(SrAssessmentComponent);

export default withRouter(SrAssessmentContainer);
