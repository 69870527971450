import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Colors from './Colors';
import {
  StepperContext,
} from './StepperContext';
import Step from './Step';

const StyledStepperBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${(props) => {
    if (props.$first) {
      return '1rem 1rem 1rem 0';
    }
    if (props.$last) {
      return '1rem 0 1rem 1rem';
    }
    return '1rem';
  }};
`;

export const StyledStepCircle = styled.div`
  background-color: ${(props) => {
    if (props.$active) {
      return Colors.green;
    }
    if (props.$visited) {
      return Colors.blue;
    }
    return Colors.lightGray;
  }};
  border-radius: 50%;
  padding: .5rem 1rem;
  color: ${Colors.white};
`;

const Line = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 2rem;
  border-top: 1px solid black;
`;

/**
  * Stepper Component
  *
  * This component is used to create generic steps. It must be
  * wrapped by a `<StepperProvider>` component. To access the
  * stepper context, you must use the `useContext` hook and the
  * `StepperContext`:
  *
  * ```
  * const {
  *   next,
  *   prev,
  *   state,
  *   setState,
  * } = React.useContext(StepperContext);
  * ```
  *
  * You can persist state between steps by using the `state`
  * and`setState` functions. These follow the standard React
  * `useState` hooks formatting.
  *
  * Calling the `next()` or `prev()` functions will move the stepper between steps.
  *
  * Steps must be wrapped in in a `<Step>` component. Any
  * children not enclosed in a `<Step>` component will be
  * rendered outside of the stepper. Each step must have a
  * unique `name` prop which will be used as React's `key`.
  * Additionally, you can pass a `displayName` prop to the
  * to display the name of the step in the stepper bar.
  *
  * You can also customize the StepCircle component by passing the `display`
  * property. The component will recieve the following properties:
  * - `active`: boolean
  * - `visited`: boolean
  * - `key`: string
  *
  * The StepperCircle has the keys active and visited prefixed with a $.
  *
  * Note, to prevent weird mounting issues, make sure you pass a `key` prop to the stepper.
  *
  * ```
  * <Stepper key="stepper">
  *   <Step name="Step 1">
  *     <p>Step 1</p>
  *     <Button onClick={() => next()}>Next</Button>
  *   </Step>
  *   <Step name="Middle Step" displayName>
  *     <p>Step 2</p>
  *     <Button onClick={() => prev()}>Back</Button>
  *     <Button onClick={() => next()}>Next</Button>
  *   </Step>
  *   <Step name="Step 3">
  *     <p>Step 3</p>
  *     <Button onClick={() => prev()}>Back</Button>
  *   </Step>
  * </Stepper>
  * ```
  */
const Stepper = ({
  children,
  noStepperBar,
}) => {
  const {
    currentStepIndex,
    setStepLength,
  } = useContext(StepperContext);

  const kids = React.Children.toArray(children);
  const steps = kids.filter((k) => (k.type === Step));
  const notSteps = kids.filter((k) => (k.type !== Step));

  useEffect(() => {
    setStepLength(steps.length);
  }, [ steps.length ]);

  let stepperBar = (
    <StyledStepperBar>
      {
        steps.map((step, i) => (
          <React.Fragment
            key={`styled_step_container_${step.props.name}`}
          >
            <StyledStep
              key={`styled_step_${step.props.name}`}
              $first={i === 0}
              $last={i === steps.length - 1}
            >
              {
                step.props.display
                  ? (
                    step.props.display({
                      key: `step_circle_${step.props.name}`,
                      active: currentStepIndex === i,
                      visited: currentStepIndex > i,
                    })
                  ) : (
                    <StyledStepCircle
                      key={`step_circle_${step.props.name}`}
                      $active={currentStepIndex === i ? true : undefined}
                      $visited={currentStepIndex > i}
                    >
                      {step.props.display ? step.props.display() : i + 1}
                    </StyledStepCircle>
                  )
              }
            </StyledStep>
            {
              i + 1 < steps.length
                ? (
                  <Line
                    key={`step_line_${step.props.name}`}
                  />
                )
                : ''
            }
          </React.Fragment>
        ))
      }
    </StyledStepperBar>
  );
  if (noStepperBar) {
    stepperBar = '';
  }

  return (
    <>
      {stepperBar}
      {steps[currentStepIndex]}
      {notSteps}
    </>
  );
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  noStepperBar: PropTypes.bool,
};

Stepper.defaultProps = {
  noStepperBar: false,
};

export default Stepper;
