import React from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  H1,
  Content,
  SmallButton,
  Colors,
  UpperCaseMessage,
  Strong,
  Icons,
} from '../../components';

import {
  abilitiesAssessment,
  next,
} from '../../utils/GlobalMessages';

const TestList = styled.ol`
  padding: 0px;
  color: ${Colors.green};
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const CH1 = styled(H1)`
  text-align:center;
`;

const Li = styled.li`
  color: ${(props) => (props.color || 'inherit')};
`;

const Abilities = ({ subTests, nextTest }) => {
  if (!nextTest) {
    return (<Navigate to="/" />);
  }
  const testArray = subTests.map((test) => (
    <Li
      key={test.id}
      color={test.isFinished ? Colors.blue : ''}
    >
      {test.testDefinition.testType.name}
      &nbsp;
      {test.isFinished ? <Icons.Check data-testid="finishedIcon" /> : '' }
    </Li>
  ));
  return (
    <Content>
      <CH1 color={Colors.green}>
        <UpperCaseMessage
          {...abilitiesAssessment}
        />
      </CH1>
      <p>
        <FormattedMessage
          id="abilities.directions1"
          defaultMessage="You will take a series of timed assessments that will
          help you understand your potential strengths and weaknesses. It can
          help you relate your current abilities to various occupations."
        />
      </p>
      <p>
        <FormattedMessage
          id="abilities.directions2"
          defaultMessage="For each assessment you will be given <strong>five
          minutes</strong>. Answer as many questions as you can. You are not
          expected to answer all the questions. Each assessment has
          instructions and sample problems. Make sure you understand the
          instructions before starting the test. If you need to take a break,
          take a break between assessments when the timer is not running."
          values={{
            strong: Strong,
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="abilities.directions3"
          defaultMessage="You must answer at least one item on each assessment to receive results."
        />
      </p>
      <p>
        <FormattedMessage
          id="abilities.directions4"
          defaultMessage="There are eight assessments that will help you find your strengths and weaknesses:"
        />
      </p>
      <Centered>
        <TestList>
          {testArray}
        </TestList>
      </Centered>
      <Centered>
        <SmallButton
          to={`/assessment/abilities/${nextTest.testDefinition.testType.abbreviation.toLowerCase()}/directions`}
          secondary
        >
          <UpperCaseMessage
            {...next}
          />
        </SmallButton>
      </Centered>
    </Content>
  );
};

export default Abilities;
