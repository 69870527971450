import React from 'react';
import {
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return (
      <Component
        {...props}
        // super old version of react-router-dom
        match={{
          params,
        }}
        router={{ location, params, navigate }}
      />
    );
  };

  return ComponentWithRouterProp;
};

export default withRouter;
