import { uniqBy, cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import {
  INIT_USER_SUCCESS,
  ENTER_PASSCODE_SUCCESS,
} from '../actions/userActions';

import {
  LOAD_EXAMINEE_SUCCESS,
} from '../actions/examineeActions';

import {
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_REQUEST,
  FIND_GROUPS_SUCCESS,
  FIND_GROUPS_REQUEST,
  FIND_GROUPS_ERROR,
} from '../actions/groupActions';

const groupReducer = (
  state = {
    list: [],
    dic: {},
    total: 0,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case CREATE_GROUP_SUCCESS:
    case INIT_USER_SUCCESS: {
      newState.list = action.payload.groups;
      action.payload.groups.forEach((g) => {
        newState.dic[g.id] = g;
      });
      newState.loading = false;
      return newState;
    }
    case CREATE_GROUP_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case ENTER_PASSCODE_SUCCESS: {
      newState.list.push(action.payload.group);
      newState.list = uniqBy(newState.list, 'id');
      newState.dic[action.payload.group.id] = action.payload.group;
      return newState;
    }
    case LOAD_EXAMINEE_SUCCESS: {
      action.payload.groups.forEach((g) => {
        newState.list.push(g);
        newState.dic[g.id] = g;
      });
      newState.list = uniqBy(newState.list, 'id');
      return newState;
    }
    case FIND_GROUPS_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case FIND_GROUPS_SUCCESS: {
      newState.loading = false;
      newState.total = action.payload.total;
      newState.list = action.payload.list.map((g) => ({
        ...g,
        createdAt: DateTime.fromISO(g.createdAt),
        updatedAt: DateTime.fromISO(g.updatedAt),
      }));
      newState.dic = {};
      action.payload.list.forEach((g) => {
        newState.dic[g.id] = g;
      });
      return newState;
    }
    case FIND_GROUPS_ERROR: {
      newState.loading = false;
      return newState;
    }
    default: {
      return { ...state };
    }
  }
};

export default groupReducer;
