/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { white } from './Colors';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  background-color: ${white};
  max-width: 1400px;
  justify-content: center;
  width: 100%;
  ${(props) => (props.$wrapperStyle)}
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding: 2em 8em;

  @media (max-width: 768px) {
    padding: .5em .5em;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    padding: 2em 2em;
  }
  ${(props) => (props.$contentStyle)}
`;

/**
 * A wrapper for content that centers it and sets a max width.
 */
const WrappedContent = ({
  children,
  contentStyle,
  contentProps,
  wrapperStyle,
  wrapperProps,
  id,
}) => (
  <Wrapper
    id={id}
    $wrapperStyle={wrapperStyle}
    {...wrapperProps}
  >
    <Content
      $contentStyle={contentStyle}
      {...contentProps}
    >
      {children}
    </Content>
  </Wrapper>
);

WrappedContent.propTypes = {
  /** Style object for the wrapper div */
  wrapperStyle: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
  ]),
  /** Pass through props to the wrapper div */
  wrapperProps: PropTypes.objectOf(PropTypes.any),
  /** Style object for the content div */
  contentStyle: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.string,
  ]),
  /** Pass through props to the content div */
  contentProps: PropTypes.objectOf(PropTypes.any),
  /** Optinal id for the wrapper div */
  id: PropTypes.string,
  /** Children to be rendered */
  children: PropTypes.node.isRequired,

};

WrappedContent.defaultProps = {
  contentProps: {},
  contentStyle: {},
  id: '',
  wrapperProps: {},
  wrapperStyle: {},
};

export default WrappedContent;
