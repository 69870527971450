import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  H1,
  Colors,
  UpperCaseMessage,
  ExamineeMenu,
  SmallButton as Button,
} from '../../components';

import TestButton from './StartTestButton';

import * as messageDefs from '../../utils/GlobalMessages';

const SH = styled.div`
  margin-top: 2em;
  text-align:center;
`;

const SS = styled.span`
  color: ${(props) => props.color};
`;

const Smaller = styled.span`
  font-size: .65em;
`;

const SP = styled.p`
  margin: 0em 2em 2em;
  color: ${Colors.grey};
`;

const StartH1 = styled(H1)`
  margin: 0;
`;

const CompletedContainer = styled.div`
  margin: auto;
  text-align:center;
`;

const CompletedButton = styled(Button)`
  margin: auto;
`;

const TestButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  line-height: normal;
`;

const ExploreComponent = ({
  allFinished,
  activeProduct,
  startAssessment,
}) => (
  <ExamineeMenu
    assigned={activeProduct}
  >
    <SH>
      <H1>
        <FormattedMessage
          id="explore.welcomeTo"
          defaultMessage="WELCOME TO"
        />
        &nbsp;
        <SS color={Colors.red}>V</SS><SS color={Colors.blue}>I</SS><SS color={Colors.green}>A</SS>
        <UpperCaseMessage
          {...messageDefs.system}
        />
      </H1>
    </SH>
    <SP>
      <FormattedMessage
        id="explore.mainMessage"
        defaultMessage="
          It is time to explore your career path by identifying jobs that
          interest you, that you value, and that match your strengths. Get
          started by clicking on one of the assessments below. We recommend
          starting with your interests.
        "
      />
    </SP>
    <TestButtonContainer>
      <TestButton
        color={Colors.red}
        to="/assessment/values/start"
        isFinished={activeProduct.isFinished.values}
        testStatus={activeProduct.values}
        startAssessment={startAssessment}
      >
        <StartH1 color={activeProduct.isFinished.values ? Colors.grey : Colors.red}>
          <UpperCaseMessage
            {...messageDefs.values}
          />
          <br />
          <Smaller>
            <UpperCaseMessage
              {...messageDefs.assessment}
            />
          </Smaller>
        </StartH1>
      </TestButton>
      <TestButton
        color={Colors.blue}
        to="/assessment/interests/start"
        isFinished={activeProduct.isFinished.interests}
        testStatus={activeProduct.interests}
        startAssessment={startAssessment}
      >
        <StartH1 color={activeProduct.isFinished.interests ? Colors.grey : Colors.blue}>
          <UpperCaseMessage
            {...messageDefs.interests}
          />
          <br />
          <Smaller>
            <UpperCaseMessage
              {...messageDefs.assessment}
            />
          </Smaller>
        </StartH1>
      </TestButton>
      <TestButton
        color={Colors.green}
        to="/assessment/abilities/start"
        isFinished={activeProduct.isFinished.abilities}
        testStatus={activeProduct.abilities}
        startAssessment={startAssessment}
      >
        <StartH1 color={activeProduct.isFinished.abilities ? Colors.grey : Colors.green}>
          <UpperCaseMessage
            {...messageDefs.abilities}
          />
          <br />
          <Smaller>
            <UpperCaseMessage
              {...messageDefs.assessment}
            />
          </Smaller>
        </StartH1>
      </TestButton>
    </TestButtonContainer>
    {
      allFinished && (
        <CompletedContainer>
          <H1>Completed</H1>
          <p>You have completed all the assessments</p>
          <CompletedButton to="/dashboard/examinee/discover">Next</CompletedButton>
        </CompletedContainer>
      )
    }
  </ExamineeMenu>
);

ExploreComponent.propTypes = {
  activeProduct: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
    interests: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
    abilities: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
  }),
  assignedProducts: PropTypes.arrayOf(PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
    interests: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
    abilities: PropTypes.arrayOf(PropTypes.shape({
      testDefinitionId: PropTypes.number,
      isFinished: PropTypes.bool,
    })),
  })),
};

ExploreComponent.defaultProps = {
  activeProduct: {},
  assignedProducts: [],
};

export default ExploreComponent;
