import {
  OPEN_BANNER_MENU,
  CLOSE_BANNER_MENU,
  SET_EXPAND_MENU,
  ENABLE_MANUAL_EXPLORE,
  ENABLE_MANUAL_DISCOVER,
  ENABLE_MANUAL_DASHBOARD_SELECTION,
} from '../actions/displayActions';

const BannerReducer = (
  state = {
    showBannerMenu: false,
    expandedMenu: true,
    manualExplore: false,
    manualDiscover: false,
    manualDashboardSelection: false,
  },
  action,
) => {
  switch (action.type) {
    case OPEN_BANNER_MENU: {
      return {
        ...state,
        showBannerMenu: true,
      };
    }
    case CLOSE_BANNER_MENU: {
      return {
        ...state,
        showBannerMenu: false,
      };
    }
    case SET_EXPAND_MENU: {
      return {
        ...state,
        expandedMenu: action.payload,
      };
    }
    case ENABLE_MANUAL_EXPLORE: {
      return {
        ...state,
        manualExplore: true,
      };
    }
    case ENABLE_MANUAL_DISCOVER: {
      return {
        ...state,
        manualDiscover: true,
      };
    }
    case ENABLE_MANUAL_DASHBOARD_SELECTION: {
      return {
        ...state,
        manualDashboardSelection: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default BannerReducer;
