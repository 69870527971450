import React, { useEffect } from 'react';

import {
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import stripePromise, { stripeOptions } from '../../../utils/stripe';
import {
  CenteredSpinner,
} from '../../../components';

const SetupPurchaseComplete = ({
  assignedProducts,
  purchases,
  broadcastError,
  initUser,
  user,
}) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [ errorState, setErrorState ] = React.useState(false);

  const next = () => {
    navigate('/dashboard/setup/bio');
  };

  useEffect(() => {
    if (assignedProducts.length > 0) {
      next();
    }
  }, [assignedProducts]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (
      assignedProducts.length === 0
      && purchases.length > 0
      && purchases[0].status === 'succeeded'
    ) {
      setErrorState(true);
      // navigate('/dashboard/setup/bio');
    } else {
      const clientSecret = searchParams.get(
        "payment_intent_client_secret",
      );

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (!paymentIntent) {
          return;
        }

        if (paymentIntent.status === "succeeded" && assignedProducts.length === 0) {
          initUser(user.id).then(() => {
            next();
          });
        } else if (paymentIntent.status === "succeeded") {
          next();
        } else {
          broadcastError(new Error("There was an error processing your payment"));
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        broadcastError(error);
      });
    }
  }, [stripe, purchases]);

  if (errorState) {
    return (
      <div>
        <p>There was an error processing your payment.</p>
        <p>Please contact our support team at <a href="mailto:service@viasystem.com">service@viasystem.com</a></p>
      </div>
    );
  }

  return (
    <CenteredSpinner>
      <p>Please wait.</p>
      <p>Verifying your purchase</p>
    </CenteredSpinner>
  );
};

const ElementsWrapper = (props) => (
  <Elements
    stripe={stripePromise}
    options={{
      ...stripeOptions,
      mode: 'setup',
      currency: 'usd',
    }}
  >
    <SetupPurchaseComplete
      {...props}
    />
  </Elements>
);

export default ElementsWrapper;
