import React, { useEffect, useRef } from 'react';
import { ScrollRestoration } from 'react-router-dom';

import {
  scrollTo,
} from '../../utils/scrollTo';

export const ScrollToTop = ({
  children,
}) => (
  <>
    {children}
    <ScrollRestoration />
  </>
);

export const ScrollToSelf = ({
  children,
  delay,
  scrollOpts,
  ...rest
}) => {
  const ref = useRef();
  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        scrollTo(ref, scrollOpts);
      }, delay);
    } else {
      scrollTo(ref, scrollOpts);
    }
  }, []);
  return (
    <div
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
};
