import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AssessmentComplete,
} from './AssessmentComplete';

import ValuesFinishPng from '../../images/Values-Finished.png';

const ValuesComplete = ({
  results,
  ...rest
}) => (
  <AssessmentComplete
    {...rest}
    image={ValuesFinishPng}
    results={results}
    render={() => (
      <p>
        <FormattedMessage
          id="complete.values"
          defaultMessage="Congratulations! You have completed the values
          portion of your assessment."
        />
      </p>
    )}
  />
);

export default ValuesComplete;
