import React, { useEffect } from 'react';
import {
  useNavigate,
} from 'react-router-dom';

import {
  Fade,
} from '../../../components';
import { SetupForm } from '../../../forms';

const SetupUser = ({
  user,
  updateUser,
  submittingUser,
}) => {
  const navigate = useNavigate();

  const next = () => {
    navigate('/');
  };

  useEffect(() => {
    if (user.gender && user.grade && user.dob) {
      next();
    }
  }, [user]);

  return (
    <Fade
      appear
      onExited={() => next()}
    >
      <div>
        <p>Before we get started. We need to know a little about you.</p>
        <SetupForm
          initialValues={user}
          isBusy={submittingUser}
          onSubmit={(values) => {
            updateUser({
              ...values,
              id: user.id,
            }).then(() => {
              next();
            });
          }}
        />
      </div>
    </Fade>
  );
};

export default SetupUser;
