/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link,
  Navigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { isNaN } from 'lodash';

import {
  ScreenReader,
  Colors,
  Icons,
} from '../../components';

const PageUl = styled.ul`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0px;
  margin-top: 3.25em;
`;

const PageLi = styled.li`
  flex: 1 1 auto;
  margin: .2em;
  padding: 0;
  height: 1.65rem;
  max-width: 1.65rem;
`;

const PageLink = styled(Link)`
  display: inline-block;
  align-items: center;
  display: flex;
  text-decoration: none;
  border: ${(props) => (props.$noBorder || props.state === 'currentPage' ? '' : `1px solid ${Colors.grey}`)};
  box-shadow: ${(props) => (props.$noBorder ? '' : `0px 3px 6px ${Colors.lightGrey}`)};
  background-color: ${(props) => {
    if (props.$noColor) {
      return 'transparent';
    }
    switch (props.state) {
      case 'currentPage': {
        return Colors.lightGrey;
      }
      case 'unvisited': {
        return Colors.white;
      }
      case 'missed': {
        return Colors.red;
      }
      case 'completed': {
        return Colors.green;
      }
      default: {
        return Colors.white;
      }
    }
  }};
  height: 100%;
  width: 100%;
  &:hover {
    opacity: 0.5;
  }
`;

const Arrow = `
  margin: auto;
  color: ${Colors.grey};
  &:visited {
    color: ${Colors.grey};
  }
`;
const LeftArrow = styled(Icons.LeftArrow)`${Arrow}`;
const RightArrow = styled(Icons.RightArrow)`${Arrow}`;

/**
 * Assessment NavBar
 */
const NavBar = ({
  testItems,
  ipp,
  isVr,
}) => {
  const { pathname } = useLocation();
  const { page } = useParams();
  const baseUrl = `/${pathname.split('/').slice(1, -1).join('/')}/`;
  const totalPages = Math.ceil(testItems.length / ipp);
  const pages = [];
  let currPage = page - 1;

  if (page === 'finish') {
    currPage = totalPages - 1;
    return (<Navigate to={`${baseUrl}/${currPage + 1}`} />);
  }
  if (page === 'start') {
    currPage = 0;
    return (<Navigate to={`${baseUrl}/${currPage + 1}`} />);
  }
  if (isNaN(currPage)) {
    currPage = 0;
  }

  for (let i = 0; i < totalPages; i += 1) {
    let pageItems = testItems.slice(ipp * i, ipp * i + ipp);

    if (isVr) {
      pageItems = testItems[i].testItems;
    }

    let state = '';
    const answeredItems = pageItems.reduce((acc, it) => {
      if (it.userResponse) {
        return acc + 1;
      }
      return acc;
    }, 0);
    if (answeredItems === 0) {
      state = 'unvisited';
    } else if (answeredItems < pageItems.length) {
      state = 'missed';
    } else {
      state = 'completed';
    }
    if (currPage === i) {
      state = 'currentPage';
    }

    pages.push(
      <PageLi
        key={`page_${i}`}
      >
        <PageLink
          to={`${baseUrl}${i + 1}`}
          state={state}
        >
          <ScreenReader>
            Page {i + 1}
            { currPage === i ? ' Current Page' : ''}
          </ScreenReader>
        </PageLink>
      </PageLi>,
    );
  }
  return (
    <>
      <ScreenReader><h1>Progress Bar</h1></ScreenReader>
      <ScreenReader>
        <p>
          You are currently on page {page} of {totalPages}.
        </p>
      </ScreenReader>
      <ScreenReader>
        { currPage === totalPages - 1 ? 'Finish Assessment' : 'Next Page' }
      </ScreenReader>
      <PageUl>
        <PageLi>
          <PageLink
            to={currPage === 0 ? `${baseUrl}start` : `${baseUrl}${currPage}`}
            $noColor
            $noBorder
          >
            <ScreenReader>
              { currPage === 0 ? 'Back to directions' : 'Previous Page' }
            </ScreenReader>
            <LeftArrow size="2x" />
          </PageLink>
        </PageLi>
        {pages}
        <PageLi>
          <PageLink
            to={currPage === totalPages - 1 ? `${baseUrl}finish` : `${baseUrl}${currPage + 2}`}
            $noColor
            $noBorder
          >
            <ScreenReader>
              { currPage === totalPages - 1 ? 'Finish Assessment' : 'Next Page' }
            </ScreenReader>
            <RightArrow size="2x" />
          </PageLink>
        </PageLi>
      </PageUl>
    </>
  );
};

NavBar.propTypes = {
  testItems: PropTypes.arrayOf(
    PropTypes.shape({
      userResponse: PropTypes.object,
    }),
  ).isRequired,

  /** The items per page */
  ipp: PropTypes.number.isRequired,
  isVr: PropTypes.bool,
};

NavBar.defaultProps = {
  isVr: false,
};

export default NavBar;
