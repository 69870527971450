import { cloneDeep, includes } from 'lodash';
import { DateTime } from 'luxon';

import {
  ADMINS_LIST_REQUEST,
  ADMINS_LIST_SUCCESS,
  ADMINS_LIST_ERROR,
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
  UPDATE_ADMIN_PERMISSION_REQUEST,
  UPDATE_ADMIN_PERMISSION_SUCCESS,
  ADMINS_DELETE_REQUEST,
  ADMINS_DELETE_SUCCESS,
} from '../actions/adminActions';

const adminReducer = (
  state = {
    list: [],
    total: 0,
    loading: undefined,
    bulk: [],
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case ADMINS_LIST_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case ADMINS_LIST_SUCCESS: {
      newState.list = [];
      action.payload.list.forEach((e) => {
        newState.list.push({
          ...e,
          per_users_view: e.per_users_view === 1,
          per_users_invite: e.per_users_invite === 1,
          per_users_move: e.per_users_move === 1,
          per_products_view: e.per_products_view === 1,
          per_products_transfer: e.per_products_transfer === 1,
          per_products_assign: e.per_products_assign === 1,
          per_admin_view: e.per_admin_view === 1,
          per_admin_invite: e.per_admin_invite === 1,
          per_admin_remove: e.per_admin_remove === 1,
          per_admin_edit: e.per_admin_edit === 1,
          per_create: e.per_create === 1,
          updatedAt: DateTime.fromISO(e.updatedAt),
          createdAt: DateTime.fromISO(e.createdAt),
        });
      });
      newState.loading = false;
      newState.total = action.payload.total;
      return newState;
    }
    case ADMINS_LIST_ERROR: {
      newState.list = [];
      newState.loading = false;
      return newState;
    }
    case ADD_ADMIN_REQUEST: {
      newState.loading = true;
      newState.error = undefined;
      return newState;
    }
    case ADD_ADMIN_SUCCESS: {
      newState.loading = false;
      return newState;
    }
    case ADD_ADMIN_ERROR: {
      newState.loading = false;
      newState.error = action.payload;
      return newState;
    }
    case UPDATE_ADMIN_PERMISSION_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case UPDATE_ADMIN_PERMISSION_SUCCESS: {
      newState.loading = false;
      newState.list = newState.list.map((g) => {
        if (includes(action.payload.adminGroupIds, g.adminGroupId)) {
          return {
            ...g,
            ...action.payload.permissions,
          };
        }
        return g;
      });
      return newState;
    }
    case ADMINS_DELETE_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case ADMINS_DELETE_SUCCESS: {
      newState.loading = false;
      newState.list = newState.list.filter((g) => (
        !includes(action.payload.adminGroupIds, g.adminGroupId)
      ));
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default adminReducer;
