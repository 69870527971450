import { connect } from 'react-redux';

import { updateUser } from '../../actions/userActions';
import EditProfileComponent from '../components/EditProfile';

const mapStateToProps = (state) => ({
  isBusy: state.user.updating,
  user: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => (
    dispatch(updateUser(values))
  ),
});

const EditProfile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProfileComponent);

export default EditProfile;
