import { connect } from 'react-redux';

import ValuesCompleteComponent from '../components/ValuesComplete';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './AssessmentComplete';

export default connect(
  mapStateToProps({
    type: 'values',
  }),
  mapDispatchToProps(),
)(ValuesCompleteComponent);
