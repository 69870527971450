import React from 'react';
import PropTypes from 'prop-types';

const Username = ({
  name,
  fieldName,
  formik,
  ...rest
}) => (
  <input
    type="text"
    name={name}
    {...formik.getFieldProps(fieldName)}
    {...rest}
  />
);

Username.propTypes = {
  name: PropTypes.string,
  fieldName: PropTypes.string,
  formik: PropTypes.shape({
    getFieldProps: PropTypes.func,
  }).isRequired,
};

Username.defaultProps = {
  name: 'username',
  fieldName: 'username',
};

export default Username;
