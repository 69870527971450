import { connect } from 'react-redux';

//import { broadcastFatalError } from '../../actions/errorActions';

import ProtectedAssessmentRouteComponent from '../components/ProtectedAssessmentRoute';

const mapStateToProps = (state, props) => {
  const {
    user: {
      profile: user,
      loading,
    },
    assessment: {
      chain,
    },
  } = state;

  return {
    user,
    loading,
    chain,
    ...props,
  };
};

const mapDispatchToProps = () => ({});

const ProtectedAssessmentRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProtectedAssessmentRouteComponent);

export default ProtectedAssessmentRouteContainer;
