import { connect } from 'react-redux';

import AbilitiesCompleteComponent from '../components/AbilitiesComplete';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './AssessmentComplete';

export default connect(
  mapStateToProps({
    type: 'abilities',
  }),
  mapDispatchToProps(),
)(AbilitiesCompleteComponent);
