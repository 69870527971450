/* eslint react/forbid-prop-types:0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  FinishButton,
  Content,
  KeyWrapper as KW,
} from '../../components';

import {
  ExampleButton,
} from './Inputs';

import {
  Radio as InterestsInput,
  L,
  LowL,
  LowD,
  D,
  LikeVeryMuch,
  LikeMod,
  DislikeMod,
  DislikeVeryMuch,
} from './InterestsInputs';

import Nav from './NavBar';

const InterestsWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

const ItemKeyContainer = styled.div`
  width: 290px;
  margin: auto;
  display: flex;
  align-items: center;
`;

const KeyWrapper = styled(KW)`
  max-height: 37px;

  @media (max-width: 1440px) {
    padding: 1em 0em;
    flex-direction: column;
    height: 100px;
    max-height: 100px;
  }
`;

const Key = styled.div`
  margin: 0 .5em;
  display: inline-block;
`;

const Example = ({ item, text }) => (
  <ItemKeyContainer>
    <ExampleButton>
      {item()}
    </ExampleButton>
    <Key>
      {text()}
    </Key>
  </ItemKeyContainer>
);

const InterestsAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it) => (
    <InterestsInput
      key={`interstsInput_${it.id}`}
      testItem={it}
      onChange={(params) => saveItem({ testStatus, ...params })}
    />
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        to="/assessment/interests/finish"
        key="finish"
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        <Example
          item={L}
          text={LikeVeryMuch}
        />
        <Example
          item={LowL}
          text={LikeMod}
        />
        <Example
          item={LowD}
          text={DislikeMod}
        />
        <Example
          item={D}
          text={DislikeVeryMuch}
        />
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey ]);
  return (
    <InterestsWrapper>
      <Content>
        {displayItems}
        <Nav
          testItems={testItems}
          ipp={8}
        />
      </Content>
    </InterestsWrapper>
  );
};

InterestsAssessment.propTypes = {
  testStatus: PropTypes.shape({
    assignedProductId: PropTypes.number,
    testDefinitionId: PropTypes.number,
  }).isRequired,
  saveItem: PropTypes.func.isRequired,
  /** These are the items for this page */
  items: PropTypes.array,
  /** These are all the items in the test. */
  testItems: PropTypes.array,
  /** Saves a testItem */
  isLastPage: PropTypes.bool,
  setRenderKey: PropTypes.func.isRequired,
  clearRenderKey: PropTypes.func.isRequired,
};

InterestsAssessment.defaultProps = {
  items: [],
  testItems: [],
  isLastPage: false,
};

export default InterestsAssessment;
