import { connect } from 'react-redux';

import SetupComponent from '../components/SetupUser';
import { initUser, updateUser } from '../../../actions/userActions';

const mapStateToProps = (state) => ({
  user: state.user.profile,
  submittingUser: state.user.updating,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (values) => (
    dispatch(updateUser(values))
  ),
  initUser: (id) => (
    dispatch(initUser(id))
  ),
});

const SetupUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupComponent);

export default SetupUserContainer;
