import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  SmallButton,
  H1,
  Content,
} from '../../components';

const Btn = styled(SmallButton)`
  margin: auto;
`;

const Timeout = ({ nextTest, resetTimer }) => (
  <Content>
    <H1>TIME IS UP</H1>
    <p>
      Do not worry if you did not finish the assessment. You are not
      expected to answer all the items.
    </p>
    <Btn
      to={nextTest}
      onClick={() => resetTimer()}
    >
      Next Test
    </Btn>
  </Content>
);

Timeout.propTypes = {
  /** Url to the next test */
  nextTest: PropTypes.string.isRequired,
  /** function to cleanup the timer */
  resetTimer: PropTypes.func.isRequired,
};

export default Timeout;
