export const OPEN_BANNER_MENU = 'OPEN-BANNER-MENU';
export const CLOSE_BANNER_MENU = 'CLOSE-BANNER-MENU';

export const SET_EXPAND_MENU = 'EXPAND-MENU';

export const ENABLE_MANUAL_EXPLORE = 'ENABLE_MANUAL_EXPLORE';
export const ENABLE_MANUAL_DISCOVER = 'ENABLE_MANUAL_DISCOVER';

export const ENABLE_MANUAL_DASHBOARD_SELECTION = 'ENABLE_MANUAL_DASHBOARD_SELECTION';

export const setExpandMenu = (isExpanded) => (
  (dispatch) => (
    dispatch({
      type: SET_EXPAND_MENU,
      payload: isExpanded,
    })
  )
);

export const openBannerMenu = () => (
  (dispatch) => (
    dispatch({
      type: OPEN_BANNER_MENU,
      payload: {},
    })
  )
);

export const closeBannerMenu = () => (
  (dispatch) => (
    dispatch({
      type: CLOSE_BANNER_MENU,
      payload: {},
    })
  )
);

export const clickExplore = () => (
  (dispatch) => (
    dispatch({
      type: ENABLE_MANUAL_EXPLORE,
      payload: {},
    })
  )
);

export const clickDiscover = () => (
  (dispatch) => (
    dispatch({
      type: ENABLE_MANUAL_DISCOVER,
      payload: {},
    })
  )
);

export const enableManualDashboardSelection = () => (
  (dispatch) => (
    dispatch({
      type: ENABLE_MANUAL_DASHBOARD_SELECTION,
      payload: {},
    })
  )
);
