import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ReaderSpan = styled.span`
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
`;

/** Hides children from view, but allows screen readers to read them */
const ScreenReader = ({ children, ...rest }) => (
  <ReaderSpan {...rest}>
    {children}
  </ReaderSpan>
);

ScreenReader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScreenReader;
