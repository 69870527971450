import { connect } from 'react-redux';

import { broadcastFatalError } from '../../actions/errorActions';

import AdminRouteComponent from '../components/AdminRoute';

const mapStateToProps = (state, props) => ({
  user: state.user.profile,
  adminGroups: state.adminGroup.list,
  ...props,
});

const mapDispatchToProps = (dispatch) => ({
  errorHandler: (err) => {
    dispatch(broadcastFatalError(err));
  },
});

const AdminRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminRouteComponent);

export default AdminRouteContainer;
