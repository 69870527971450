import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  ApiImage,
  ExampleAnswer,
  HR,
  ImageCache,
  ScreenReader,
  StartButton,
} from '../../components';

import {
  spatialRelations,
  correctAnswer,
  practice,
} from '../../utils/GlobalMessages';

import {
  SrItem,
} from './SrAssessment';

import SubTestInstructions from './SubTestInstructions';

const SrDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ ex1, setEx1 ] = useState();

  const [ ex2, setEx2 ] = useState();

  const [ warmCache, setWarmCache ] = useState(false);

  if (!isEmpty(assessment)) {
    const images = assessment.testItems.map((it) => (`/sr/${it.item.image}`));
    images.push('/sr/sr_ex1.jpg');
    images.push('/sr/sr_ex1a.jpg');
    images.push('/sr/sr_ex1b.jpg');
    images.push('/sr/sr_ex1c.jpg');
    images.push('/sr/sr_ex1d.jpg');
    ImageCache(images).then(() => {
      setWarmCache(true);
    });
  }

  const example1Input = {
    id: 1,
    item: {
      image: 'sr_ex1.jpg',
    },
    responses: [
      {
        ranking: 1,
        value: 1,
        text: 'sr_ex1a.jpg',
      },
      {
        ranking: 2,
        value: 2,
        text: 'sr_ex1b.jpg',
      },
      {
        ranking: 3,
        value: 3,
        text: 'sr_ex1c.jpg',
      },
      {
        ranking: 4,
        value: 4,
        text: 'sr_ex1d.jpg',
      },
    ],
  };

  const example2Input = {
    id: 2,
    item: {
      image: 'sr_ex2.jpg',
    },
    responses: [
      {
        ranking: 1,
        value: 1,
        text: 'sr_ex2a.jpg',
      },
      {
        ranking: 2,
        value: 2,
        text: 'sr_ex2b.jpg',
      },
      {
        ranking: 3,
        value: 3,
        text: 'sr_ex2c.jpg',
      },
      {
        ranking: 4,
        value: 4,
        text: 'sr_ex2d.jpg',
      },
    ],
  };

  return (
    <SubTestInstructions
      abbr="sr"
      busy={loading || !warmCache}
      startTimer={startTimer}
      tagLine={{
        id: 'sr.directions.tagline',
        defaultMessage: 'This is an assessment of spatial relations to see how well you are able to visualize in three-dimensions',
      }}
      title={spatialRelations}
    >
      <p>
        <FormattedMessage
          id="sr.directions.description"
          defaultMessage="Following are patterns which can be folded into
          figures. Choose which figure can be correctly made by folding the
          first pattern on the left. Only one of the four figures is
          correct for each pattern shown.  Select the circle corresponding to
          the best answer. If you wish to change your answer, select the new
          one."
        />
      </p>
      <ScreenReader>
        <FormattedMessage
          id="sr.directions.blind"
          defaultMessage="For blind or visually impaired users, please answer
          one question on the assessment. You can do this by starting the
          assessment and selecting one answer.  Then navigate to the end of the
          assessment and select Finish. This will trigger the accessibility
          scoring for the Spatial Relations."
        />
        <StartButton
          busy={loading || !warmCache}
          to="/assessment/abilities/sr/1"
          onClick={() => startTimer()}
          secondary
        />
      </ScreenReader>
      <p>
        <FormattedMessage
          {...practice}
        />
      </p>
      <SrItem
        testItem={example1Input}
        keyId={`ex1_${warmCache}`}
        onChange={({ response }) => setEx1(response.value)}
      />
      <ExampleAnswer
        value={ex1}
        correct={1}
      >
        <p>
          <FormattedMessage
            {...correctAnswer}
          />
          <ApiImage src="/sr/sr_ex1a.jpg" />
        </p>
      </ExampleAnswer>
      <HR />
      <SrItem
        testItem={example2Input}
        keyId={`ex2_${warmCache}`}
        onChange={({ response }) => setEx2(response.value)}
      />
      <ExampleAnswer
        value={ex2}
        correct={4}
      >
        <p>
          <FormattedMessage
            {...correctAnswer}
          />
          <ApiImage src="/sr/sr_ex2d.jpg" />
        </p>
        <p>
          <FormattedMessage
            id="sr.directions.correct2"
            defaultMessage="All of the figures are the correct shape, but only
            the last figure is shaded correctly."
          />
        </p>
      </ExampleAnswer>
    </SubTestInstructions>
  );
};

export default SrDirections;
