import React from 'react';
import { Link } from 'react-router-dom';

import { H1, CenteredText } from '../../components';

const SignedOut = () => (
  <CenteredText>
    <H1>Sign Out</H1>
    <p>Thank you for using the VIA System.</p>
    <Link to="/signin">Sign In Again</Link>
  </CenteredText>
);

export default SignedOut;
