import Directions from './DirectionsContainer';
import MrDirectionsComponent from '../components/MrDirections';

const MrDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'MR',
  component: MrDirectionsComponent,
});

export default MrDirectionsContainer;
