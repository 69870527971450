/* eslint jsx-a11y/label-has-associated-control:0 */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import {
  UsernameSchema,
  UniqueUsernameSchema,
} from '../validators/Username';
import {
  UniqueEmailSchema,
  RequiredEmailSchema,
  OptionalEmailSchema,
} from '../validators/Email';

/** The Examinee Setup form. Values help determine the norm group. */
const useEditProfile = ({
  user: {
    username,
    email,
    firstName,
    lastName,
    gender,
    grade,
    dob,
  },
  onSubmit,
}) => {
  const [ genderLabel, setGenderLabel] = useState('Gender');
  const [ noEmail, setNoEmail ] = useState(isEmpty(email));
  const formik = useFormik({
    initialValues: {
      username,
      email,
      firstName,
      lastName,
      gender,
      grade,
      dob,
    },
    validationSchema: Yup.object({
      username: UsernameSchema
        .concat(UniqueUsernameSchema(username)),
      email: noEmail ? (
        OptionalEmailSchema
      ) : (
        RequiredEmailSchema
          .concat(UniqueEmailSchema(email))
      ),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      gender: Yup.string().nullable().required('Required'),
      grade: Yup.number().nullable().required('Required'),
      dob: Yup.object().nullable().test('validDate', 'Please enter a valid date.', (value) => {
        if (!value) {
          return true;
        }
        return moment.isMoment(value);
      }),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (noEmail) {
      formik.setFieldValue('email', '', true);
    } else {
      formik.setFieldValue('email', email, true);
    }
  }, [ noEmail ]);

  return ({
    formik,
    genderLabel,
    setGenderLabel,
    noEmail,
    setNoEmail,
  });
};

export default useEditProfile;
