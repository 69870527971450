export const white = '#FFFFFF';

export const blue = '#4450A2';
export const lightBlue = '#4450A259';

export const black = '#000000';

export const red = '#F0523C';

export const green = '#29BBBF';

export const yellow = '#E5BC00';

export const grey = '#5A6870';
export const gray = grey;

export const lightGrey = '#00000029';
export const lightGray = lightGrey;

export const background = '#EEEFF0';

export const superLightGrey = background;
export const superLightGray = background;
