import { connect } from 'react-redux';
import WkAssessmentComponent from '../components/WkAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const WkAssessmentContainer = connect(
  mapStateToProps({ ipp: 4 }),
  mapDispatchToProps(),
)(WkAssessmentComponent);

export default withRouter(WkAssessmentContainer);
