import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import 'react-datepicker/dist/react-datepicker.css';

import { FilterRow } from './Filter';
import Select from '../formComponents/Select';

const Container = styled.div`
  border: none !important;
  & > .react-datepicker-wrapper {
    width: auto;
  }
`;

const FilterDate = ({
  column: {
    id,
    accessor,
    Header,
    Label,
  },
  setFilter,
}) => {
  const ident = id || accessor;
  const name = `filter_${ident}`;
  const [ rawDur, setRawDur ] = useState('');
  const [ fromDate, setFromDate ] = useState(DateTime.now());
  useEffect(() => {
    if (!fromDate || !rawDur) {
      return setFilter(undefined);
    }
    const startDate = fromDate.minus(JSON.parse(rawDur));
    return setFilter({
      from: startDate.toSQL(),
      to: fromDate.toSQL(),
    });
  }, [ rawDur, fromDate ]);
  return (
    <FilterRow>
      <label key={`label_${name}`} htmlFor={name}>{Label || Header}</label>

      <Container
        className="filterInput"
      >
        <Select
          allowEmpty
          placeholder="Duration"
          value={rawDur}
          onChange={(e) => setRawDur(e.target.value)}
        >
          <option value='{"days":1}'>1 Day</option>
          <option value='{"days":3}'>3 Days</option>
          <option value='{"weeks":1}'>1 Week</option>
          <option value='{"weeks":2}'>2 Week</option>
          <option value='{"months":1}'>1 Month</option>
          <option value='{"months":2}'>2 Months</option>
          <option value='{"months":6}'>6 Months</option>
          <option value='{"year":1}'>1 Year</option>
        </Select>
        <span>&nbsp;from&nbsp;</span>
        <DatePicker
          selected={fromDate.toJSDate()}
          onChange={(date) => setFromDate(DateTime.fromJSDate(date))}
        />
      </Container>
    </FilterRow>
  );
};

export default FilterDate;
