/* eslint react/no-danger: 0 */
import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  useParams,
} from 'react-router-dom';

import {
  H1,
  H2 as inH2,
  Button,
  MediumButton,
  ExamineeMenu,
  CountryPicker,
  FormikError,
} from '../../components';

const JobContainer = styled.div`
  margin-bottom: 1rem;
`;

const JobLink = styled(MediumButton)`
  margin-top: .25rem;
`;

const H2 = styled(inH2)`
  margin: .25rem 0 0 0;
`;

const JobSnippet = styled.p` 
  margin: 0;
  padding: .25rem 0; 
`;

const JobUl = styled.ul`
  margin: 0;
  padding: 0 1rem;
`;

const Input = styled.input`
  width: 100%;
`;
const InputSplit = styled.input`
  display: inline-block;
`;

const Form = styled.form`
  max-width: 24rem;
`;

const IndeedContainer = styled.div`
  margin: 1rem 0;
`;

const JobSearch = ({
  loading,
  loadPostings,
  indeed,
}) => {
  const {
    jobTitle: rawInTitle,
  } = useParams();

  const inTitle = decodeURIComponent(rawInTitle);

  const formik = useFormik({
    initialValues: {
      jobTitle: inTitle,
      radius: 25,
      country: 'us',
      postCode: '',
    },
    validationSchema: Yup.object({
      jobTitle: Yup
        .string()
        .required('Please enter a job title'),
      radius: Yup
        .number('Please enter a valid number')
        .required('Please select a radius'),
      country: Yup
        .string()
        .required('Please select a country'),
      postCode: Yup
        .string()
        .required('Please enter a valid zip or post code'),
    }),
    onSubmit: (values) => {
      if (!loading) {
        loadPostings(values);
      }
    },
  });

  const mappedIndeed = indeed.listings.map((x) => (
    <JobContainer>
      <H2>{x.jobtitle}</H2>
      <JobSnippet
        dangerouslySetInnerHTML={{
          __html: x.snippet,
        }}
      />
      <JobUl>
        <li>Company: {x.company}</li>
        <li>Posted By: {x.source}</li>
        <li>City: {x.city}</li>
        <li>State: {x.state}</li>
        <li>Country: {x.country}</li>
        <li>Date: {x.date}</li>
      </JobUl>
      <JobLink
        href={x.url}
        target="__blank"
        onClick={x.mousedown}
      >
        View Full Posting
      </JobLink>
    </JobContainer>
  ));

  return (
    <ExamineeMenu>
      <H1>Job Search</H1>
      <Form
        onSubmit={formik.handleSubmit}
      >
        <Input
          {...formik.getFieldProps('jobTitle')}
        />
        <FormikError
          formik={formik}
          accessor="jobTitle"
        />
        <CountryPicker
          {...formik.getFieldProps('country')}
          style={{ display: 'block', width: '100%' }}
        />
        <FormikError
          formik={formik}
          accessor="country"
        />
        <InputSplit
          placeholder="Zip Code"
          style={{ marginRight: '.25rem' }}
          {...formik.getFieldProps('postCode')}
        />
        <InputSplit
          {...formik.getFieldProps('radius')}
        />
        <FormikError
          formik={formik}
          accessor="postCode"
        />
        <FormikError
          formik={formik}
          accessor="radius"
        />

        <Button
          type="submit"
          busy={loading}
          style={{
            width: '100%',
            margin: '.25rem 0',
          }}
        >
          Search
        </Button>
      </Form>
      {
        mappedIndeed.length > 0
          ? (
            <IndeedContainer id="indeed_at">
              <a href="http://www.indeed.com/">
                Jobs
              </a>
                &nbsp;by&nbsp;
              <a
                href="http://www.indeed.com/"
                title="Job Search"
              >
                <img
                  src="http://www.indeed.com/p/jobsearch.gif"
                  style={{
                    border: 0,
                    verticalAlign: 'middle',
                  }}
                  alt="Indeed job search"
                />
              </a>
            </IndeedContainer>
          ) : ''
      }
      {mappedIndeed}
      {
        mappedIndeed.length > 0
          ? (
            <a href={indeed.meta.seeAll}>See All {indeed.meta.totalResults} Jobs</a>
          ) : ''
      }
    </ExamineeMenu>
  );
};

export default JobSearch;
