import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';

import {
  Table,
  Thead,
  Tbody as RawBody,
} from './Table';

const Tbody = styled(RawBody)`
  td:first-child {
    text-align: left;
  }
`;

/** Display a table of sum of stanines and the precentile score */
const AbilityCareerProfileTable = ({
  abilities,
  stanineSums,
}) => {
  const scores = map(stanineSums, (stanines) => (
    stanines.reduce((sum, stanine) => {
      const currentAbility = abilities.ability.find((a) => (
        a.testDefinitionId === stanine
      ));
      if (!currentAbility) {
        return sum;
      }
      return sum + currentAbility.norm;
    }, 0)
  ));
  return (
    <Table>
      <Thead>
        <tr>
          <th>Career Cluster</th>
          <th>MR</th>
          <th>SR</th>
          <th>VR</th>
          <th>NA</th>
          <th>LU</th>
          <th>WK</th>
          <th>PSA</th>
          <th>MSD</th>
          <th>Raw Score</th>
          <th>Percentile</th>
        </tr>
      </Thead>
      <Tbody>
        {Object.keys(stanineSums).map((key, i) => (
          <tr key={`careerProfile_${key}`}>
            <td>{key}</td>
            {
              abilities.ability.map((a) => (
                <td key={`careerProfile_${key}_${a.abbreviation}`}>
                  {
                    stanineSums[key].includes(a.testDefinitionId)
                      ? a.norm : ''
                  }
                </td>
              ))
            }
            <td>{scores[i]}</td>
            <td>{abilities.career[i].norm}</td>
          </tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default AbilityCareerProfileTable;
