import styled from 'styled-components';

import * as Colors from './Colors';

export const Table = styled.table`
  border: 1px solid black;
  border-radius: 0.5rem;
  border-spacing: 0;
  overflow: hidden;
  margin: auto;
`;

export const Thead = styled.thead`
  background-color: ${Colors.blue};
  color: ${Colors.white};
  th {
    padding: 0.33rem 1rem;
  }
`;

export const Tbody = styled.tbody`
  tr:nth-child(even) {
    background-color: ${Colors.superLightGray};
  }
  td {
    text-align: center;
  }
`;
