import { defineMessages } from 'react-intl';

const messageDefs = defineMessages({
  abilities: {
    id: 'globals.abilities',
    defaultMessage: 'Abilities',
  },
  abilitiesAssessment: {
    id: 'globals.abilitiesAssessment',
    defaultMessage: 'Abilities Assessment',
  },
  assessment: {
    id: 'globals.assessment',
    defaultMessage: 'Assessment',
  },
  beginAssessment: {
    id: 'globals.beginAssessment',
    defaultMessage: 'Begin Assessment',
  },
  backToAssessment: {
    id: 'globals.backToAssessment',
    defaultMessage: 'Back To Assessment',
  },
  completed: {
    id: 'globals.completed',
    defaultMessage: 'Completed',
  },
  correctAnswer: {
    id: 'globals.correctAnswer',
    defaultMessage: 'The correct answer is',
  },
  dashboard: {
    id: 'globals.dashboard',
    defaultMessage: 'Dashboard',
  },
  discover: {
    id: 'globals.discover',
    defaultMessage: 'Discover',
  },
  download: {
    id: 'globals.download',
    defaultMessage: 'Download',
  },
  editSignin: {
    id: 'globals.editSignin',
    defaultMessage: 'Edit Sign-in',
  },
  editPassword: {
    id: 'globals.editPassword',
    defaultMessage: 'Edit Password',
  },
  errorNumber: {
    id: 'globals.errorNumber',
    defaultMessage: 'Please enter a number.',
  },
  explore: {
    id: 'globals.explore',
    defaultMessage: 'Explore',
  },
  finish: {
    id: 'globals.finish',
    defaultMessage: 'Finish',
  },
  finishAll: {
    id: 'globals.finishAll',
    defaultMessage: 'You must answer all the items.',
  },
  fiveMins: {
    id: 'globals.fiveMins',
    defaultMessage: 'You will have 5 minutes to complete this section.',
  },
  instructions: {
    id: 'globals.instructions',
    defaultMessage: 'INSTRUCTIONS',
  },
  interests: {
    id: 'globals.interests',
    defaultMessage: 'Interests',
  },
  interestsAssessment: {
    id: 'globals.interestsAssessment',
    defaultMessage: 'Interests Assessment',
  },
  languageUsage: {
    id: 'globals.languageUsage',
    defaultMessage: 'Language Usage',
  },
  mechanicalReasoning: {
    id: 'globals.mechanicalReasoning',
    defaultMessage: 'Mechanical Reasoning',
  },
  msd: {
    id: 'globals.msd',
    defaultMessage: 'Manual Speed and Dexterity',
  },
  pleaseWait: {
    id: 'globals.pleaseWait',
    defaultMessage: 'Please Wait',
  },
  practice: {
    id: 'globals.practice',
    defaultMessage: 'Please practice on the following examples:',
  },
  practiceSingular: {
    id: 'globals.practiceSingular',
    defaultMessage: 'Please practice on the following example:',
  },
  psa: {
    id: 'globals.psa',
    defaultMessage: 'Perceptual Speed and Accuracy',
  },
  next: {
    id: 'globals.next',
    defaultMessage: 'Next',
  },
  no: {
    id: 'globals.no',
    defaultMessage: 'No',
  },
  numericalAbility: {
    id: 'globals.numericalAbility',
    defaultMessage: 'Numerical Ability',
  },
  signout: {
    id: 'globals.signout',
    defaultMessage: 'Sign Out',
  },
  spatialRelations: {
    id: 'globals.spatialRelations',
    defaultMessage: 'Spatial Relations',
  },
  start: {
    id: 'globals.start',
    defaultMessage: 'Start',
  },
  succeed: {
    id: 'globals.succeed',
    defaultMessage: 'Succeed',
  },
  system: {
    id: 'globals.system',
    defaultMessage: 'System',
  },
  question: {
    id: 'globals.question',
    defaultMessage: 'Question',
  },
  quick: {
    id: 'globals.quick',
    defaultMessage: 'Work as quickly and as carefully as you can.',
  },
  values: {
    id: 'globals.values',
    defaultMessage: 'Values',
  },
  valuesAssessment: {
    id: 'globals.valuesAssessment',
    defaultMessage: 'Values Assessment',
  },
  verbalReasoning: {
    id: 'globals.verbalReasoning',
    defaultMessage: 'Verbal Reasoning',
  },
  wordKnowledge: {
    id: 'globals.wordKnowledge',
    defaultMessage: 'Word Knowledge',
  },
  yes: {
    id: 'globals.yes',
    defaultMessage: 'Yes',
  },
});

export const {
  abilities,
  abilitiesAssessment,
  assessment,
  backToAssessment,
  beginAssessment,
  completed,
  correctAnswer,
  dashboard,
  discover,
  download,
  editSignin,
  editPassword,
  errorNumber,
  explore,
  finish,
  finishAll,
  fiveMins,
  instructions,
  interests,
  interestsAssessment,
  languageUsage,
  mechanicalReasoning,
  msd,
  next,
  no,
  numericalAbility,
  pleaseWait,
  practice,
  practiceSingular,
  psa,
  question,
  quick,
  signout,
  spatialRelations,
  start,
  succeed,
  system,
  values,
  valuesAssessment,
  verbalReasoning,
  wordKnowledge,
  yes,
} = messageDefs;
