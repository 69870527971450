import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  ExampleAnswer,
  ScreenReader,
  Strong,
  B,
} from '../../components';

import {
  PsaItem,
  S,
  Same,
  D,
  Different,
} from './PsaAssessment';

import {
  psa,
  practice,
} from '../../utils/GlobalMessages';

import SubTestInstructions from './SubTestInstructions';

const TranslatedScreenReader = (txt) => (<ScreenReader>{txt}</ScreenReader>);

const PsaDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ Ex1, setEx1 ] = useState();
  const [ Ex2, setEx2 ] = useState();
  const [ Ex3, setEx3 ] = useState();

  const responses = [
    {
      text: S,
      alt: Same,
      value: 1,
      ranking: 1,
    },
    {
      text: D,
      alt: Different,
      value: 2,
      ranking: 2,
    },
  ];

  return (
    <SubTestInstructions
      abbr="psa"
      busy={loading}
      startTimer={startTimer}
      tagLine={{
        id: 'psa.directions.tagline',
        defaultMessage: 'This is an assessment of visual speed and accuracy',
      }}
      title={psa}
    >
      <p>
        <FormattedMessage
          id="psa.directions.directions"
          defaultMessage="The purpose of this assessment is to see how quickly and
          accurately you can tell whether pairs of symbols and numbers are
          exactly the same or different in any way.
          <ScreenReader>
            Pairs are separated by the word <strong>Break. </strong>
          </ScreenReader>
           Each pair is either exactly the same or different in some way. When
          they are exactly the same, select <strong>S</strong> for
          <strong> Same</strong>. If they are different in any way, select
          <strong> D</strong> for <strong>Different</strong>.  If you wish to
          change your answer, select the new one."
          values={{
            ScreenReader: TranslatedScreenReader,
            strong: Strong,
          }}
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practice}
          />
        </em>
      </p>
      <PsaItem
        testItem={{
          id: 1,
          item: {
            text: '46,74||46,74',
          },
          responses,
        }}
        keyId="ex1"
        onChange={({ response: { value } }) => { setEx1(value); }}
        last={Ex1 !== undefined}
      />
      <ExampleAnswer
        value={Ex1}
        correct={1}
      >
        <p>
          <FormattedMessage
            id="sr.ex1.correct"
            defaultMessage="You should have selected <b>S</b> for same."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
      <PsaItem
        testItem={{
          id: 2,
          item: {
            text: '111.1||1111.1',
          },
          responses,
        }}
        keyId="ex2"
        onChange={({ response: { value } }) => { setEx2(value); }}
        last={Ex2 !== undefined}
      />
      <ExampleAnswer
        value={Ex2}
        correct={2}
      >
        <p>
          <FormattedMessage
            id="sr.ex2.correct"
            defaultMessage="You should have selected <b>D</b> for different."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
      <PsaItem
        testItem={{
          id: 3,
          item: {
            text: '39-110||39,110',
          },
          responses,
        }}
        keyId="ex3"
        onChange={({ response: { value } }) => { setEx3(value); }}
        last
      />
      <ExampleAnswer
        value={Ex3}
        correct={2}
      >
        <p>
          <FormattedMessage
            id="sr.ex2.correct"
            defaultMessage="You should have selected <b>D</b> for different."
            values={{ b: B }}
          />
        </p>
      </ExampleAnswer>
    </SubTestInstructions>
  );
};

export default PsaDirections;
