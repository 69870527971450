/* eslint react/no-danger: 0 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  ExampleAnswer,
  H2,
  HR,
  ScreenReader,
  Strong,
} from '../../components';

import {
  practiceSingular,
  verbalReasoning,
} from '../../utils/GlobalMessages';

import SubTestInstructions from './SubTestInstructions';

import {
  Ul,
  Li,
  VrItem,
  FactMessage,
  ConclusionMessage,
  TMessage,
  FMessage,
  UMessage,
} from './VrAssessment';

const conclusions = [
  {
    id: 1,
    item: {
      text: (
        <FormattedMessage
          id="vr.directions.ex1.text"
          defaultMessage="Kim is Sue's relative."
        />
      ),
      correct: (
        <FormattedMessage
          id="vr.directions.ex1.correct"
          defaultMessage="The facts state that everyone on the farm is related to Sue. Kim lives on the farm. Therefore, Kim is related to Sue. The correct response is <strong>T</strong>, for <strong>True</strong>."
          values={{
            strong: (chunks) => (<strong>{chunks}</strong>),
          }}
        />
      ),
      correctValue: 1,
    },
  },
  {
    id: 2,
    item: {
      text: (
        <FormattedMessage
          id="vr.directions.ex2.text"
          defaultMessage="Kim is Sue's daughter."
        />
      ),
      correct: (
        <FormattedMessage
          id="vr.directions.ex2.correct"
          defaultMessage="The facts also state that Sue has only one child, a boy. Therefore, Kim cannot be Sue's daughter, because she is a girl and Sue’s only child is a boy. The correct response is <strong>F</strong>, for <strong>False</strong>."
          values={{
            strong: (chunks) => (<strong>{chunks}</strong>),
          }}
        />
      ),
      correctValue: 2,
    },
  },
  {
    id: 3,
    item: {
      text: (
        <FormattedMessage
          id="vr.directions.ex3.text"
          defaultMessage="Sue lives on the farm."
        />
      ),
      correct: (
        <FormattedMessage
          id="vr.directions.ex3.correct"
          defaultMessage="The set of facts you have been given do not make it clear whether Sue lives on the farm. The correct response is <strong>U</strong>, for <strong>Uncertain</strong>."
          values={{
            strong: (chunks) => (<strong>{chunks}</strong>),
          }}
        />
      ),
      correctValue: 3,
    },
  },
  {
    id: 4,
    item: {
      text: (
        <FormattedMessage
          id="vr.directions.ex4.text"
          defaultMessage="Sue has a son."
        />
      ),
      correct: (
        <FormattedMessage
          id="vr.directions.ex4.correct"
          defaultMessage="The facts state that Sue's only child is a boy. The correct response is <strong>T</strong>, for <strong>True</strong>."
          values={{
            strong: (chunks) => (<strong>{chunks}</strong>),
          }}
        />
      ),
      correctValue: 1,
    },
  },
  {
    id: 5,
    item: {
      text: (
        <FormattedMessage
          id="vr.directions.ex5.text"
          defaultMessage="Jack lives on the farm."
        />
      ),
      correct: (
        <FormattedMessage
          id="vr.directions.ex5.correct"
          defaultMessage="The set of facts you have been given do not make it clear whether Jack lives on the farm. The correct response is <strong>U</strong>, for <strong>Uncertain</strong>."
          values={{
            strong: (chunks) => (<strong>{chunks}</strong>),
          }}
        />
      ),
      correctValue: 3,
    },
  },
].map((con) => ({
  ...con,
  responses: [
    {
      id: 1,
      value: 1,
      text: <TMessage />,
    },
    {
      id: 2,
      value: 2,
      text: <FMessage />,
    },
    {
      id: 3,
      value: 3,
      text: <UMessage />,
    },
  ],
}));

const ExampleContainer = styled.div`
  margin: 0em 15%;
`;

const ExampleP = styled.p`
  margin-left: 0;
  margin-right: 0;
`;

const VrDirections = ({
  loading,
  assessment,
  status,
  loadTest,
  startTimer,
}) => {
  useEffect(() => {
    if (isEmpty(assessment) && !loading) {
      loadTest(status);
    }
  });

  const [ correct, setCorrect ] = useState([undefined, undefined, undefined, undefined, undefined]);

  const cons = conclusions.map((inpt) => (
    <React.Fragment key={`inputs_${inpt.id}`}>
      <VrItem
        item={inpt}
        onChange={({ testItem, response }) => {
          const newCorrect = cloneDeep(correct);
          newCorrect[testItem.id - 1] = response.value;
          setCorrect(newCorrect);
        }}
      />
      <ExampleAnswer
        value={correct[inpt.id - 1]}
        correct={inpt.item.correctValue}
      >
        <ExampleP>
          {inpt.item.correct}
        </ExampleP>
      </ExampleAnswer>
    </React.Fragment>
  ));

  return (
    <SubTestInstructions
      abbr="vr"
      busy={loading}
      startTimer={startTimer}
      tagLine={{
        id: 'vr.directions.tagline',
        defaultMessage: 'This is an assessment of verbal reasoning',
      }}
      title={verbalReasoning}
    >
      <p>
        <FormattedMessage
          id="vr.directions.description"
          defaultMessage="Following are sets of facts and conclusions. In
          many cases, from reading the facts, you can determine which
          conclusions are true and which are false. If the conclusion is
          <strong>True</strong>, select <strong> T</strong>. If the conclusion
          is <strong>False</strong>, select <strong> F</strong>. If the facts
          don't provide enough information to determine if a conclusion is
          true or false, select <strong>U </strong> for
          <strong>Uncertain</strong>. Select the best answer. If you wish to
          change your answer, select the new one."
          values={{ strong: Strong }}
        />
      </p>
      <p>
        <em>
          <FormattedMessage
            {...practiceSingular}
          />
        </em>
      </p>
      <ExampleContainer>
        <div id="ex1_label">
          <ScreenReader>Example 1</ScreenReader>
          <H2>
            <FactMessage />
          </H2>
          <Ul id="example1_facts">
            <Li>
              <FormattedMessage
                id="vr.ex.fact1"
                defaultMessage="Everyone on the farm is related to Sue."
              />
            </Li>
            <Li>
              <FormattedMessage
                id="vr.ex.fact2"
                defaultMessage="Sue's only child is a boy."
              />
            </Li>
            <Li>
              <FormattedMessage
                id="vr.ex.fact3"
                defaultMessage="Kim lives on the farm."
              />
            </Li>
            <Li>
              <FormattedMessage
                id="vr.ex.fact4"
                defaultMessage="Jack is Sue's husband."
              />
            </Li>
          </Ul>
        </div>
        <HR />
        <H2>
          <ConclusionMessage />
        </H2>
        {cons}
      </ExampleContainer>
    </SubTestInstructions>
  );
};

export default VrDirections;
