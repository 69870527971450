import {
  cloneDeep,
} from 'lodash';

import {
  LOAD_CLUSTER_REQUEST,
  LOAD_CLUSTER_SUCCESS,
  LOAD_CLUSTER_FAILURE,
} from '../actions/clusterActions';

const clusterReducer = (
  state = {
    loading: false,
    clusters: {},
    subClusters: {},
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case LOAD_CLUSTER_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case LOAD_CLUSTER_SUCCESS: {
      newState.loading = false;
      action.payload.data.forEach((clust) => {
        newState.clusters[clust.id] = {
          ...clust,
        };
        if (clust.subClusters && clust.subClusters.length > 0) {
          newState.clusters[clust.id].subClusters = clust.subClusters.map((s) => {
            newState.subClusters[s.id] = {
              id: s.id,
              clusterId: clust.id,
              subClusterId: s.id,
              name: s.name,
              description: s.description,
            };
            return {
              id: s.id,
              name: s.name,
              description: s.description,
            };
          });
        } else {
          delete newState.clusters[clust.id].jobs;
        }
      });
      return newState;
    }
    case LOAD_CLUSTER_FAILURE: {
      newState.loading = false;
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default clusterReducer;
