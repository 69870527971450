import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AssessmentComplete,
} from './AssessmentComplete';

import InterestsCompletePng from '../../images/Interests-Finished.png';

const InterestsComplete = ({
  ...rest
}) => (
  <AssessmentComplete
    {...rest}
    image={InterestsCompletePng}
    render={() => (
      <p>
        <FormattedMessage
          id="complete.interests"
          defaultMessage="Congratulations! You have completed the interests
          portion of your assessment."
        />
      </p>
    )}
  />
);

export default InterestsComplete;
