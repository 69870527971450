/* eslint react/forbid-prop-types:0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  Link,
} from 'react-router-dom';

import {
  Content,
  H1,
  MediumButton,
  SmallButton,
  CenteredSpinner,
  KeyWrapper as KW,
} from '../../components';

import UpperCaseMessage from '../../components/components/UpperCaseMessage';
import Timer from '../../components/containers/Timer';

import * as globalMessages from '../../utils/GlobalMessages';

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  flex-direction: column;
`;

const P = styled.p`
  margin: 1em;
`;

const Btn = styled(SmallButton)`
  margin-bottom: 1em;
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

const CH1 = styled(H1)`
  text-align: center;
`;

const FinishComponent = ({
  testStatus,
  user,
  allMarked,
  requireAllItems,
  timeLeft,
  backUrl,
  finish,
  loading,
  nextTest,
  name,
  setRenderKey,
  clearRenderKey,
  color,
  storyMode,
}) => {
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          (storyMode || !timeLeft)
            ? ''
            : <Timer />
        }
        <CH1 color={color}>{name}</CH1>
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [ setRenderKey, clearRenderKey, color, name, storyMode, timeLeft ]);

  if (loading) {
    return (
      <Content>
        <H1>
          <FormattedMessage
            {...globalMessages.pleaseWait}
          />
        </H1>
        <CenteredSpinner />
      </Content>
    );
  }

  if (requireAllItems && !allMarked) {
    return (
      <Content>
        <CenteredContent>
          <p>
            <FormattedMessage
              {...globalMessages.finishAll}
            />
          </p>
          <MediumButton
            to={backUrl}
          >
            <UpperCaseMessage
              {...globalMessages.backToAssessment}
            />
          </MediumButton>
        </CenteredContent>
      </Content>
    );
  }
  return (
    <Content>
      <CenteredContent>
        <P>
          <FormattedMessage
            id="finish.confirm"
            defaultMessage="Are you sure you want to finish your assessment?"
          />
        </P>
        {
          timeLeft
            ? (
              <p>
                <FormattedMessage
                  id="finish.timeLeft"
                  defaultMessage="You have time remaining."
                />
              </p>
            )
            : ''
        }
        <Btn
          onClick={() => finish(testStatus, user, nextTest)}
        >
          <UpperCaseMessage
            {...globalMessages.finish}
          />
        </Btn>
        <Link
          to={backUrl}
        >
          <FormattedMessage
            id="finish.notDone"
            defaultMessage="I am not done yet."
          />
        </Link>
      </CenteredContent>
    </Content>
  );
};

FinishComponent.propTypes = {
  /** Test Status Object */
  testStatus: PropTypes.object.isRequired,
  /** User Object */
  user: PropTypes.object.isRequired,
  /** Pass in true if all items are answered */
  allMarked: PropTypes.bool,
  /** If true will require allMarked to be true to finish the assessment */
  requireAllItems: PropTypes.bool,
  /** If true, will display a warning that time is remaining */
  timeLeft: PropTypes.bool,
  /** The url to return you back to the assessment */
  backUrl: PropTypes.string.isRequired,
  /** A function to finish the assement. Must redirect the user.
   * `finish(testStatus, user, nextTest)`
   */
  finish: PropTypes.func.isRequired,
  /** If true, will display a busy message */
  loading: PropTypes.bool,
  /** The url to the next assessment */
  nextTest: PropTypes.string,
  /** Name of test to render */
  name: PropTypes.string.isRequired,
  /** Color of the name */
  color: PropTypes.string,
  /** Function to render in the key area */
  setRenderKey: PropTypes.func.isRequired,
  /** Clears the key area */
  clearRenderKey: PropTypes.func.isRequired,
  /** Disables the timer for storybook */
  storyMode: PropTypes.bool,
};

FinishComponent.defaultProps = {
  allMarked: false,
  requireAllItems: false,
  timeLeft: undefined,
  loading: false,
  nextTest: undefined,
  color: undefined,
  storyMode: false,
};

export default FinishComponent;
