import { connect } from 'react-redux';
import Wrapper from '../components/Wrapper';

import {
  openBannerMenu,
  closeBannerMenu,
} from '../../actions/displayActions';

const mapStateToProps = (state, props) => ({
  user: state.user.profile,
  fatalError: state.error.fatalError,
  renderKey: state.assessment.renderKey,
  showBannerMenu: state.display.showBannerMenu,
  ...props,
});

const mapDispatchToProps = (dispatch) => ({
  openBannerMenu: () => {
    dispatch(openBannerMenu());
  },
  closeBannerMenu: () => {
    dispatch(closeBannerMenu());
  },
});

const WrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wrapper);

export default WrapperContainer;
