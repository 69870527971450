import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { H1 } from './Headers';
import * as Colors from './Colors';

const clusterSize = 375;

const ClusterButtonStyle = styled(Link)`
  background-color: ${Colors.white};
  min-width: ${clusterSize}px;
  max-width: ${clusterSize}px;
  width: ${clusterSize}px;
  height: 140px;
  flex: 1 1 auto;
  display: flex;
  text-align:center;
  justify-content:center;
  flex-direction:column;
  border-radius: 15px;

  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color} !important;

  text-decoration: none;
  margin: 1rem 0rem;
`;

export const ClusterContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem;
`;

const ClusterButton = ({
  index,
  name,
  color,
  onClick,
}) => (
  <ClusterButtonStyle
    onClick={() => onClick()}
    color={color}
    to={`/dashboard/examinee/discover/cluster/${index}`}
  >
    <H1 color={color}>{`${index + 1}. ${name}`}</H1>
  </ClusterButtonStyle>
);

ClusterButton.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

ClusterButton.defaultProps = {
  onClick: () => {},
};

export default ClusterButton;
