import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';

//import userManager from '../../utils/userManager';
import { CenteredSpinner } from '../../components';

const CallBack = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.error) {
    return (
      <>
        <h1>Error</h1>
        <p>There was an error signing in.</p>
        <p>{auth.error}</p>
      </>
    );
  }

  React.useEffect(() => {
    if (!auth.isLoading) {
      let path = '/';
      if (auth.user && auth.user.state && auth.user.state.postPath) {
        path = auth.user.state.postPath;
      }
      navigate(path, { replace: true });
    }
  }, [auth]);

  return (
    <CenteredSpinner>
      <p>Your account is loading.</p>
      <p>Please wait.</p>
    </CenteredSpinner>
  );
};

export default withAuthenticationRequired(CallBack, {
  onRedirecting: () => (
    <>
      <CenteredSpinner />
      <p>Please while we direct you to the login page.</p>
    </>
  ),
});
