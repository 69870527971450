import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { H1Template } from './Headers';
import * as Colors from './Colors';
import Select from '../formComponents/Select';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const H3 = styled.h3`${H1Template}`;

const SelectAssignedProduct = ({
  assignedProducts,
  groupsDic,
  setSelectedProduct,
  selectedGroup,
  setSelectedGroup,
}) => (
  <>
    <p
      style={{
        margin: '0',
      }}
    >
      To view your examinees results, please select the examinees’ assessment
      you want to view by selecting the group and the date completed. You can
      change the norm group for a selected examinee by changing the gender or
      grade level for the examinee. If these fields are blank, you will need
      to enter them to obtain results.
    </p>
    <Row
      style={{
        justifyContent: 'space-between',
      }}
    >
      <H3>SHOW RESULTS FROM</H3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: '1 1 auto',
            margin: '0 1rem',
          }}
        >
          <Select
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 1 auto',
              color: Colors.grey,
            }}
            onChange={(e) => {
              setSelectedGroup(groupsDic[e.target.value]);
              setSelectedProduct(
                // eslint-disable-next-line
                assignedProducts.filter((product) => product.groupId == e.target.value)[0],
              );
            }}
          >
            {
              assignedProducts
                .reduce((acc, product) => {
                  const id = parseInt(product.groupId, 10);
                  if (!acc.includes(id)) {
                    acc.push(id);
                  }
                  return acc;
                }, [])
                .map((id) => (
                  <option
                    key={`results_option_${id}`}
                    value={id}
                  >
                    {groupsDic[id]?.name}
                  </option>
                ))
            }
          </Select>
        </div>
        <Select
          style={{
            color: Colors.grey,
          }}
          onChange={(e) => {
            setSelectedProduct(assignedProducts[e.target.value]);
          }}
        >
          {
            assignedProducts
              .filter((product) => product.groupId === selectedGroup?.id)
              .map((product, i) => (
                <option
                  key={`results_option_${product.id}`}
                  value={i}
                >
                  {product.updatedAt?.toLocaleString(DateTime.DATE_SHORT)}
                </option>
              ))
          }
        </Select>
      </div>
    </Row>
  </>
);

export default SelectAssignedProduct;
