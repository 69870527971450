import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const FIND_PRODUCTS_REQUEST = 'FPR';
export const FIND_PRODUCTS_SUCCESS = 'FPS';
export const FIND_PRODUCTS_ERROR = 'FPE';

export const findProducts = (queryParams) => (
  (dispatch) => {
    sendIt(dispatch, FIND_PRODUCTS_REQUEST);
    return http.post('/products/list', queryParams).then(({ data }) => {
      sendIt(dispatch, FIND_PRODUCTS_SUCCESS, data);
      return data;
    }).catch((e) => {
      /* eslint-disable-next-line */
      console.error(e);
      sendIt(dispatch, FIND_PRODUCTS_ERROR, e);
    });
  }
);
