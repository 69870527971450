import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

import {
  SmallButton,
  MediumButton,
} from '../../components';

const copyToClip = () => {
  const clip = document.getElementById('clipBoard');
  clip.select();
  document.execCommand('copy');
};

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const CopyContainer = styled.div`
  flex-direction: row;
`;

const ShareArea = ({
  assignedProductId,
  settingPublic,
  isPublic,
  setPublic,
}) => {
  const shareUrl = `${window.location.origin}/results/${assignedProductId}`;

  const shareArea = (
    <>
      <CopyContainer>
        <input
          id="clipBoard"
          value={shareUrl}
          readOnly
          style={{
            width: '335px',
          }}
        />
        <SmallButton
          style={{
            display: 'inline-block',
            marginLeft: '.5rem',
          }}
          onClick={() => copyToClip()}
        >
          COPY
        </SmallButton>
      </CopyContainer>
      <div
        style={{
          display: 'inline-block',
          margin: 'auto',
        }}
      >
        <TwitterShareButton
          url={shareUrl}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <FacebookShareButton
          url={shareUrl}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <EmailShareButton
          url={shareUrl}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </>
  );
  return (
    <Container>
      { isPublic ? shareArea : '' }
      <MediumButton
        link={isPublic}
        busy={settingPublic}
        onClick={() => {
          const nextPublic = !isPublic;
          setPublic(assignedProductId, nextPublic);
        }}
      >
        {isPublic ? 'Make your results private' : 'Share your results'}
      </MediumButton>
    </Container>
  );
};

ShareArea.propTypes = {
  assignedProductId: PropTypes.number.isRequired,
  settingPublic: PropTypes.bool,
  isPublic: PropTypes.bool,
  setPublic: PropTypes.func.isRequired,
};

ShareArea.defaultProps = {
  isPublic: undefined,
  settingPublic: false,
};

export default ShareArea;
