/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  FormikError,
  InputDOB,
  InputGender as InptGender,
  InputGrade as InptGrade,
} from '../../components';

const Label = styled.label`
  display: block;
  width: 100%;
`;

const InputGender = styled(InptGender)`
  display: block;
  width: 100%;
`;

const InputGrade = styled(InptGrade)`
  display: block;
  width: 100%;
`;

/** The Examinee Setup form. Values help determine the norm group. */
const Setup = ({
  className,
  onSubmit,
  initialValues,
  isBusy,
}) => {
  const [ genderLabel, setGenderLabel] = useState('Gender');
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      gender: Yup.string().nullable().required('Required'),
      grade: Yup.number().nullable().required('Required'),
      dob: Yup.object().nullable().test('validDate', 'Please enter a valid date.', (value) => (
        moment.isMoment(value)
      )),
    }),
    onSubmit: (values) => {
      if (!isBusy) { onSubmit(values); }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className={classNames('pure-form', 'pure-form-stacked', className)}>
      <Label htmlFor="gender">{genderLabel}</Label>
      <InputGender
        data-testid="select-gender"
        className="pure-input-1"
        name="gender"
        allowEmpty={!formik.values.gender}
        allowOther
        value={formik.values.gender}
        onChange={(e) => {
          if (e.target.value === 'O') {
            setGenderLabel('Norm Group');
          }
          formik.handleChange(e);
        }}
      />
      <FormikError formik={formik} accessor="gender" />
      {
        formik.values.gender === 'O'
          && (
          <>
            <p>
              Gender influences which types of careers interest people. We try
              to remove this influence and really get to know you, not your
              gender. In order to do this, we create a &quot;norm group&quot;.
              This a grouping that influences the career choices people make.
              We want to give you the most helpful information.
            </p>
            <label htmlFor="additionalGender">
              <p>
                Knowing that, which gender group do you most closely identify with?
              </p>
            </label>
            <InputGender
              data-testid="select-gender2"
              name="additionalGender"
              value={formik.values.gender}
              allowEmpty
              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
            />
          </>
          )
      }
      <Label htmlFor="grade">Grade</Label>
      <InputGrade
        data-testid="select-grade"
        className="pure-input-1"
        name="grade"
        allowEmpty={!formik.values.grade}
        {...formik.getFieldProps('grade')}
      />
      <FormikError formik={formik} accessor="grade" />
      <Label htmlFor="dob">Date of Birth</Label>
      <InputDOB
        name="dob"
        value={formik.values.dob}
        onChange={(e) => formik.setFieldValue('dob', e)}
      />
      <FormikError formik={formik} accessor="dob" />
      <Button type="submit" full busy={isBusy}>Submit</Button>
    </form>
  );
};

Setup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  initialValues: PropTypes.shape({
    gender: PropTypes.string,
    grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dob: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
  }),
  isBusy: PropTypes.bool,
};

Setup.defaultProps = {
  initialValues: {
    gender: '',
    grade: '',
    dob: '',
  },
  isBusy: false,
  className: '',
};

export default Setup;
