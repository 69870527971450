import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Colors,
  H1 as RawH1,
  XButton,
  CenteredSpinner as Spinner,
} from '../../components';

const Container = styled.div`
  margin: 1.5rem 0rem;
  padding: 2rem;
  background-color: ${Colors.white};
  border-radius: 0.7rem;
  border: 1px solid ${Colors.grey};
  box-shadow: 0px 3px 6px ${Colors.lightGrey};
  @media(max-width: 786px) {
    padding: 1rem;
  }
  position:relative;
`;

const H1 = styled(RawH1)`
  display: flex;
  align-items: center;
`;

const H2 = styled.h2`
  font-size: 1rem;
  padding: 0px;
  margin-bottom: 0px;
`;

const P = styled.p`
  margin: 0px;
  padding: 0px;
`;

const ClusterTitle = styled.span`
  color: ${(props) => (props.color)};
`;

const Circle = styled.span`
  font-family: Heebo, sans-serif;
  font-weight: 600;

  border-radius: 50%;
  color: ${Colors.white};
  background-color: ${(props) => (props.color)};

  font-size: 20px;

  padding: 0 10px;
  margin-right: .5rem
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-top: 1rem;
`;

const X = styled(XButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size:1.25rem;
  &:hover ~ .tooltip {
    display: block;
  }
`;

const ToolTip = styled.div`
  display:none;
  position: absolute;
  right: 0;
  top: 3rem;
  border: 1px solid ${Colors.grey};
  padding: .5rem;
  background-color: ${Colors.white};
  border-radius: 0.7rem;
`;

const JobTile = ({
  index,
  jobTitle,
  clusters,
  description,
  courses,
  id,
  removeJob,
}) => {
  const cluster = clusters.filter((c) => (c.type === 'cluster'))[0];
  const courseElms = courses.map((c) => {
    try {
      return (
        c.name.split(' ').map((n) => `${n[0].toUpperCase()}${n.substring(1)}`).join(' ')
      );
    } catch (e) {
      // eslint-disable-next-line
      console.log(`Course Formatted incorrectly: ${c}`);
      return '';
    }
  }).join(', ');

  if (!cluster) {
    return (
      <Container>
        <Spinner />
        <p>Please wait.</p>
      </Container>
    );
  }

  return (
    <Container>
      {
        removeJob && (
          <X screenReader="Remove Job" onClick={() => removeJob()} />
        )
      }
      <ToolTip className="tooltip">
        Remove
      </ToolTip>
      <H1 color={cluster.color}>
        <Circle color={cluster.color}>{index + 1}</Circle>
        {jobTitle.toUpperCase()}
      </H1>
      <H2>
        CAREER CLUSTER:&nbsp;
        <ClusterTitle color={cluster.color}>{cluster.name.toUpperCase()}</ClusterTitle>
      </H2>
      <P>
        {cluster.description}
      </P>
      <H2>JOB DESCRIPTION:</H2>
      <P>{description}</P>
      <H2>COURSES:</H2>
      <P>{courseElms}</P>
      <ButtonContainer>
        <Link
          to={`/job/${id}`}
          style={{ marginLeft: 'auto' }}
        >
          View detailed job information
        </Link>
      </ButtonContainer>
    </Container>
  );
};

JobTile.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number,
  jobTitle: PropTypes.string,
  clusters: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  })),
  description: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  removeJob: PropTypes.func.isRequired,
};

JobTile.defaultProps = {
  index: 0,
  jobTitle: 'Loading',
  clusters: [],
  description: 'Please wait',
  courses: [],
};

export default JobTile;
