import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  H1 as RawH1,
  SmallButton as Btn,
  CareerWheel,
} from '../../components';

const TwoColumn = styled.div`
  display: flex;
  flex-direction:row;
  flex: 1 1 auto;
  align-items: center;
  @media(max-width: 1024px) {
    flex-direction:column;
  }
`;

const Col = styled.div`
  flex: 1 2 auto;
`;

const Button = styled(Btn)`
  margin: 1rem auto;
`;

const P = styled.p`
  margin: 1rem 1rem 1rem 1rem;
`;

const H1 = styled(RawH1)`
  margin: 1rem;
`;

const DiscoverCareerWheel = ({
  type,
  onClick,
}) => (
  <div>
    <H1>Discover Your Career Profile</H1>
    <TwoColumn>
      <Col>
        <P>
          It is time to discover careers based on your Values, Interests, and
          Abilities.
        </P>
        <P>
          We group careers into different categories called Career Clusters. There
          are 8 major categories, and 6 subcategories for a total of 14 Career
          Clusters. You can see the groupings with Career Wheel image.
        </P>
        <P>
          We will show you how your scores line up with these 14 career clusters,
          and your top 3 clusters. We will then have you pick 5 jobs from these
          clusters, then sort these jobs into your favorites to the least
          favorites.
        </P>
        <P>
          After that, you will move onto the Succeed phase, where you will be able
          to pick classes and training programs, or see job postings for your top 3
          favorite jobs.
        </P>
        <Button
          onClick={() => onClick()}
        >
          Next
        </Button>
      </Col>
      <Col>
        <CareerWheel
          type={type}
          height={400}
        />
      </Col>

    </TwoColumn>
  </div>
);

DiscoverCareerWheel.propTypes = {
  /** The type of career wheel. */
  type: PropTypes.oneOf([ 'cluster', 'professional', 'spanish' ]),
  /** The callback function to move to the next component */
  onClick: PropTypes.func.isRequired,
};

DiscoverCareerWheel.defaultProps = {
  type: 'cluster',
};

export default DiscoverCareerWheel;
