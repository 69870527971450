import {
  TIMER_START,
  TIMER_STOP,
  TIMER_TIMEOUT,
  TIMER_TICK,
} from '../actions/timerActions';

import {
  LOAD_ASSESSMENT_SUCCESS,
  FINISH_REQUEST,
} from '../actions/assessmentActions';

const timerReducer = (
  state = {
    active: false,
    time: undefined,
    type: undefined,
    timeout: undefined,
  },
  action,
) => {
  switch (action.type) {
    case LOAD_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        active: false,
        timeout: undefined,
        time: action.payload.testStatus.timeLeft,
        testDefinitionId: action.payload.id,
      };
    }
    case FINISH_REQUEST: {
      return {
        ...state,
        active: false,
        timeout: false,
        time: undefined,
        type: undefined,
      };
    }
    case TIMER_START: {
      return {
        ...state,
        active: true,
        timeout: false,
      };
    }
    case TIMER_TICK: {
      return {
        ...state,
        active: true,
        timeout: false,
        time: action.payload.time,
      };
    }
    case TIMER_STOP: {
      return {
        ...state,
        active: false,
        timeout: false,
        time: undefined,
        type: undefined,
      };
    }
    case TIMER_TIMEOUT: {
      return {
        ...state,
        active: false,
        timeout: true,
        time: undefined,
        type: undefined,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default timerReducer;
