import { connect } from 'react-redux';

import InterestsCompleteComponent from '../components/InterestsComplete';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './AssessmentComplete';

export default connect(
  mapStateToProps({
    type: 'interests',
  }),
  mapDispatchToProps(),
)(InterestsCompleteComponent);
