import {
  remove,
} from 'lodash';

import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const LOAD_FAVORITE_JOBS_REQUEST = 'LOAD_FAVORITE_JOBS_REQUEST';
export const LOAD_FAVORITE_JOBS_SUCCESS = 'LOAD_FAVORITE_JOBS_SUCCESS';

export const LOAD_JOB_SUCCESS = 'LOAD_JOB_SUCCESS';

export const LOAD_JOB_POSTINGS_REQUEST = 'LOAD_JOB_POSTINGS_REQUEST';
export const LOAD_JOB_POSTINGS_SUCCESS = 'LOAD_JOB_POSTINGS_SUCCESS';
export const LOAD_JOB_POSTINGS_ERROR = 'LOAD_JOB_POSTINGS_ERROR';

export const TOGGLE_FAVORITE_JOB = 'TOGGLE_FAVORITE_JOB';

export const UPDATE_FAVORITE_JOBS = 'UPDATE_FAVORITE_JOBS';

export const loadAllFavoriteJobs = (userId) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_FAVORITE_JOBS_REQUEST);
    return http.get('/favoriteJobs', {
      userId,
    }).then(({ data }) => {
      sendIt(dispatch, LOAD_FAVORITE_JOBS_SUCCESS, data);
    });
  }
);

export const loadJob = ({ id }) => (
  (dispatch) => (
    http.get(
      '/job',
      {
        params: {
          id,
        },
      },
    ).then(({ data }) => {
      sendIt(dispatch, LOAD_JOB_SUCCESS, data);
      return data;
    })
  )
);

export const loadPostings = (params) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_JOB_POSTINGS_REQUEST);
    return http.get('/job/postings', { params }).then(({ data }) => {
      sendIt(dispatch, LOAD_JOB_POSTINGS_SUCCESS, data);
      return data;
    }).catch((e) => {
      sendIt(dispatch, LOAD_JOB_POSTINGS_ERROR, e);
    });
  }
);

export const toggleFavoriteJob = ({ job, favoriteJobs }) => (
  (dispatch) => {
    sendIt(dispatch, TOGGLE_FAVORITE_JOB, { job });
    const removedJob = remove(favoriteJobs, (j) => (j.id === job.id));
    if (removedJob.length === 0) {
      favoriteJobs.push(job);
    }
    return http.post(
      '/favoriteJobs',
      favoriteJobs.map((j, i) => ({ jobId: j.id, ranking: i })),
    );
  }
);

export const updateFavoriteJob = ({ favoriteJobs }) => (
  (dispatch) => {
    sendIt(dispatch, UPDATE_FAVORITE_JOBS, { favoriteJobs });
    return http.post(
      '/favoriteJobs',
      favoriteJobs.map((j, i) => ({ jobId: j.id, ranking: i })),
    );
  }
);
