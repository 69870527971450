import { connect } from 'react-redux';
import JobSearchComponent from '../components/JobSearch';

import {
  loadPostings,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    jobPostings: {
      loading,
      listings: {
        self: listings,
        partners: {
          indeed,
        },
      },
    },
  } = state;
  return {
    loading,
    listings,
    indeed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPostings: (params) => {
    dispatch(loadPostings(params));
  },
});

const JobSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobSearchComponent);

export default JobSearchContainer;
