/* eslint react-hooks/exhaustive-deps:0, no-console:0 */
/** This componet is deprecated. */
import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import {
  useResize,
} from '../../utils/sizing';

import {
  scrollTo,
} from '../../utils/scrollTo';

import {
  H1,
  SmallButton as Button,
  CenteredSpinner,
  Colors,
  ExamineeMenu,
} from '../../components';

import CareerWheel from './DiscoverCareerWheel';
import ValuesGraph from './DiscoverValues';
import InterestsGraph from './DiscoverInterests';
import AbilitiesGraph from './DiscoverAbilities';
import FullGraph from './FullGraph';
import Cluster from './Cluster';
import JobSorter from './JobSorter';

const ClusterButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 1620px) {
    flex-wrap: wrap;
  }
`;

const ClusterButtonStyle = styled.div`
  background-color: ${Colors.white};
  max-width: 430px;
  height: 140px;
  flex: 1 1 auto;
  display: flex;
  text-align:center;
  justify-content:center;
  flex-direction:column;
  border-radius: 15px;

  margin: 1rem 0;

  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color} !important;

  text-decoration: none;
`;

const ClusterContainer = styled.div`
  margin: 1rem 0;
`;

const GraphContainer = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const GraphButtonContainer = styled.div`
  margin: 1rem 2rem;
`;

const GraphSelect = styled.select`
  margin-left: 1rem;
`;

const GraphNextButton = styled(Button)`
  margin: 1rem 1rem 1rem auto;
`;

const ClusterButton = ({
  name,
  color,
}) => (
  <ClusterButtonStyle
    color={color}
  >
    <H1 color={color}>{name}</H1>
  </ClusterButtonStyle>
);

const Loading = () => (
  <>
    <H1>Please Wait</H1>
    <CenteredSpinner />
  </>
);

const DiscoverComponent = ({
  abilities,
  activeProduct,
  assignedProducts,
  allClusters,
  rankedClusters,
  favoriteJobs,
  interests,
  loadClusters,
  loadJob,
  loadResults,
  loading,
  toggleFavoriteJob,
  type,
  unloadResults,
  updateFavoriteJob,
  user,
  values,
}) => {
  const [ showGraph, setShowGraph ] = useState(undefined);
  const [ sort, setSort ] = useState('high');
  const [ ranked, setRanked ] = useState(rankedClusters.ranked);
  const [ sortedInterests, setSortedInterests ] = useState(interests);
  const [ steps, setSteps ] = useState(0);
  const [ shownClusters, setShownClusters ] = useState(3);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      loadResults(activeProduct, user);
    }
    return () => {
      unloadResults();
    };
  }, [user]);

  useEffect(() => {
    if (rankedClusters && rankedClusters.ranked && rankedClusters.ranked.length > 0) {
      loadClusters(rankedClusters.ranked.slice(0, shownClusters), user.sub);
    }
  }, [loadClusters, rankedClusters.ranked, shownClusters]);

  useEffect(() => {
    const rnkd = cloneDeep(rankedClusters.ranked);
    switch (sort) {
      case 'high': {
        rnkd.sort((c1, c2) => (
          c2.total - c1.total
        ));
        break;
      }
      case 'low': {
        rnkd.sort((c1, c2) => (
          c1.total - c2.total
        ));
        break;
      }
      case 'clusters': {
        rnkd.sort((c1, c2) => (
          c1.id - c2.id
        ));
        break;
      }
      default: {
        console.log(`unkown sort ${sort}`);
        break;
      }
    }
    setRanked(rnkd);
  }, [ sort, rankedClusters, setRanked ]);

  useEffect(() => {
    if (!interests) {
      return;
    }
    const rnkd = cloneDeep(interests);
    switch (sort) {
      case 'high': {
        rnkd.sort((c1, c2) => (
          c2.norm - c1.norm
        ));
        break;
      }
      case 'low': {
        rnkd.sort((c1, c2) => (
          c1.norm - c2.norm
        ));
        break;
      }
      case 'clusters': {
        rnkd.sort((c1, c2) => (
          c1.id - c2.id
        ));
        break;
      }
      default: {
        console.log(`unkown sort ${sort}`);
        break;
      }
    }
    setSortedInterests(rnkd);
  }, [ sort, interests, setSortedInterests ]);

  useEffect(() => {
    if (values && !interests && !abilities) {
      setShowGraph('values');
    }
    if (!values && interests && !abilities) {
      setShowGraph('interests');
    }
    if (!values && !interests && abilities) {
      setShowGraph('abilities');
    }
  }, [ values, interests, abilities]);

  const ref = useRef();
  const resultsRef = useRef();
  const clusterButtonsRef = useRef();
  const clusterRefs = Array(16).fill(0).map(() => (useRef()));
  const jobSorterRef = useRef();
  const [ width ] = useResize(ref);

  const isFinished = activeProduct.testStatus.reduce((acc, stat) => {
    if (!stat.isFinished) {
      return false;
    }
    return acc;
  }, true);

  if (!isFinished) {
    return (
      <ExamineeMenu
        showChangeProduct={assignedProducts.length > 1}
        assigned={activeProduct}
      >
        <div ref={ref}>
          <H1>DISCOVER YOUR CAREER PROFILE</H1>
          <p>You must finish all your assessments before you can Discover careers.</p>
          <Button to="/dashboard/examinee/explore">Back</Button>
        </div>
      </ExamineeMenu>
    );
  }

  // if (loading || !clusters || !clusters.ranked || clusters.ranked.length === 0) {
  if (loading || rankedClusters.ranked.length === 0 || !allClusters[rankedClusters.ranked[0].id]) {
    return (
      <ExamineeMenu
        showChangeProduct={assignedProducts.length > 1}
        assigned={activeProduct}
      >
        <div ref={ref}>
          <Loading />
        </div>
      </ExamineeMenu>
    );
  }

  let graph;
  switch (showGraph) {
    case 'values':
      if (values) {
        graph = (
          <>
            <ValuesGraph
              values={values}
              width={width}
            />
            <p>
              This graph shows what clusters you value. Each scale has two
              sides, left or right. The closer you are to one side, the more
              you value jobs that have that trait.
            </p>
            <p>
              The three bars in darker read, show your highest 3 values.
            </p>
          </>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'interests':
      if (interests) {
        graph = (
          <>
            <InterestsGraph
              interests={sortedInterests}
              width={width}
            />
            <p>
              This graph shows you which clusters interest you. The higher the
              score the more jobs in this cluster interest you.
            </p>
          </>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'abilities':
      if (abilities) {
        graph = (
          <>
            <AbilitiesGraph
              abilities={abilities}
              width={width}
            />
            <p>
              This graph shows your abilities scores. These represent the 8
              assessments you took earlier. The higher the score the more
              ability you have in these areas.
            </p>
            <p>
              If you are weak in certain areas, do not worry! Many areas can be
              improved through training or education.
            </p>
          </>
        );
      } else {
        graph = (
          <p> You do not have access to this assessment.</p>
        );
      }
      break;
    case 'combined':
      graph = (
        <>
          <FullGraph
            clusters={ranked}
            clusterMax={rankedClusters.max}
            width={width}
          />
          <p>
            This graph shows how well you match to each cluster. The higher the
            score, the better the match. Your Values, Interests, and Abilities
            are represented by red, blue, and green rectangles within each
            cluster. The number on the far right shows your percent match to
            this cluster.
          </p>
        </>
      );
      break;
    default:
      graph = <div />;
  }

  const clusterElms = rankedClusters.ranked.slice(0, shownClusters).map((rnkd, i) => {
    const c = allClusters[rnkd.id];
    if (!c) {
      return (
        <ClusterContainer
          ref={clusterRefs[i]}
          key="loadingCluster"
        >
          <Loading />
        </ClusterContainer>
      );
    }
    return (
      <ClusterContainer
        ref={clusterRefs[i]}
        key={`cluster_${c.id}`}
      >
        <Cluster
          key={`cluster_${c.id}`}
          {...c}
          favoriteJobs={favoriteJobs}
          toggleFavorite={(job) => toggleFavoriteJob({ job, favoriteJobs })}
          loadJob={loadJob}
          lastCluster={shownClusters === (i + 1)}
          nextCluster={(action) => {
            setSteps(steps + 1);
            if (action === 'addCluster') {
              setShownClusters(shownClusters + 1);
            }
            if ((i + 1) === shownClusters) {
              scrollTo(jobSorterRef);
            } else {
              scrollTo(clusterRefs[i + 1]);
            }
          }}
        />
      </ClusterContainer>
    );
  });

  return (
    <div
      ref={ref}
      id="content"
    >
      <CareerWheel
        type={type}
        onClick={() => { setSteps(steps + 1); }}
      />
      <div ref={resultsRef}>
        <div>
          <H1>Your Results Profile</H1>
          <GraphContainer
            inWidth={width}
          >
            <GraphButtonContainer>
              <label htmlFor="results">Results:</label>
              <GraphSelect
                name="results"
                onChange={(e) => {
                  if (e.target.value === 'interests') {
                    setSort('clusters');
                  }
                  setShowGraph(e.target.value);
                }}
                value={showGraph}
              >
                <option value="combined">COMBINED</option>
                { values ? <option value="values">VALUES</option> : '' }
                { interests ? <option value="interests">INTERESTS</option> : '' }
                { values ? <option value="abilities">ABILITIES</option> : '' }
              </GraphSelect>
              { showGraph === 'combined' || showGraph === 'interests' ? (
                <GraphSelect
                  onChange={(e) => setSort(e.target.value)}
                  value={sort}
                >
                  <option value="high">HIGH TO LOW</option>
                  <option value="low">LOW TO HIGH</option>
                  <option value="clusters">CLUSTERS</option>
                </GraphSelect>
              ) : '' }
            </GraphButtonContainer>
            { graph }
            <GraphNextButton
              onClick={() => { setSteps(steps + 1); scrollTo(clusterButtonsRef); }}
            >
              Next
            </GraphNextButton>
          </GraphContainer>
        </div>
      </div>
      <div
        ref={clusterButtonsRef}
      >
        <div>
          <H1>Top Clusters</H1>
          <p>Below are you top three career clusters.</p>
          <ClusterButtonContainer>
            <ClusterButton
              name={`1. ${rankedClusters.ranked[0].name}`}
              color={rankedClusters.ranked[0].color}
            />
            <ClusterButton
              name={`2. ${rankedClusters.ranked[1].name}`}
              color={rankedClusters.ranked[1].color}
            />
            <ClusterButton
              name={`3. ${rankedClusters.ranked[2].name}`}
              color={rankedClusters.ranked[2].color}
            />
          </ClusterButtonContainer>
          <p>
            You now have the opportunity to explore careers with in each
            cluster. We recommend you pick 3 jobs from each cluster, at a
            minimum pick 1.
            <Button
              onClick={() => {
                setSteps(steps + 1);
                scrollTo(clusterRefs[0]);
              }}
            >
              Next
            </Button>
          </p>
        </div>
      </div>
      { clusterElms }
      <div
        ref={jobSorterRef}
      >
        <JobSorter
          favoriteJobs={favoriteJobs}
          loadJob={loadJob}
          toggleFavoriteJob={(job) => toggleFavoriteJob({ job, favoriteJobs })}
          updateFavoriteJob={updateFavoriteJob}
          finished={() => { navigate('/dashboard/examinee/succeed'); }}
        />
      </div>
    </div>
  );
};

export default DiscoverComponent;
