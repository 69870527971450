import {
  cloneDeep,
} from 'lodash';

import {
  LOAD_JOB_POSTINGS_REQUEST,
  LOAD_JOB_POSTINGS_SUCCESS,
} from '../actions/jobActions';

const clusterReducer = (
  state = {
    loading: false,
    listings: {
      self: [],
      partners: {
        indeed: {
          meta: {},
          listings: [],
        },
      },
    },
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case LOAD_JOB_POSTINGS_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case LOAD_JOB_POSTINGS_SUCCESS: {
      newState.loading = false;
      newState.listings.partners.indeed.meta = {
        ...action.payload.indeed,
        results: undefined,
      };
      newState.listings.partners.indeed.listings = action.payload.indeed.results;
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default clusterReducer;
