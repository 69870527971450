import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useNavigate,
} from 'react-router-dom';

import {
  Button,
  Fade,
  H1,
} from '../../../components';

const Wrapper = styled.div`
  max-width:800px;
  margin:2rem auto;
`;

const SetupWelcomeComponent = ({
  assignedProducts,
}) => {
  const navigate = useNavigate();

  const next = () => {
    if (assignedProducts.length > 0) {
      return navigate('/dashboard/setup/bio');
    }
    return navigate('/dashboard/setup/purchase');
  };

  return (
    <Wrapper>
      <Fade
        appear
        timeout={1000}
      >
        <>
          <H1>WELCOME</H1>
          <Button link onClick={next}>Next</Button>
        </>
      </Fade>
    </Wrapper>
  );
};

SetupWelcomeComponent.propTypes = {
  //eslint-disable-next-line react/forbid-prop-types
  assignedProducts: PropTypes.arrayOf(
    PropTypes.object,
  ),
};

SetupWelcomeComponent.defaultProps = {
  assignedProducts: [],
};

export default SetupWelcomeComponent;
