import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  KeyWrapper as KW,
  FinishButton,
  Content,
} from '../../components';

import {
  Input,
  Label,
} from './Inputs';

const ResponseContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
  margin: 3.25em 1em;
  flex-wrap: wrap;
`;

const Response = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ResponseLabel = styled(Label)`
  width: auto;
  max-width: inherit;
  border-radius: 5px;
  box-shadow: none;
  padding: .5rem;
  white-space: nowrap;
`;

const KeyWrapper = styled(KW)`
  flex-direction: column;
`;

export const LuItem = ({ testItem, keyId, onChange }) => {
  const {
    id,
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res) => (
    <Response key={`${keyId}_res_${res.ranking}`}>
      <Input
        type="radio"
        name={`sr_res_${id}`}
        id={`sr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <ResponseLabel htmlFor={`sr_res_${id}_${res.ranking}`}>
        {res.text}
      </ResponseLabel>
    </Response>
  ));
  return (
    <ResponseContainer
      key={keyId}
      id={keyId}
      role="radiogroup"
    >
      {responses}
    </ResponseContainer>
  );
};

const NaAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it) => (
    <React.Fragment key={`na_item_${it.ranking}`}>
      <LuItem
        keyId={`sr_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
      />
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/lu/finish"
        secondary
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer will go here</p>
            : <Timer />
        }
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);

  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={4}
      />
    </Content>
  );
};

export default NaAssessment;
