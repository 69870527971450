import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  KeyWrapper as KW,
  FinishButton,
  Content,
  Colors,
  ScreenReader,
} from '../../components';

import {
  Input,
  Label,
  Example,
} from './Inputs';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: strech;
  justify-content: center;
`;

const ItemCol = styled.div`
  display: flex;
  border-top: 1px solid ${Colors.lightGrey};
  border-left: 1px solid ${Colors.lightGrey};
  border-bottom: ${(props) => (props.$last ? `1px solid ${Colors.lightGrey}` : 'none')};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-right: 1px solid ${Colors.lightGrey};
`;

const ResponseCol = styled.div`
  display: flex;
  margin-left: .5rem;
`;

const Response = styled.div`
`;

const ResponseLabel = styled(Label)`
`;

const StyledTimer = styled(Timer)`
  margin: 0;
`;

const KeyWrapper = styled(KW)`
  flex-direction: row;
  justify-content: space-evenly;
`;

export const S = (
  <FormattedMessage
    id="psa.s"
    defaultMessage="S"
  />
);

export const Same = (
  <FormattedMessage
    id="psa.same"
    defaultMessage="Same"
  />
);

export const D = (
  <FormattedMessage
    id="psa.d"
    defaultMessage="D"
  />
);

export const Different = (
  <FormattedMessage
    id="psa.different"
    defaultMessage="Different"
  />
);

export const PsaItem = ({
  testItem,
  keyId,
  onChange,
  last,
}) => {
  const {
    id,
    item: {
      text,
    },
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = testItem;

  const [ value, setValue ] = useState(initValue);
  const responses = testItem.responses.map((res, i) => (
    <Response key={`${keyId}_res_${res.ranking}`}>
      <Input
        type="radio"
        name={`sr_res_${id}`}
        id={`sr_res_${id}_${res.ranking}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
      />
      <ResponseLabel htmlFor={`sr_res_${id}_${res.ranking}`}>
        {res.text}
        <ScreenReader> {res.alt}</ScreenReader>
      </ResponseLabel>
      { i % 2 === 0 ? <ScreenReader>Break</ScreenReader> : '' }
    </Response>
  ));
  const item = text.split('||');
  return (
    <Row>
      <ItemCol $last={last}>
        <Item>
          {item[0]}
        </Item>
        <ScreenReader>Break</ScreenReader>
        <Item>
          {item[1]}
        </Item>
      </ItemCol>
      <ResponseCol
        key={keyId}
        id={keyId}
        role="radiogroup"
      >
        {responses}
      </ResponseCol>
    </Row>
  );
};

const PsaAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const displayItems = items.map((it, i) => (
    <React.Fragment key={`na_item_${it.ranking}`}>
      <PsaItem
        keyId={`sr_${it.id}`}
        testItem={it}
        onChange={(params) => saveItem({ testStatus, time, ...params })}
        $last={items.length === i + 1}
      />
    </React.Fragment>
  ));
  if (isLastPage) {
    displayItems.push(
      <FinishButton
        key="finish"
        to="/assessment/abilities/psa/finish"
        secondary
      />,
    );
  }
  useEffect(() => {
    setRenderKey(() => (
      <KeyWrapper>
        {
          storyMode
            ? <p>Timer will go here</p>
            : <StyledTimer />
        }
        <Example
          item={S}
          text={Same}
        />
        <Example
          item={D}
          text={Different}
        />
      </KeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);

  return (
    <Content>
      {displayItems}
      <Nav
        testItems={testItems}
        ipp={40}
      />
    </Content>
  );
};

export default PsaAssessment;
