import { connect } from 'react-redux';

import App from './AppComponent';

import es from './compiled-lang/es.json';
import en from './compiled-lang/en.json';

import { initUser } from './actions/userActions';

const mapStateToProps = (state, props) => {
  let locale = 'en';
  let messages = en;

  const {
    assignedProduct: {
      activeProduct,
    },
  } = state;

  if (activeProduct && activeProduct.language === 'spanish') {
    locale = 'es';
    messages = es;
  }

  return {
    locale,
    messages,
    ...props,
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoaded: (user) => {
    if (user) {
      dispatch(initUser(user.profile.sub));
    }
  },
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
