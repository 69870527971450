import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  red,
  green,
  blue,
  white,
  lightGrey,
  grey,
} from './Colors';

import Spinner from './Spinner';
import UpperCaseMessage from './UpperCaseMessage';

import ScreenReader from './ScreenReader';

import {
  finish as finishMessage,
  beginAssessment as beginMessage,
  pleaseWait as pleaseMessage,
} from '../../utils/GlobalMessages';

const styleFn = (type) => (type`
  font-weight: 700;
  background-color: ${(props) => {
    if (props.$danger) {
      return red;
    }
    if (props.$secondary) {
      return green;
    }
    return blue;
  }};
  border: none;
  box-shadow: 0px 3px 6px ${lightGrey};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  color: ${white} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.$margin)};

  ${(props) => {
    if (props.$full || props.full) {
      return `
        flex: 1 1 auto;
        width: 100%;
      `;
    }
    return `width: ${props.width};`;
  }}
  height: ${(props) => (props.height)};
  padding: ${(props) => (props.$padding)};
  &:hover {
    opacity: .5;
  }

  text-decoration: none;
`);

const PrimaryButton = styleFn(styled.button);

const ToButton = styleFn(styled(Link));

const Ahref = styleFn(styled.a);

const LinkButton = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;

  color: ${blue};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) => {
    if (props.$full || props.full) {
      return `
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
      `;
    }
    return '';
  }}
`;

const XButtonStyle = styled.button`
  color: ${grey};
  background: none!important;
  border: none;
  padding: 0!important;

  margin-right: 1rem;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }
`;

const ClearButtonStyle = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;

  cursor: pointer;
  color: ${grey};

  &:hover {
    opacity: .5;
  }

  ${(props) => {
    if (props.$full || props.full) {
      return `
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
      `;
    }
    return '';
  }}
`;

/** The VIA System button wrapper */
const Button = ({
  children,
  link,
  clear,
  type,
  busy,
  disabled,
  to,
  href,
  danger,
  secondary,
  raw,
  full,
  padding,
  margin,
  ...rest
}) => {
  let kids = children;
  if (typeof children === 'string' && !link && !raw) {
    kids = children.toUpperCase();
  }
  if (link) {
    return (
      <LinkButton
        type={type}
        disabled={disabled}
        $danger={danger ? 1 : 0}
        $secondary={secondary ? 1 : 0}
        $padding={padding}
        $margin={margin}
        {...rest}
      >
        {kids}
      </LinkButton>
    );
  }
  if (to) {
    return (
      <ToButton
        aria-disabled={disabled || busy}
        to={disabled || busy ? '#' : to}
        type={type}
        $danger={danger ? 1 : 0}
        $secondary={secondary ? 1 : 0}
        disabled={disabled || busy}
        $padding={padding}
        $margin={margin}
        {...rest}
      >
        {
          busy ? (
            <span>
              <Spinner />
              &nbsp;
              <FormattedMessage
                {...pleaseMessage}
              />
            </span>
          ) : kids
        }
      </ToButton>
    );
  }
  if (href) {
    return (
      <Ahref
        aria-disabled={disabled || busy}
        href={disabled || busy ? '#' : href}
        type={type}
        $danger={danger ? 1 : 0}
        $secondary={secondary ? 1 : 0}
        disabled={disabled || busy}
        $padding={padding}
        $margin={margin}
        {...rest}
      >
        {
          busy ? (
            <span>
              <Spinner />
              &nbsp;
              <FormattedMessage
                {...pleaseMessage}
              />
            </span>
          ) : kids
        }
      </Ahref>
    );
  }
  if (clear) {
    return (
      <ClearButtonStyle
        disabled={disabled || busy}
        type={type}
        $danger={danger ? 1 : 0}
        $secondary={secondary ? 1 : 0}
        $padding={padding}
        $margin={margin}
        {...rest}
      >
        {
            busy ? (
              <span>
                <Spinner />
                &nbsp;
                <FormattedMessage
                  {...pleaseMessage}
                />
              </span>
            ) : kids
          }
      </ClearButtonStyle>
    );
  }
  return (
    <PrimaryButton
      disabled={disabled || busy}
      type={type}
      $full={full}
      $danger={danger ? 1 : 0}
      $secondary={secondary ? 1 : 0}
      $padding={padding}
      $margin={margin}
      {...rest}
    >
      {
          busy ? (
            <span>
              <Spinner />
              &nbsp;
              <FormattedMessage
                {...pleaseMessage}
              />
            </span>
          ) : kids
        }
    </PrimaryButton>
  );
};

Button.propTypes = {
  /** Styles the button as a secondary button */
  secondary: PropTypes.bool,
  /** Styles the button as a danger button */
  danger: PropTypes.bool,
  /** Styles the button as a link. */
  link: PropTypes.bool,
  /** Styles the button as clear text */
  clear: PropTypes.bool,
  /** Make the button fill the area */
  full: PropTypes.bool,
  /** Passes through the css width property. Cannot be used with full */
  width: PropTypes.string,
  /** Passes through the css height property. */
  height: PropTypes.string,
  /** Pass through the css margin property */
  margin: PropTypes.string,
  /** Padding passthrough */
  padding: PropTypes.string,
  /** Switch the button from a button to a submit */
  type: PropTypes.string,
  /** Disables the button */
  disabled: PropTypes.bool,
  /** Disables the button and shows a spinner */
  busy: PropTypes.bool,
  /** Disables auto capatialization of strings */
  raw: PropTypes.bool,
  /** Creates an external link styled as a button */
  href: PropTypes.string,
  /** Creates an internal link */
  to: PropTypes.string,
};

Button.defaultProps = {
  full: undefined,
  width: 'auto',
  height: 'auto',
  margin: '0',
  padding: '.5em',
  secondary: undefined,
  danger: undefined,
  clear: undefined,
  link: false,
  type: 'button',
  disabled: false,
  busy: false,
  raw: false,
  to: undefined,
  href: undefined,
};

export const LargeButton = (props) => (
  <Button
    {...props}
  />
);

export const MediumButton = (props) => (
  <Button
    width="13.75rem"
    height="2.5rem"
    padding="0"
    {...props}
  />
);

export const SmallButton = (props) => (
  <Button
    width="6rem"
    height="2.5rem"
    padding="0"
    {...props}
  />
);

export const FinishButton = (props) => (
  <SmallButton
    {...props}
    margin="1em auto"
  >
    <UpperCaseMessage
      {...finishMessage}
    />
  </SmallButton>
);

export const StartButton = (props) => (
  <MediumButton
    {...props}
    margin="auto"
  >
    <UpperCaseMessage
      {...beginMessage}
    />
  </MediumButton>
);

export const XButton = ({ screenReader, ...rest }) => (
  <XButtonStyle
    type="button"
    {...rest}
  >
    &#x2715;
    <ScreenReader>{screenReader || 'Close'}</ScreenReader>
  </XButtonStyle>
);

export const ClearButton = (props) => (
  <Button
    clear
    {...props}
  />
);

export default Button;
