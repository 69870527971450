import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';

export const FIND_GROUPS_REQUEST = 'FIND_GROUPS_REQUEST';
export const FIND_GROUPS_SUCCESS = 'FIND_GROUPS_SUCCESS';
export const FIND_GROUPS_ERROR = 'FIND_GROUPS_ERROR';

export const createGroup = ({
  group,
  sourceAdminGroup,
  products,
}) => (
  (dispatch) => {
    sendIt(dispatch, CREATE_GROUP_REQUEST);
    return http.put('/group/create', {
      group,
      sourceAdminGroup,
      products,
    }).then(({ data }) => {
      sendIt(dispatch, CREATE_GROUP_SUCCESS, {
        groups: [data.group],
        adminGroups: [data.adminGroup],
      });
    });
  }
);

// This will find all groups.
// SUPER ADMIN ONLY
export const findGroups = (params) => (
  (dispatch) => {
    sendIt(dispatch, FIND_GROUPS_REQUEST);
    return http.post('/groups/list', params).then(({ data }) => {
      sendIt(dispatch, FIND_GROUPS_SUCCESS, data);
    });
  }
);
