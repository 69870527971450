import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  isType,
} from '../../utils/products';

import {
  loadAssessment,
  saveResponse,
} from '../../actions/assessmentActions';

import MsdDirectionsComponent from '../components/MsdDirections';

const mapStateToProps = (state) => {
  const {
    assessment: {
      active: activeAssessment,
      chain,
    },
  } = state;

  const [ status ] = chain;

  let active = activeAssessment;
  if (!isEmpty(active) && !isType('abilities', active)) {
    active = {};
  }
  const {
    testType: {
      abbreviation: abbr,
    } = {},
  } = active;
  if (abbr !== 'MSD') {
    active = {};
  }

  return {
    loading: state.assessment.loading,
    assessment: active,
    status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadTest: (stat) => {
    dispatch(loadAssessment(stat));
  },
  saveItem: ({
    testStatus,
    testItem,
    response,
  }) => {
    dispatch(saveResponse({
      assignedProductId: testStatus.assignedProductId,
      testDefinitionId: testStatus.testDefinitionId,
      testItemId: testItem.id,
      choiceId: response.id,
    }));
  },
});

const MsdDirectionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MsdDirectionsComponent);

export default MsdDirectionsContainer;
