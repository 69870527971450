import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import withRouter from '../../utils/withRouter';
import {
  isType,
} from '../../utils/products';
import {
  saveResponse,
  finish,
} from '../../actions/assessmentActions';
import MsdAssessmentComponent from '../components/MsdAssessment';

const mapStateToProps = (state) => {
  let locale = 'en';

  const {
    assessment: {
      active: activeAssessment,
      chain,
    },
    assignedProduct: {
      activeProduct,
    },
  } = state;

  const [ status ] = chain;

  let active = activeAssessment;
  if (!isEmpty(active) && !isType('abilities', active)) {
    active = {};
  }
  const {
    testType: {
      abbreviation: abbr,
    } = {},
  } = active;
  if (abbr !== 'MSD') {
    active = {};
  }

  if (activeProduct && activeProduct.language === 'spanish') {
    locale = 'es';
  }

  return {
    locale,
    loading: state.assessment.loading,
    assessment: active,
    status,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  saveItem: ({
    testStatus,
    testItem,
    response,
  }) => {
    dispatch(saveResponse({
      assignedProductId: testStatus.assignedProductId,
      testDefinitionId: testStatus.testDefinitionId,
      testItemId: testItem.id,
      choiceId: response.id,
    }));
  },
  finish: (testStatus) => {
    dispatch(finish({
      assignedProductId: testStatus.assignedProductId,
      testDefinitionId: testStatus.testDefinitionId,
    })).then(() => {
      props.router.navigate('/assessment/abilities/complete');
    });
  },
});

const MsdDirectionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MsdAssessmentComponent);

export default withRouter(MsdDirectionsContainer);
