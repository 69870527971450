import { connect } from 'react-redux';
import EnterPasscode from '../components/EnterPasscode';

import { enterPasscode } from '../../actions/userActions';

const mapStateToProps = (state, props) => ({
  ...props,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (passcode) => (
    dispatch(enterPasscode(passcode))
  ),
});

const EnterPasscodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterPasscode);

export default EnterPasscodeContainer;
