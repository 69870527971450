import React from 'react'; import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { toHtml, icon } from "@fortawesome/fontawesome-svg-core";

import { grey, black, white } from '../components/Colors';

const getSVGURI = (faIcon, color) => {
  const abstract = icon(faIcon).abstract[0];
  if (color) abstract.children[0].attributes.fill = color;
  return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

const StyledSelect = styled.select`
  background-color: ${(props) => {
    if (props['background-color']) {
      return props['background-color'];
    }
    if (props.disabled) {
      return grey;
    }
    return `${white} !important`;
  }};
  border-color: ${grey} !important;
  &:hover {
    cursor: pointer;
  }

  color: ${black};

  border-radius: 0.5rem;

  /* Repace default arrow  with fontawesome*/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${(props) => (
    getSVGURI(faCaretDown, props?.style?.color)
  )});
  background-size: .75rem;
  background-repeat: no-repeat;
  background-position: right 0.5rem center; 
  padding-right: 1.25rem;
`;

/** Select wrapper */
const Select = ({
  placeholder,
  allowEmpty,
  children,
  ...rest
}) => (
  <StyledSelect
    {...rest}
  >
    {allowEmpty && <option value="">{placeholder}</option>}
    {children}
  </StyledSelect>
);

Select.propTypes = {
  /** Allows the empty option */
  allowEmpty: PropTypes.bool,
  /** Placeholder value. Must have allowEmpty:true */
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  allowEmpty: false,
  placeholder: 'Please Select',
};

export default Select;
