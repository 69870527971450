/* eslint jsx-a11y/label-has-associated-control:0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import momentPropTypes from 'react-moment-proptypes';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  FormikError,
  FormError,
} from '../../components';

const Label = styled.label`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
`;

const Input = styled.input`
  display: block;
  width: 100%;
`;

const Spacer = styled.span`
  display: flex;
  flex: 1 1 auto;
`;

/** The Examinee Setup form. Values help determine the norm group. */
const EditProfile = ({
  user: {
    id,
  },
  passwordSuccess,
  passwordError,
  isBusy,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [ newText, setNewText ] = useState();
  const [ matchText, setMatchText ] = useState();
  useEffect(() => {
    if (passwordSuccess) {
      navigate(-1);
    }
  }, [ passwordSuccess ]);
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      matchPassword: '',
      currentPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup
        .string()
        .required('Please enter a password')
        .test(
          'spaces',
          'Password cannot contain spaces',
          (password) => (password && !/\s/.test(password)),
        ),
      matchPassword: Yup
        .string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
        .required('Please match your passwords'),
      currentPassword: Yup
        .string()
        .required('Please enter your current password'),
    }),
    onSubmit: (values) => {
      if (!isBusy) {
        onSubmit({
          userId: id,
          password: values.newPassword,
          currentPassword: values.currentPassword,
        });
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classNames('pure-form', 'pure-form-stacked')}
      style={{
        width: '400px',
      }}
    >
      <Label htmlFor="newPassword">
        <span>New Password</span>
        <Spacer />
        <Button
          link
          onClick={() => setNewText(!newText)}
        >
          { newText ? 'Hide' : 'Show' }
        </Button>
      </Label>
      <Input
        type={newText ? 'text' : 'password'}
        name="newPassword"
        {...formik.getFieldProps('newPassword')}
      />
      <FormikError formik={formik} accessor="newPassword" />
      <Label htmlFor="matchPassword">
        <span>Confirm Password</span>
        <Spacer />
        <Button
          link
          onClick={() => setMatchText(!matchText)}
        >
          { matchText ? 'Hide' : 'Show' }
        </Button>
      </Label>
      <Input
        type={matchText ? 'text' : 'password'}
        name="matchPassword"
        {...formik.getFieldProps('matchPassword')}
      />
      <FormikError formik={formik} accessor="matchPassword" />
      <Label htmlFor="newPassword">Current Password</Label>
      <Input
        type="password"
        name="currentPassword"
        {...formik.getFieldProps('currentPassword')}
      />
      <FormikError formik={formik} accessor="currentPassword" />
      <FormError
        show={passwordError}
      >
        {passwordError || ''}
      </FormError>
      <Button type="submit" full busy={isBusy}>Update</Button>
      <Button
        link
        style={{
          margin: '.5em auto',
          width: '100%',
        }}
        onClick={() => {
          // NOTE: If you refresh on the password screen, this will throw an error
          navigate(-1);
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

EditProfile.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dob: PropTypes.oneOfType([PropTypes.string, momentPropTypes.momentObj]),
  }),
  isBusy: PropTypes.bool,
};

EditProfile.defaultProps = {
  user: {
    username: '',
    email: '',
    gender: '',
    grade: '',
    dob: '',
  },
  isBusy: false,
};

export default EditProfile;
