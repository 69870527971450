import React from 'react';
import PropTypes from 'prop-types';

import Select from './Select';

/** Selects the Gender. Values are M, F, or O.
  * The props, `onChange` and `value` are passed to the `Select` component.
  * Additional props are passed to the `Select` component using `...rest`.
  */
const Gender = ({
  placeholder,
  allowEmpty,
  allowOther,
  ...rest
}) => (
  <Select
    allowEmpty={allowEmpty}
    placeholder={placeholder}
    {...rest}
  >
    <option value="M">Male</option>
    <option value="F">Female</option>
    {
      allowOther
        ? <option value="O">Other</option>
        : ''
    }
  </Select>
);

Gender.propTypes = {
  /** Allows the empty option */
  allowEmpty: PropTypes.bool,
  /** Allows Other genders */
  allowOther: PropTypes.bool,
  /** Placeholder value. Must have allowEmpty:true */
  placeholder: PropTypes.string,
};

Gender.defaultProps = {
  allowEmpty: false,
  allowOther: false,
  placeholder: 'Gender',
};

export default Gender;
