import React from 'react';
import PropTypes from 'prop-types';

import { blue } from './Colors';

const ExploreIcon = ({ width, height, style }) => (
  <svg
    id="ExploreIcon"
    data-name="Explore Icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 65.53 65.22"
    width={width}
    height={height}
    style={style}
  >
    <title>Explore Icon</title>
    <path d="M37.58,21.23a11.42,11.42,0,1,0-8.07,19.48,11.5,11.5,0,0,0,4.37-.86,11.36,11.36,0,0,0,3.7-2.48,11.4,11.4,0,0,0,0-16.14Zm8.26,17.91,8.09,8.09.07.07a3.9,3.9,0,0,1-.07,5.46h0l-.06.06-.9.89A4,4,0,0,1,50.4,55a3.29,3.29,0,0,1-2.61-1h0l-8.35-8.35a19,19,0,1,1,6.41-6.44Zm-4.41,5,8.06,8h0a1,1,0,0,0,.76.33,1.66,1.66,0,0,0,1-.54l.9-.9.06-.06h0A1.53,1.53,0,0,0,52.67,50a1.5,1.5,0,0,0-.4-1l-7.82-7.82a19.67,19.67,0,0,1-3,3ZM29.51,12.74A16.58,16.58,0,1,1,17.8,17.59a16.51,16.51,0,0,1,11.71-4.85Zm5.27,3.82a14,14,0,0,1,4.48,3h0a13.79,13.79,0,1,1-19.5,0h0a13.81,13.81,0,0,1,15-3Z" style={{ fillRule: 'evenodd' }} />
  </svg>
);

ExploreIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({
    fill: PropTypes.string,
  }),
};

ExploreIcon.defaultProps = {
  width: 45,
  height: 45,
  style: {
    fill: blue,
  },
};

export default ExploreIcon;
