import { createGlobalStyle } from 'styled-components';

import { Colors } from '../components';

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: Heebo, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    letter-spacing: .01em;
    color: ${Colors.grey};
    display: flex;
    flex: 1 1 auto;
    overflow-anchor: none;
  }

  p {
    line-height: 1.7em;
    margin: 1em 15%;
  }

  a {
    color: ${Colors.blue};
    &:visited {
      color: ${Colors.grey};
    }

    &:hover {
      opacity: .5;
    }
  }

  #root {
    display: flex;
    flex: 1 1 auto;
    min-height: 100vh;
    flex-direction: column;
  }

  button {
    font-size: 20px;
    font-family: Heebo, sans-serif;
    padding: .5em .6em;
    margin: .25em 0em;
  }

  input, select, textarea {
    font-size: 20px;
    font-family: Heebo, sans-serif;

    padding: .5em .6em;
    margin: .25em 0em;

    border: 1px solid ${Colors.grey};
    border-radius: 0px;

    box-shadow: inset 0 1px 3px ${Colors.superLightGrey};

    box-sizing: border-box;
  }

  .heebo-me .katex {
    font-family: Heebo, sans-serif;
  }
}
`;

export default GlobalStyles;
