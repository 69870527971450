import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { CenteredSpinner } from '../../components';

const Signout = () => {
  const auth = useAuth();
  useEffect(() => {
    auth
      .signoutRedirect();
  }, []);
  return <CenteredSpinner />;
};

export default Signout;
