import http from '../utils/httpCli';
import { dispatchWrapper as sendIt } from '../utils/shortHand';

export const LOAD_CLUSTER_REQUEST = 'LOAD_CLUSTER_REQUEST';
export const LOAD_CLUSTER_SUCCESS = 'LOAD_CLUSTER_SUCCESS';
export const LOAD_CLUSTER_FAILURE = 'LOAD_CLUSTER_FAILURE';

export const loadClusters = (clusters, favoritesFor) => (
  (dispatch) => {
    const ids = clusters.map((clust) => (clust.id));
    const p = clusters.reduce((acc, clust) => {
      if (clust.type === 'professional') {
        return true;
      }
      return acc;
    }, false);
    sendIt(dispatch, LOAD_CLUSTER_REQUEST, { clusters: ids });
    return http.get(
      p ? '/pcluster' : '/cluster',
      {
        params: {
          clusters: ids,
          favoritesFor,
        },
      },
    ).then(({ data }) => {
      sendIt(dispatch, LOAD_CLUSTER_SUCCESS, { data });
    }).catch((e) => {
      sendIt(dispatch, LOAD_CLUSTER_FAILURE, { error: e });
    });
  }
);
