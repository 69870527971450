import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import {
  FIND_AVAILABLE_PRODUCTS_REQUEST,
  FIND_AVAILABLE_PRODUCTS_SUCCESS,
  MOVE_PRODUCTS_SUCCESS,
  DELETE_AVAILABLE_PRODUCTS_SUCCESS,
} from '../actions/availableProducts';

const availableProductsReducer = (
  state = {
    list: [],
    unqiue: [],
    total: 0,
    dic: {},
    loading: false,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case FIND_AVAILABLE_PRODUCTS_REQUEST: {
      newState.loading = true;
      newState.list = [];
      newState.total = 0;
      newState.dic = {};
      return newState;
    }
    case FIND_AVAILABLE_PRODUCTS_SUCCESS: {
      newState.loading = false;
      newState.list = [];
      newState.total = action.payload.total;
      newState.unique = action.payload.unique;
      action
        .payload
        .list
        .forEach((avail) => {
          newState.list.push({
            ...avail,
            createdAt: DateTime.fromISO(avail.createdAt),
            updatedAt: DateTime.fromISO(avail.updatedAt),
          });
          newState.dic[avail.id] = {
            ...avail,
            createdAt: DateTime.fromISO(avail.createdAt),
            updatedAt: DateTime.fromISO(avail.updatedAt),
          };
        });
      return newState;
    }
    case MOVE_PRODUCTS_SUCCESS: {
      action.payload.source.forEach((avail) => {
        newState.list.find((a) => a.id === avail.id).amount -= avail.amount;
      });
      action.payload.destination.forEach((avail) => {
        const found = newState.list.find((a) => a.id === avail.id);
        if (found) {
          found.amount = avail.amount;
        } else {
          newState.list.push(avail);
        }
      });
      newState.dic = newState.list.reduce((acc, avail) => {
        acc[avail.id] = avail;
        return acc;
      }, {});
      return newState;
    }
    case DELETE_AVAILABLE_PRODUCTS_SUCCESS: {
      action.payload.forEach((delProd) => {
        const index = newState.list.findIndex((avail) => avail.id === delProd.id);
        if (index >= 0) {
          newState.list.splice(index, 1);
        }
      });
      newState.dic = newState.list.reduce((acc, avail) => {
        acc[avail.id] = avail;
        return acc;
      }, {});
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default availableProductsReducer;
