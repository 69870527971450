/* eslint react-hooks/exhaustive-deps:0 */
import React from 'react';
import styled from 'styled-components';
/* import PropTypes from 'prop-types'; */
import {
  Link,
  Navigate,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  SmallButton,
  CenteredText,
  Colors,
  Content,
  H1,
  UpperCaseMessage,
} from '../../components';

import * as globalMessages from '../../utils/GlobalMessages';

const CenteredH1 = styled(H1)`
  text-align: center;
`;

const CenteredImg = styled.img`
  display: block;
  margin: auto;
`;

const NextButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-evenly;
`;

const BackLink = styled(Link)`
  color: ${Colors.blue};
  &:hover {
    opacity: .5;
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
`;

export const AssessmentComplete = ({
  testStatus,
  start,
  isFinished,
  render,
  image,
  type,
}) => {
  if (!isFinished[type]) {
    return <Navigate to="/" />;
  }

  let allFinished = true;
  if (isFinished.values === false) {
    allFinished = false;
  }
  if (isFinished.interests === false) {
    allFinished = false;
  }
  if (isFinished.abilities === false) {
    allFinished = false;
  }
  return (
    <Content>
      <CenteredImg src={image} width="400" height="315" alt="Finished Logo" />
      <CenteredH1>
        <UpperCaseMessage
          {...globalMessages[type]}
        />
        &nbsp;
        <FormattedMessage
          id="complete.title"
          defaultMessage="COMPLETED"
        />
      </CenteredH1>
      {render()}
      <CenteredText>
        <p>
          <FormattedMessage
            id="complete.learnMore"
            defaultMessage="Complete your profile to learn more."
          />
        </p>
        <NextButtonContainer>
          {
            isFinished.values === false
              ? (
                <Button
                  to="/assessment/values/start"
                  danger
                  onClick={() => { start(testStatus.values); }}
                >
                  <FormattedMessage
                    id="complete.startValues"
                    defaultMessage="BEGIN VALUES"
                  />
                </Button>
              ) : ''
          }
          {
            isFinished.interests === false
              ? (
                <Button
                  to="/assessment/interests/start"
                  onClick={() => { start(testStatus.interests); }}
                >
                  <FormattedMessage
                    id="complete.startInterests"
                    defaultMessage="BEGIN INTERESTS"
                  />
                </Button>
              ) : ''
          }
          {
            isFinished.abilities === false
              ? (
                <Button
                  to="/assessment/abilities/start"
                  secondary
                  onClick={() => start(testStatus.abilities)}
                >
                  <FormattedMessage
                    id="complete.startAbilities"
                    defaultMessage="BEGIN ABILITIES"
                  />
                </Button>
              ) : ''
          }
        </NextButtonContainer>
        <br />
        {
          allFinished
            ? (
              <SmallButton
                to="/dashboard/examinee/discover"
                style={{
                  margin: 'auto',
                }}
              >
                <FormattedMessage
                  id="globals.next"
                  defaultMessage="NEXT"
                />
              </SmallButton>
            )
            : (
              <BackLink
                to="/"
              >
                <FormattedMessage
                  id="complete.back"
                  defaultMessage="BACK TO DASHBOARD"
                />
              </BackLink>
            )
        }
      </CenteredText>
    </Content>
  );
};
