import { connect } from 'react-redux';

import withRouter from '../../utils/withRouter';
import {
  clearTimer,
} from '../../actions/timerActions';
import TimeoutComponent from '../components/Timeout';

const mapStateToProps = (state, props) => {
  const {
    assessment: {
      chain,
    },
    timer: {
      timeout,
    },
  } = state;

  const {
    router: {
      location: {
        pathname,
      },
    },
  } = props;

  let nextTest = '/';
  if (chain[0]) {
    const type = chain[0].testDefinition.testType.abbreviation.toLowerCase();
    nextTest = `/${pathname.split('/').slice(1, -1).concat([type, 'directions']).join('/')}`;
  }

  return {
    timeout,
    nextTest,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetTimer: () => {
    dispatch(clearTimer());
  },
});

const TimeoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeoutComponent);

export default withRouter(TimeoutContainer);
