import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Nav from './NavBar';

import Timer from '../../components/containers/Timer';

import {
  H2,
  Colors,
  FinishButton,
  Content,
  KeyWrapper,
  HR,
} from '../../components';

import {
  Input,
  Label,
} from './Inputs';

const CenteredContent = styled.div`
  display: inline-block;
`;

const CustomKeyWrapper = styled(KeyWrapper)`
  display: flex;
  max-height: none;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 675px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Key = styled.div`
  display: flex;
  width: 180px;
  align-items: center;
`;

const StyledTimer = styled(Timer)`
  margin: 0px;
`;

const KeyText = styled.span`
  margin-left: 1em;
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 1em 0em;
`;

export const Li = styled.li`
  margin: 1em 0em;
`;

export const VrLabel = styled(Label)`
  margin: .5em .2em;
`;

const VrItemRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
`;

const VrRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 2em;
`;

const VrItemText = styled.div`
`;

export const FactMessage = () => (
  <FormattedMessage
    id="vr.facts"
    defaultMessage="FACTS"
  />
);

export const ConclusionMessage = () => (
  <FormattedMessage
    id="vr.conclusions"
    defaultMessage="CONCLUSIONS"
  />
);

export const TMessage = () => (
  <FormattedMessage
    id="vr.T"
    defaultMessage="T"
  />
);

export const FMessage = () => (
  <FormattedMessage
    id="vr.F"
    defaultMessage="F"
  />
);

export const UMessage = () => (
  <FormattedMessage
    id="vr.U"
    defaultMessage="U"
  />
);

export const VrItem = ({ item, onChange }) => {
  const {
    id,
    item: {
      text,
    },
    responses,
    userResponse: {
      choice: {
        value: initValue = null,
      } = {},
    } = {},
  } = item;

  const [ value, setValue ] = useState(initValue);
  const choices = responses.map((res) => (
    <React.Fragment key={`radio_${id}_${res.id}`}>
      <Input
        type="radio"
        id={`radio_${id}_${res.id}`}
        value={res.value}
        checked={value === res.value}
        onChange={() => {
          setValue(res.value);
          onChange({ testItem: item, response: res });
        }}
      />
      <VrLabel
        htmlFor={`radio_${id}_${res.id}`}
        color={Colors.grey}
      >
        {res.text}
      </VrLabel>
    </React.Fragment>
  ));
  return (
    <VrItemRow key={`item_${id}`}>
      <VrRadioGroup
        aria-labelledby={`item_${id}`}
        role="radiogroup"
      >
        {choices}
      </VrRadioGroup>
      <VrItemText
        id={`item_${id}`}
      >
        {text}
      </VrItemText>
    </VrItemRow>
  );
};

const VRAssessment = ({
  testStatus,
  items,
  testItems,
  saveItem,
  isLastPage,
  time,
  storyMode,
  setRenderKey,
  clearRenderKey,
}) => {
  const statements = items[0].vrStatements.map((stat) => (
    <Li key={stat.id}>{stat.fact}</Li>
  ));
  const its = items[0].testItems.map((t) => (
    <VrItem
      key={`vrItem_${t.id}`}
      item={t}
      saveItem={saveItem}
      onChange={(params) => saveItem({ testStatus, time, ...params })}
    />
  ));

  useEffect(() => {
    setRenderKey(() => (
      <CustomKeyWrapper>
        {
          storyMode
            ? <p>Timer will go here</p>
            : <StyledTimer />
        }
        <Key>
          <VrLabel>
            <TMessage />
          </VrLabel>
          <KeyText>
            <FormattedMessage
              id="vr.true"
              defaultMessage="True"
            />
          </KeyText>
        </Key>
        <Key>
          <VrLabel>
            <FMessage />
          </VrLabel>
          <KeyText>
            <FormattedMessage
              id="vr.false"
              defaultMessage="False"
            />
          </KeyText>
        </Key>
        <Key>
          <VrLabel>
            <UMessage />
          </VrLabel>
          <KeyText>
            <FormattedMessage
              id="vr.uncertian"
              defaultMessage="Uncertian"
            />
          </KeyText>
        </Key>
      </CustomKeyWrapper>
    ));
    return () => { clearRenderKey(); };
  }, [setRenderKey, clearRenderKey, storyMode ]);

  const contentStyle = `
    justify-content: center;
    display: flex;
  `;

  return (
    <Content contentStyle={contentStyle}>
      <CenteredContent>
        <H2>
          <FactMessage />
        </H2>
        <Ul>
          {statements}
        </Ul>
        <HR />
        <H2>
          <ConclusionMessage />
        </H2>
        {its}
        {
          isLastPage
            ? (
              <FinishButton
                to="/assessment/abilities/vr/finish"
                secondary
              />
            )
            : ''
        }
        <Nav
          testItems={testItems}
          ipp={1}
          isVr
        />
      </CenteredContent>
    </Content>
  );
};

export default VRAssessment;
