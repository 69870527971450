import http from '../utils/httpCli';
import { dispatchWrapper as sendIt } from '../utils/shortHand';

import {
  LOAD_FAVORITE_JOBS_SUCCESS,
} from './jobActions';

export const LOAD_ASSESSMENT_REQUEST = 'LOAD_ASSESSMENT_REQUEST';
export const LOAD_ASSESSMENT_SUCCESS = 'LOAD_ASSESSMENT_SUCCESS';
export const LOAD_ASSESSMENT_FAILURE = 'LOAD_ASSESSMENT_FAILURE';

export const LOAD_RESULTS_REQUEST = 'LOAD_RESULTS_REQUEST';
export const LOAD_RESULTS_SUCCESS = 'LOAD_RESULTS_SUCCESS';
export const LOAD_RESULTS_FAILURE = 'LOAD_RESULTS_FAILURE';
export const LOAD_RESULTS_COMPLETE = 'LOAD_RESULTS_COMPLETE';

export const SET_ASSESSMENT_CHAIN = 'SET_ASSESSMENT_CHAIN';

export const SAVE_RESPONSE_REQUEST = 'SAVE_RESPONSE_REQUEST';
export const SAVE_RESPONSE_SUCCESS = 'SAVE_RESPONSE_SUCCESS';
export const SAVE_RESPONSE_FAILURE = 'SAVE_RESPONSE_FAILURE';

export const FINISH_REQUEST = 'FINISH_REQUEST';
export const FINISH_SUCCESS = 'FINISH_SUCCESS';
export const FINISH_FAILURE = 'FINISH_FAILURE';

export const SET_KEY = 'SET_KEY';
export const CLEAR_KEY = 'CLEAR_KEY';

export const SET_PUBLIC_REQUEST = 'SET_PUBLIC_REQUEST';
export const SET_PUBLIC_SUCCESS = 'SET_PUBLIC_SUCCESS';

export const setChain = (testStatusArray) => (
  (dispatch) => (
    sendIt(dispatch, SET_ASSESSMENT_CHAIN, testStatusArray)
  )
);

export const loadAssessment = (testStatus) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_ASSESSMENT_REQUEST, testStatus);
    return http.get(`/assessment?assignedId=${testStatus.assignedProductId}&testDefinitionId=${testStatus.testDefinitionId}`).then(({ data }) => {
      sendIt(dispatch, LOAD_ASSESSMENT_SUCCESS, data);
      return data;
    }).catch((e) => {
      sendIt(dispatch, LOAD_ASSESSMENT_FAILURE, e);
      return e;
    });
  }
);

export const loadResults = (activeProduct, opts = {}) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_RESULTS_REQUEST, { loading: true });
    return http.get('/assessment/results', {
      params: {
        assignedProductId: activeProduct.id,
        gender: opts.gender,
        grade: opts.grade,
        loadJobs: opts.loadJobs,
      },
    }).then(({ data }) => {
      sendIt(dispatch, LOAD_RESULTS_SUCCESS, { data });
      if (opts.loadJobs) {
        sendIt(dispatch, LOAD_FAVORITE_JOBS_SUCCESS, data.jobs);
      }
      return Promise.resolve(data);
    }).catch((e) => {
      sendIt(dispatch, LOAD_RESULTS_FAILURE, e);
      return Promise.reject(e);
    });
  }
);

export const cleanResults = () => (
  (dispatch) => {
    sendIt(dispatch, LOAD_RESULTS_REQUEST, { loading: false });
  }
);

export const saveResponse = ({
  assignedProductId,
  testDefinitionId,
  testItemId,
  choiceId,
  time,
}) => (
  (dispatch) => {
    sendIt(dispatch, SAVE_RESPONSE_REQUEST, {
      testItemId,
      choiceId,
    });
    return http.post(
      '/userResponse',
      {
        assignedProductId,
        testDefinitionId,
        testItemId,
        choiceId,
        time,
      },
    ).then(() => {
      sendIt(dispatch, SAVE_RESPONSE_SUCCESS, {
        testItemId,
        choiceId,
      });
    });
  }
);

/** Parameters: assignedProductId, testDefinitionId */
export const finish = (params) => (
  (dispatch) => {
    sendIt(dispatch, FINISH_REQUEST, params);
    return http.post('/assessment/finish', params).then(() => {
      sendIt(dispatch, FINISH_SUCCESS, params);
    }).catch((e) => {
      sendIt(dispatch, FINISH_FAILURE, {
        error: e,
        payload: params,
      });
    });
  }
);

export const setKey = ({ render }) => (
  (dispatch) => {
    sendIt(dispatch, SET_KEY, { render });
  }
);

export const clearKey = () => (
  (dispatch) => {
    sendIt(dispatch, CLEAR_KEY, {});
  }
);

export const setPublic = ({
  assignedProductId,
  isPublic,
}) => (
  (dispatch) => {
    sendIt(dispatch, SET_PUBLIC_REQUEST, { assignedProductId, isPublic });
    return http.post('/assessment/results', {
      assignedProductId,
      isPublic,
    }).then(() => {
      sendIt(dispatch, SET_PUBLIC_SUCCESS, { assignedProductId, isPublic });
    });
  }
);
