/** This is the adminGroup reducer for the loggined in user*/
import { uniqBy, cloneDeep } from 'lodash';

import {
  INIT_USER_SUCCESS,
} from '../actions/userActions';

import {
  CREATE_GROUP_SUCCESS,
} from '../actions/groupActions';

const adminGroupReducer = (
  state = {
    list: [],
  },
  action,
) => {
  switch (action.type) {
    case CREATE_GROUP_SUCCESS:
    case INIT_USER_SUCCESS: {
      const list = cloneDeep(state.list);
      action.payload.adminGroups.forEach((g) => list.push(g));
      return {
        ...state,
        list: uniqBy(list, 'id'),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default adminGroupReducer;
