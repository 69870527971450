import * as Yup from 'yup';

import httpClient from '../../utils/httpCli';

export const UsernameSchema = Yup.string()
  .required('Please enter a username')
  .test(
    'illegal-chars',
    'Usernames may not contain spaces, commas, or semi-colons',
    (username) => username && username.match(/.*(\s|,|;).*/) === null,
  );

export const UniqueUsernameSchema = (initialUsername) => Yup.string()
  .required('Please enter a username')
  .test(
    'unique-username',
    'This username already exists',
    (value) => new Promise((resolve) => {
      // do not test the (optional) initial username for uniqueness
      if (value === initialUsername) {
        return resolve(true);
      }
      return httpClient.get('/check/username', { params: { username: value } })
        .then((response) => resolve(response.data === true))
        .catch((err) => {
          const isStorybook = window.location.href.includes('localhost:9009');
          /*eslint-disable-next-line*/
          console.log(isStorybook ? 'Username lookup can NOT work in storybook' : err.message);
          resolve(isStorybook);
        });
    }),
  );
