import { connect } from 'react-redux';
import CallbackComponent from '../components/CallBack';

import { broadcastFatalError } from '../../actions/errorActions';
import { initUser } from '../../actions/userActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => dispatch(initUser(id)),
  errorHandler: (err) => {
    dispatch(broadcastFatalError(err));
  },
});

const CallbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CallbackComponent);

export default CallbackContainer;
