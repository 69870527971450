import Directions from './DirectionsContainer';
import SrDirectionsComponent from '../components/SrDirections';

const SrDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'SR',
  component: SrDirectionsComponent,
});

export default SrDirectionsContainer;
