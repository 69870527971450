import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const FIND_AVAILABLE_PRODUCTS_REQUEST = 'FIND_AVAILABLE_PRODUCTS_REQUEST';
export const FIND_AVAILABLE_PRODUCTS_SUCCESS = 'FIND_AVAILABLE_PRODUCTS_SUCCESS';
export const FIND_AVAILABLE_PRODUCTS_ERROR = 'FIND_AVAILABLE_PRODUCTS_ERROR';

export const MOVE_PRODUCTS_REQUEST = 'MPR';
export const MOVE_PRODUCTS_SUCCESS = 'MPS';
export const MOVE_PRODUCTS_ERROR = 'MPE';

export const DELETE_AVAILABLE_PRODUCTS_REQUEST = 'DELETE_AVAILABLE_PRODUCTS_REQUEST';
export const DELETE_AVAILABLE_PRODUCTS_SUCCESS = 'DELETE_AVAILABLE_PRODUCTS_SUCCESS';
export const DELETE_AVAILABLE_PRODUCTS_ERROR = 'DELETE_AVAILABLE_PRODUCTS_ERROR';

export const findAvailableProducts = (queryParams) => (
  (dispatch) => {
    sendIt(dispatch, FIND_AVAILABLE_PRODUCTS_REQUEST);
    return http.post('/availableProducts/list', queryParams).then(({ data }) => {
      sendIt(dispatch, FIND_AVAILABLE_PRODUCTS_SUCCESS, data);
      return data;
    }).catch((e) => {
      /* eslint-disable-next-line */
      console.error(e);
      sendIt(dispatch, FIND_AVAILABLE_PRODUCTS_ERROR, e);
    });
  }
);

/* params = {
    availableProducts: [{
      id: 0,
      amount: 0,
    }],
    destinationId: 0,
  }
*/
export const moveProducts = (params) => (
  (dispatch) => {
    sendIt(dispatch, MOVE_PRODUCTS_REQUEST);
    return http.post('/availableProducts/move', params).then(({ data }) => {
      sendIt(dispatch, MOVE_PRODUCTS_SUCCESS, {
        source: params.availableProducts,
        destination: data.products,
      });
      return Promise.resolve({
        source: params.availableProducts,
        destination: data.products,
      });
    }).catch((e) => {
      if (e.response) {
        sendIt(dispatch, MOVE_PRODUCTS_ERROR, e.response.data);
        return Promise.reject(e.response.data);
      }
      sendIt(dispatch, MOVE_PRODUCTS_ERROR, e);
      return Promise.reject(e);
    });
  }
);

export const deleteAvailableProducts = (params) => (
  (dispatch) => {
    sendIt(dispatch, DELETE_AVAILABLE_PRODUCTS_REQUEST);
    setTimeout(() => {
      sendIt(dispatch, DELETE_AVAILABLE_PRODUCTS_SUCCESS, params);
    }, 1000);
    /*
    return http.post('/availableProducts/delete', {
      availableProducts: params.map(({ id }) => (id)),
    }).then(() => {
      sendIt(dispatch, DELETE_AVAILABLE_PRODUCTS_SUCCESS, params);
      return params;
    }).catch((e) => {
      sendIt(dispatch, DELETE_AVAILABLE_PRODUCTS_ERROR, e);
    });
    */
  }
);
