import { connect } from 'react-redux';
import {
  filter,
  each,
  chain,
  reduce,
} from 'lodash';

import DiscoverComponent from '../components/Discover';
import {
  isFinished as computeIsFinished,
  getStatus,
} from '../../utils/products';
import {
  loadResults,
  cleanResults,
} from '../../actions/assessmentActions';
import {
  loadClusters,
} from '../../actions/clusterActions';
import {
  toggleFavoriteJob,
  updateFavoriteJob,
} from '../../actions/jobActions';

const mapStateToProps = (state) => {
  const {
    user: {
      profile: user,
    },
    assignedProduct: {
      activeProduct,
    },
    assessment: {
      loading: loadingAssessments,
      results: {
        values,
        interests,
        abilities,
        clusters: rankedClusters,
        suppressed,
      },
    },
    cluster: {
      clusters: allClusters,
      subClusters: allSubClusters,
    },
    jobs: {
      obj: allJobs,
      favorites: allFavoriteJobs,
    },
    display: {
      manualDiscover,
    },
  } = state;

  // join the clusters, sub clusters, and jobs together
  const allFullClusters = {};
  each(allClusters, (clust) => {
    const subClusters = chain(allSubClusters)
      .filter({ clusterId: clust.id })
      .map((sub) => ({
        ...sub,
        jobs: filter(allJobs, { subClusterId: sub.id }),
      }))
      .value();

    allFullClusters[clust.id] = {
      ...clust,
      subClusters,
      // if it's professional, we don't have subclusters
      jobs: subClusters.length === 0 ? filter(allJobs, { clusterId: clust.id }) : undefined,
    };
  });

  let type;
  if (activeProduct.professional) {
    type = 'professional';
  } else if (activeProduct.language === 'spanish') {
    type = 'spanish';
  } else {
    type = 'cluster';
  }

  const favoriteJobs = allFavoriteJobs
    .map((j) => {
      const clust = allClusters[j.clusterId];
      if (clust) {
        return {
          ...j,
          clusterName: clust.name,
        };
      }
      return j;
    });

  const isFinished = computeIsFinished(activeProduct);
  const allFinished = reduce(isFinished, (acc, value) => {
    if (!value) {
      return false;
    }
    return acc;
  }, true);

  return {
    activeProduct: {
      ...activeProduct,
      ...isFinished,
      values: getStatus('values', activeProduct),
      interests: getStatus('interests', activeProduct),
      abilities: getStatus('abilities', activeProduct),
    },
    allFinished,
    allClusters: allFullClusters,
    type,
    loading: loadingAssessments,
    user,
    values,
    interests,
    abilities,
    rankedClusters,
    favoriteJobs,
    manualDiscover,
    suppressed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadResults: (activeProduct) => (
    dispatch(loadResults(activeProduct, { loadJobs: true }))
  ),
  unloadResults: () => (dispatch(cleanResults())),
  loadClusters: (clusters, favoritesFor) => (dispatch(loadClusters(clusters, favoritesFor))),
  toggleFavoriteJob: (params) => (dispatch(toggleFavoriteJob(params))),
  updateFavoriteJob: (params) => (dispatch(updateFavoriteJob(params))),
});

const DiscoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscoverComponent);

export default DiscoverContainer;
