import { dispatchWrapper as sendIt } from '../utils/shortHand';
import httpCli from '../utils/httpCli';

export const TIMER_START = 'TIMER_START';
export const TIMER_STOP = 'TIMER_STOP';
export const TIMER_TIMEOUT = 'TIMER_TIMEOUT';
export const TIMER_TICK = 'TIMER_TICK';

export const startTimer = () => (
  (dispatch) => {
    sendIt(dispatch, TIMER_START);
  }
);

export const clearTimer = () => (
  (dispatch) => {
    sendIt(dispatch, 'TIMER_STOP');
  }
);

export const timeout = ({
  testDefinitionId,
  assignedProductId,
}) => (
  (dispatch) => {
    sendIt(dispatch, 'TIMER_TIMEOUT', {
      testDefinitionId,
      assignedProductId,
    });
    return httpCli.post('/assessment/finish', {
      testDefinitionId,
      assignedProductId,
    });
  }
);

export const tick = ({
  time,
  testDefinitionId,
  assignedProductId,
}) => (
  (dispatch) => {
    sendIt(dispatch, 'TIMER_TICK', { time });
    if ((time / 1000) % 10 === 0) {
      return httpCli.post(
        '/assessment/tick',
        {
          testDefinitionId,
          assignedProductId,
          time,
        },
      );
    }
    return Promise.resolve();
  }
);
