import { connect } from 'react-redux';
import ExamineeMenuCom from '../components/ExamineeMenu';

import {
  setExpandMenu,
  clickExplore,
  clickDiscover,
} from '../../actions/displayActions';

const mapStateToProps = (state, props) => {
  const {
    user: {
      profile,
    },
    assignedProduct: {
      list: assignedProducts,
    },
    display: {
      expandedMenu: expanded,
    },
    adminGroup: {
      list: adminGroups,
    },
  } = state;
  return ({
    user: profile,
    expanded,
    showAdmin: adminGroups.length > 0,
    showChangeProduct: assignedProducts.length > 1,
    ...props,
  });
};

const mapDispatchToProps = (dispatch) => ({
  setExpanded: (isExpanded) => {
    dispatch(setExpandMenu(isExpanded));
  },
  clickExplore: () => (dispatch(clickExplore())),
  clickDiscover: () => (dispatch(clickDiscover())),
});

const ExamineeMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExamineeMenuCom);

export default ExamineeMenuContainer;
