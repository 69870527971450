import React from 'react';
import styled from 'styled-components';

import { SmallButton as Button } from './Button';
import Modal from './Modal';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto; 
  margin: 1rem 15%;
  justify-content: center;
`;

const SwitchToExamineeModal = ({
  examinee,
  show,
  onClose,
  onSubmit,
}) => (
  <Modal
    isOpen={show}
    title="Switch to Examinee"
  >
    <p>Do you want to switch to {examinee.firstName}?</p>
    <p>This will sign you out of the current session and sign you in as {examinee.firstName}.</p>
    <ButtonContainer>
      <Button
        onClick={() => {
          onSubmit(examinee);
        }}
      >
        Yes
      </Button>
      <Button
        link
        onClick={onClose}
        style={{
          marginLeft: '1rem',
        }}
      >
        No
      </Button>
    </ButtonContainer>
  </Modal>
);

SwitchToExamineeModal.defaultProps = {
  examinee: {},
  show: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default SwitchToExamineeModal;
