/* eslint import/prefer-default-export: 0 */
/** Utility shorthand file */

/** Short and for dispatching actions */
export const dispatchWrapper = (dispatch, type, payload = {}) => (
  dispatch({
    type,
    payload,
  })
);
