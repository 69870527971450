import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ children, ...props }) => (
  <>
    {
      React.Children.map(children, (kid) => (
        React.cloneElement(kid, { ...props })
      ))
    }
  </>
);

Step.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /** The name of the step. It must be a string and will be used as a react key. */
  name: PropTypes.string.isRequired,
  /** If true, will display the name of the step*/
  $display: PropTypes.bool,
};

Step.defaultProps = {
  $display: false,
};

export default Step;
