import P from 'bluebird';
import PropTypes from 'prop-types';

let apiPath = process.env.REACT_APP_API || 'https://api.viasystem.com/v1/';
apiPath = apiPath.replace('/v1/', '');

const singleCache = (src) => (
  new P((resolve, reject) => {
    const img = new Image();
    const path = `${apiPath}${src}`;
    img.src = path;
    img.onload = () => { resolve(); };
    img.onerror = () => { reject(new Error(`Cannot load image ${src}`)); };
  })
);

/** Loads an array of strings as images into the browser cache.
  * Notifies when all images have loaded
  */
const ImageCache = (imgArray) => (
  P.all(imgArray.map((img) => (singleCache(img))))
);

ImageCache.propTypes = {
  /** An array of strings to load.
   *
   *   - Relative to `REACT_APP_API`.
   *   - default `https://api.viasystem.com/`
   *   - `/v1` is striped from the apiPath.
  */
  imgArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageCache;
