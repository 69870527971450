import moment from 'moment';

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  PASSWORD_REQUEST,
  PASSWORD_SUCCESS,
  PASSWORD_ERROR,
  INIT_USER_SUCCESS,
  INIT_USER_REQUEST,
  INIT_USER_ERROR,
} from '../actions/userActions';

const UserReducer = (
  state = {
    profile: undefined,
    updating: false,
    updateError: undefined,
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        updating: true,
        updateError: undefined,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
        updating: false,
        updateError: undefined,
      };
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };
    }
    case INIT_USER_SUCCESS: {
      const {
        dob,
      } = action.payload.user;

      return {
        ...state,
        loading: false,
        profile: {
          ...action.payload.user,
          gender: action.payload.user.gender || undefined,
          grade: action.payload.user.grade || undefined,
          dob: dob ? moment(dob) : undefined,
        },
      };
    }
    case INIT_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INIT_USER_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PASSWORD_REQUEST: {
      return {
        ...state,
        passwordError: undefined,
        passwordSuccess: false,
        updating: true,
      };
    }
    case PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordError: undefined,
        passwordSuccess: true,
        updating: false,
      };
    }
    case PASSWORD_ERROR: {
      return {
        ...state,
        passwordError: action.payload.message,
        passwordSuccess: false,
        updating: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default UserReducer;
