/* eslint jsx-a11y/label-has-associated-control: 0 */
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { DateTime } from 'luxon';
import { parseInt } from 'lodash';

import { white, black } from '../components/Colors';
import ScreenReader from '../components/ScreenReader';

const MONTH = 'MONTH';
const DAY = 'DAY';
const YEAR = 'YEAR';

const Wrapper = styled.div`
  border: 1px solid ${black};
  box-shadow: inset 0 1px 3px #ddd;
  margin-top: .25em;
  margin-bottom: .25em;
  display: flex;
  align-items:center;

  background-color: ${white};

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
`;

const StyledInput = styled.input`
  display: flex;
  flex: 1 1 auto;
  text-align:center;
  padding: .5em;
  width: ${(props) => (props.$year ? '4em' : '3.5em')};
  border: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  display: inline-block !important;
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin:0;
  }

`;

/** Date of birth input.
 *
 * Returns null or a valid moment object to onChange or onBlur handlers.
 * All fields must be entered to recieve a moment object.
 */
const DOB = ({
  value,
  name,
  onChange,
  onBlur,
  className,
}) => {
  const monthRef = React.useRef(null);
  const dayRef = React.useRef(null);
  const yearRef = React.useRef(null);
  let initialState = {
    month: '',
    day: '',
    year: '',
    date: moment(),
    callback: onChange,
  };
  let shadowValue = value;
  // luxon compatibility
  if (value && !moment.isMoment(value) && value.isValid) {
    shadowValue = moment(value.toISODate());
  }
  // legacy moment compatibility
  if (shadowValue && moment.isMoment(shadowValue) && shadowValue.isValid()) {
    initialState = {
      month: shadowValue.month() + 1,
      day: shadowValue.date(),
      year: shadowValue.year(),
      date: moment(shadowValue), // clone the object
      callback: onChange,
    };
  }

  // compute the state.
  const reducer = (state, action) => {
    let {
      toFocus,
      year,
      month,
      day,
      /* eslint-disable-next-line */
      date,
    } = state;

    switch (action.type) {
      case MONTH:
        month = action.payload.value;
        if (month.toString().length === 2 && action.payload.type === 'change') {
          toFocus = dayRef;
        }
        break;
      case DAY:
        day = action.payload.value;
        if (day.toString().length === 2 && action.payload.type === 'change') {
          toFocus = yearRef;
        }
        break;
      case YEAR:
        year = action.payload.value;
        date.year(year);
        break;
      default:
        throw new Error('unknown state');
    }

    if (month > 12 || month < 0) {
      month = '';
      date.year(year);
      toFocus = monthRef;
    } else if (parseInt(month) === 0) {
      date.year(year);
    } else {
      date.month(month - 1);
    }

    if (day < 0 || day > date.daysInMonth()) {
      day = '';
      date.year(year).month(month);
      toFocus = dayRef;
    } else {
      date.date(day);
    }

    const callback = action.payload.type === 'blur' ? onBlur : onChange;
    return {
      toFocus,
      year,
      month,
      day,
      date,
      callback,
    };
  };

  const [ state, dispatch ] = useReducer(reducer, initialState);

  // Send changes out
  useEffect(
    () => {
      const {
        year, month, day, date, callback,
      } = state;
      if (year > 1820 && month && day) {
        if (value && !moment.isMoment(value)) {
          callback(DateTime.fromISO(date.format('YYYY-MM-DD')));
        } else {
          callback(moment(date));
        }
      } else {
        callback(null);
      }
    },
    [state],
  );

  // Focus on the next input
  useEffect(
    () => {
      if (state.toFocus) {
        state.toFocus.current.focus();
      }
    },
    [ state.toFocus ],
  );

  return (
    <Wrapper
      aria-labelledby={name}
      className={className}
    >
      <ScreenReader>
        <label htmlFor="dobMonth">Month format MM</label>
      </ScreenReader>
      <StyledInput
        name="dobMonth"
        ref={monthRef}
        type="number"
        value={state.month}
        placeholder="MM"
        onBlur={
          (e) => {
            dispatch({
              type: MONTH,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
        onChange={
          (e) => {
            dispatch({
              type: MONTH,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
      />
      <span>/</span>
      <ScreenReader>
        <label htmlFor="dobDay">Day format DD</label>
      </ScreenReader>
      <StyledInput
        name="dobDay"
        ref={dayRef}
        type="number"
        value={state.day}
        placeholder="DD"
        onBlur={
          (e) => {
            dispatch({
              type: DAY,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
        onChange={
          (e) => {
            dispatch({
              type: DAY,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
      />
      <span>/</span>
      <ScreenReader>
        <label htmlFor="dobYear">Year format YYYY</label>
      </ScreenReader>
      <StyledInput
        name="dobYear"
        ref={yearRef}
        $year
        type="number"
        value={state.year}
        placeholder="YYYY"
        onBlur={
          (e) => {
            dispatch({
              type: YEAR,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
        onChange={
          (e) => {
            dispatch({
              type: YEAR,
              payload: {
                type: e.type,
                value: e.target.value,
              },
            });
          }
        }
      />
    </Wrapper>
  );
};

DOB.propTypes = {
  /** Value to initialize fields. Must be a moment object */
  value: PropTypes.oneOfType([
    momentPropTypes.momentObj,
    PropTypes.any,
  ]),
  /** Recieves null or a moment object */
  onChange: PropTypes.func,
  /** Recieves null or a moment object */
  onBlur: PropTypes.func,
};

DOB.defaultProps = {
  value: undefined,
  onChange: () => {},
  onBlur: () => {},
};

export default DOB;
