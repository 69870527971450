import React from 'react';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';

import configureStore from './store';
import App from './AppContainer';
import GlobalStyles from './utils/GlobalStyles';
import { oidcConfig } from './utils/oidc';

/*
// import { LOG } from 'oidc-client-ts'
Log.setLevel(Log.DEBUG);
Log.setLogger(console);
*/

const store = configureStore();

const Root = () => (
  <>
    <GlobalStyles />
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <App>
          <Outlet />
        </App>
      </AuthProvider>
    </Provider>
  </>
);

export default Root;
