import React from 'react';
import { Link } from 'react-router-dom';

import { H1 } from './Headers';
import CenteredText from './CenteredText';

const NoRoute = () => (
  <CenteredText>
    <H1>Page Not Found</H1>
    <p>You are just too good at exploring. We cannot find the page you requested.</p>
    <Link to="/">Back to your dashboard.</Link>
  </CenteredText>
);

export default NoRoute;
