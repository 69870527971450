import { connect } from 'react-redux';

import { updatePassword } from '../../actions/userActions';
import EditPasswordComponent from '../components/EditPassword';

const mapStateToProps = (state) => ({
  isBusy: state.user.updating,
  passwordError: state.user.passwordError,
  passwordSuccess: state.user.passwordSuccess,
  user: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) => (
    dispatch(updatePassword(values))
  ),
});

const EditPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditPasswordComponent);

export default EditPassword;
