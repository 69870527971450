import {
  cloneDeep,
  chain,
  remove,
} from 'lodash';

import {
  LOAD_CLUSTER_REQUEST,
  LOAD_CLUSTER_SUCCESS,
} from '../actions/clusterActions';

import {
  LOAD_FAVORITE_JOBS_REQUEST,
  LOAD_FAVORITE_JOBS_SUCCESS,
  LOAD_JOB_SUCCESS,
  TOGGLE_FAVORITE_JOB,
  UPDATE_FAVORITE_JOBS,
} from '../actions/jobActions';

const jobReducer = (
  state = {
    loading: false,
    obj: {},
    favorites: [],
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case LOAD_CLUSTER_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case LOAD_CLUSTER_SUCCESS: {
      newState.loading = false;

      // We have to make this data match the favoriteJobs data
      // If you update the loadCluster handler, you'll need to update this
      // also, I'm not sure if we need the cloneDeep?

      // loop through the clusters and extract the jobs
      action.payload.data.forEach((clust) => {
        // regular version b/c it's got subClusters
        if (clust.subClusters) {
          clust.subClusters.forEach((sub) => {
            sub.jobs.forEach((j) => {
              const newJob = {
                ...j,
                clusters: [ {
                  id: clust.id,
                  name: clust.name,
                  description: clust.description,
                  type: clust.type,
                  color: clust.color,
                  ranking: clust.ranking,
                }],
                clusterId: clust.id,
                subClusterId: sub.id,
                subClusters: [
                  {
                    id: sub.id,
                    name: sub.name,
                  },
                ],
                loaded: false,
              };
              newState.obj[j.id] = cloneDeep(newJob);

              if (j.favoriteJob !== null && j.favoriteJob !== undefined) {
                newState.favorites.push(cloneDeep(newJob));
              }
            });
          });
        }
        // professional version
        if (clust.jobs) {
          clust.jobs.forEach((j) => {
            const newJob = {
              ...j,
              clusters: [ {
                id: clust.id,
                name: clust.name,
                description: clust.description,
                type: clust.type,
                color: clust.color,
                ranking: clust.ranking,
              }],
              clusterId: clust.id,
              loaded: false,
            };
            newState.obj[j.id] = cloneDeep(newJob);
            if (j.favoriteJob !== null && j.favoriteJob !== undefined) {
              newState.favorites.push(cloneDeep(newJob));
            }
          });
        }
      });
      newState.favorites = chain(newState.favorites)
        .uniqBy('id')
        .orderBy('favoriteJob')
        .value();
      return newState;
    }
    case LOAD_FAVORITE_JOBS_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case LOAD_FAVORITE_JOBS_SUCCESS: {
      newState.loading = false;
      action.payload.forEach((j, i) => {
        newState.obj[j.id] = {
          ...j,
          loaded: true,
          favoriteJob: i,
        };
      });
      newState.favorites = action.payload.map((j, i) => ({
        ...j,
        favoriteJob: i,
        loaded: true,
      }));
      return newState;
    }
    case LOAD_JOB_SUCCESS: {
      newState.obj[action.payload.id] = {
        ...newState.obj[action.payload.id],
        ...action.payload,
        loaded: true,
      };
      newState.favorites = newState.favorites.map((j) => {
        if (j.id === action.payload.id) {
          return {
            ...j,
            ...action.payload,
            loaded: true,
          };
        }
        return j;
      });
      return newState;
    }
    case TOGGLE_FAVORITE_JOB: {
      const {
        payload: {
          job: {
            id,
          },
        },
      } = action;
      const deletedFav = remove(newState.favorites, { id });
      // if we didn't remove one, push it
      if (deletedFav.length === 0) {
        newState.obj[id].favoriteJob = newState.favorites.length;
        newState.favorites.push({
          ...action.payload.job,
          favoriteJob: newState.favorites.length,
        });
      } else {
        newState.obj[deletedFav[0].id].favoriteJob = null;
        newState.favorites = newState.favorites.map((j, i) => ({
          ...j,
          favoriteJob: i,
        }));
      }
      return newState;
    }
    case UPDATE_FAVORITE_JOBS: {
      newState.favorites = [];
      action.payload.favoriteJobs.forEach((j, i) => {
        newState.favorites.push({
          ...j,
          favoriteJob: i,
        });
        newState.obj[j.id].favoriteJob = i;
      });
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default jobReducer;
