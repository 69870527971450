import { connect } from 'react-redux';

import { broadcastFatalError } from '../../actions/errorActions';

import SuperRouteComponent from '../components/SuperRoute';

const mapStateToProps = (state, props) => ({
  user: state.user.profile,
  ...props,
});

const mapDispatchToProps = (dispatch) => ({
  errorHandler: (err) => {
    dispatch(broadcastFatalError(err));
  },
});

const SuperRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuperRouteComponent);

export default SuperRouteContainer;
