import { connect } from 'react-redux';
import InterestsAssessmentComponent from '../components/InterestsAssessmentComponent';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const InterestsAssessmentContainer = connect(
  mapStateToProps(),
  mapDispatchToProps(),
)(InterestsAssessmentComponent);

export default withRouter(InterestsAssessmentContainer);
