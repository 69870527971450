export const toGradeString = (grade) => {
  const gradeInt = parseInt(grade, 10);
  switch (gradeInt) {
    case 5:
      return "Elementary (Grade 1-5)";
    case 6:
      return "Middle School (Grade 6)";
    case 7:
      return "Middle School (Grade 7)";
    case 8:
      return "Middle School (Grade 8)";
    case 9:
      return "High School (Grade 9)";
    case 10:
      return "High School (Grade 10)";
    case 11:
      return "High School (Grade 11)";
    case 12:
      return "High School (Grade 12)";
    //case 13:
      //return "Reserved - Legacy";
    case 14:
      return "High School Graduate";
    case 15:
      return "Some College";
    case 16:
      return "2-year College Degree (AA)";
    case 17:
      return "4-year College Degree (BA,BS)";
    case 18:
      return "Master's Degree (MA, MS)";
    case 19:
      return "Professional Degree (MD, JD, PhD)";
    default:
      return "";
  }
};

export const toGenderString = (gender) => {
  if (gender === 'M') {
    return "Male";
  }
  if (gender === 'F') {
    return "Female";
  }
  return "";
};
