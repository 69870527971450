import {
  saveResponse,
  setKey,
  clearKey,
} from '../../actions/assessmentActions';

export const mapStateToProps = ({
  ipp = 8, // items per page
} = {}) => (
  (state, props) => {
    const {
      assessment: {
        active: {
          testStatus,
          testItems,
        },
      },
      timer: {
        time,
      },
    } = state;

    const {
      match: {
        params: {
          page: pageDumIndex,
        },
      },
    } = props;

    const page = pageDumIndex - 1;
    const items = testItems.slice(page * ipp, page * ipp + ipp);

    const lastPage = testItems.length % ipp > 0
      ? Math.floor(testItems.length / ipp)
      : testItems.length / ipp - 1;

    return {
      isLastPage: lastPage === page,
      testStatus,
      items,
      testItems,
      time,
    };
  }
);

export const mapDispatchToProps = () => (
  (dispatch) => ({
    saveItem: ({
      testStatus,
      testItem,
      response,
      time,
    }) => {
      dispatch(saveResponse({
        assignedProductId: testStatus.assignedProductId,
        testDefinitionId: testStatus.testDefinitionId,
        testItemId: testItem.id,
        choiceId: response.id,
        time,
      }));
    },
    setRenderKey: (render) => {
      dispatch(setKey({
        render,
      }));
    },
    clearRenderKey: () => {
      dispatch(clearKey());
    },
  })
);
