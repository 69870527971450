import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  ScreenReader,
  Colors,
} from '../../components';

import {
  Label,
  Input,
} from './Inputs';

const RadioWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  &:nth-child(odd) {
    background-color: ${Colors.superLightGrey};
  }
`;

const RadioGroup = styled.div`
  margin-left: auto;
  min-width: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: .77rem;
`;

export const InterestsLabel = styled(Label)`
  &:hover {
    background-color: ${Colors.lightBlue};
    opacity: 1;
  }
`;

export const Capital = () => (
  <ScreenReader>
    <FormattedMessage
      id="interets.capital"
      defaultMessage="capital"
    />
  </ScreenReader>
);

export const L = () => (
  <>
    <Capital />
    <FormattedMessage
      id="intrests.capL"
      defaultMessage="L"
    />
  </>
);

export const LowL = () => (
  <>
    <Capital />
    <FormattedMessage
      id="intrests.l"
      defaultMessage="l"
    />
  </>
);

export const LowD = () => (
  <>
    <Capital />
    <FormattedMessage
      id="intrests.d"
      defaultMessage="d"
    />
  </>
);

export const D = () => (
  <>
    <Capital />
    <FormattedMessage
      id="intrests.capD"
      defaultMessage="D"
    />
  </>
);

export const LikeVeryMuch = () => (
  <FormattedMessage
    id="intrests.likeVeryMuch"
    defaultMessage="Like very much"
  />
);

export const LikeMod = () => (
  <FormattedMessage
    id="intrests.likeModerately"
    defaultMessage="like moderately"
  />
);

export const DislikeMod = () => (
  <FormattedMessage
    id="intrests.dislikeModerately"
    defaultMessage="dislike moderately"
  />
);

export const DislikeVeryMuch = () => (
  <FormattedMessage
    id="intrests.dislikeVeryMuch"
    defaultMessage="Dislike very much"
  />
);

export const Radio = ({ testItem, onChange }) => {
  const {
    id,
    item: {
      text,
    },
    userResponse: {
      choice: {
        value: initValue,
      } = {},
    } = {},
  } = testItem;
  const [ value, setValue ] = useState(initValue);
  const buttons = testItem.responses.map((res) => (
    <React.Fragment key={`interests_res_${id}_${res.value}`}>
      <Input
        type="radio"
        data-testid={`question_${id}_${res.value}`}
        id={`question_${id}_${res.value}`}
        name={`question_${id}_res`}
        value={res.value}
        onChange={() => { setValue(res.value); onChange({ testItem, response: res }); }}
        checked={value === res.value}
      />
      <InterestsLabel
        htmlFor={`question_${id}_${res.value}`}
      >
        { res.text === 'L' || res.text === 'D' ? <ScreenReader>Capital</ScreenReader> : '' }
        { res.text }
        <ScreenReader>{ res.alt }</ScreenReader>
      </InterestsLabel>
    </React.Fragment>
  ));

  return (
    <RadioWrapper>
      <QuestionWrapper tabIndex="0">
        <ScreenReader>Question {id}</ScreenReader>
        {text}
      </QuestionWrapper>

      <RadioGroup
        role="radiogroup"
        aria-labelledby={`question_${id}`}
      >
        { buttons }
      </RadioGroup>
    </RadioWrapper>
  );
};

Radio.propTypes = {
  /** Question Id */
  testItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    item: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
      alt: PropTypes.string,
    })),
    userResponse: PropTypes.shape({
      choice: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  }).isRequired,
  /** The change handler. Will receive the passed in response */
  onChange: PropTypes.func.isRequired,
};

export default Radio;
