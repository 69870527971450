import React from 'react';
import styled from 'styled-components';

import {
  Colors,
} from '../../components';

/** These are styled `<input>` and `<label>` elements.
  * Use them to create the standard box input found on the interests and abilities assesments.
  *
  * To achive the coloring, you would need to set the `<input>` as a radio element.
  */
export const Docs = () => (
  <p>Documentation</p>
);

export const Label = styled.label`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: solid 1px ${Colors.grey};
  background-color: ${Colors.white};
  box-shadow: 0 6px 6px -3px ${Colors.lightGrey};

  cursor: pointer;

  font-weight: 400;

  width:1.65rem;
  height:1.65rem;
  max-width: 1.65rem;
  max-height: 1.65rem;

  margin: .5rem .2rem;

  &:hover{
    background-color: ${Colors.lightBlue};
    opacity: .5;
    color:#fff;
  }
`;

export const Input = styled.input`
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;

  &:checked + label {
    background-color: ${(props) => props.color || Colors.blue};
    border-color: ${Colors.grey};
    color: #fff;
  }

  &:focus + label {
    outline: solid;
  }
`;

const ExampleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExampleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin:.1rem;

  font-weight: 400;

  width:1.65rem;
  height: 1.65rem;

  border: solid 1px ${Colors.grey};
`;

const ExampleText = styled.div`
  margin: 0 .5rem;
  display: inline-block;
`;

export const Example = ({ item, text }) => (
  <ExampleContainer>
    <ExampleButton>
      {item}
    </ExampleButton>
    <ExampleText>
      {text}
    </ExampleText>
  </ExampleContainer>
);
