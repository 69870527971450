import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
  ScreenReader,
  HR as Hr,
} from '../../components';

const ItemLabel = styled.label`
  margin: .4em .7em;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`;

const ItemWrapper = styled.div`
  margin: 0em .25em;
  padding: .25em 1em;
`;

const HR = styled(Hr)`
  margin: 1.5em 0em;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: baseline;
`;

export const ValueMessage = () => (
  <FormattedMessage
    id="values.directions3"
    defaultMessage='"I value activities or jobs in which (I) …"'
  />
);

const Input = ({
  testItem,
  onChange,
}) => {
  const {
    id,
    ranking,
    responses,
    userResponse: {
      choice: {
        value: initValue,
      } = {},
    } = {},
  } = testItem;
  const [ value, setValue ] = useState(initValue);

  const inputs = responses.map((res) => (
    <ItemContainer
      key={`values_res_${id}_${res.value}`}
    >
      <input
        type="radio"
        id={`question_${id}_${res.value}`}
        name={`question_${id}_res`}
        value={res.value}
        checked={value === res.value}
        onChange={() => {
          setValue(res.value);
          onChange({ testItem, response: res });
        }}
      />
      <ItemLabel
        htmlFor={`question_${id}_${res.value}`}
      >
        {res.text}
      </ItemLabel>
    </ItemContainer>
  ));

  return (
    <>
      <ScreenReader>
        <label htmlFor={`question_${id}`}>
          Question {ranking}
        </label>
      </ScreenReader>
      <ItemWrapper
        id={`question_${id}`}
        role="radiogroup"
        aria-labelledby={`question_${id}`}
      >
        {inputs}
      </ItemWrapper>
      <HR />
    </>
  );
};

Input.propTypes = {
  /** Question Id */
  testItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })),
    userResponse: PropTypes.shape({
      choice: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  }).isRequired,
  /** The change handler. Will receive the passed in response */
  onChange: PropTypes.func.isRequired,
};

export default Input;
