import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
/*
import PropTypes from 'prop-types';
*/

import logo from '../Succeed-Logo.svg';

import {
  H1,
  CenteredSpinner as Spinner,
  ExamineeMenu,
  ClusterButton,
  ClusterContainer,
  SmallButton as Button,
} from '../../components';

import JobTile from './JobTile';
import ShareArea from './ShareArea';

const JobsContainer = styled.div`
  margin-right: 1.5rem;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const P = styled.p`
  margin: 0;
  padding: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
`;

const Img = styled.img`
  width: 372px;
  height: 372px;
`;

const SucceedComponent = ({
  allFinished,
  activeProduct,
  clusters,
  favoriteJobs,
  loading,
  loadResults,
  unloadResults,
  isPublic,
  settingPublic,
  setPublic,
  setManualDiscover,
  suppressed,
  toggleFavoriteJob,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => {
    loadResults(activeProduct);
    return () => {
      unloadResults();
    };
  }, []);

  if (loading) {
    return (
      <ExamineeMenu>
        <Spinner />
      </ExamineeMenu>
    );
  }

  if (suppressed) {
    return (
      <ExamineeMenu
        id="content"
      >
        <CenteredContainer>
          <H1>Welcome to the Succeed phase</H1>
          <p>
            Your administrator has not released your results. Please contact
            them to receive your results.
          </p>
        </CenteredContainer>
      </ExamineeMenu>
    );
  }

  if (favoriteJobs.length < 3) {
    return (
      <ExamineeMenu
        id="content"
      >
        <CenteredContainer>
          <H1>Welcome to the Succeed phase</H1>
          <p>You must select at least 3 jobs from the Discover phase.</p>
          <Button
            onClick={() => (setManualDiscover())}
            to="/dashboard/examinee/discover"
            style={{ margin: 'auto' }}
          >
            Back
          </Button>
        </CenteredContainer>
      </ExamineeMenu>
    );
  }

  const Jobs = favoriteJobs.slice(0, 3).map((j, i) => (
    <JobTile
      key={`jobTile_${j.jobId}`}
      index={i}
      {...j}
      removeJob={() => {
        setIsOpen(j);
      }}
    />
  ));

  const Clusters = clusters.slice(0, 3).map((clust, i) => (
    <ClusterButton
      key={`cluster_${clust.id}`}
      index={i}
      name={clust.name}
      color={clust.color}
      onClick={() => setManualDiscover()}
    />
  ));

  return (
    <ExamineeMenu
      id="content"
      assigned={activeProduct}
    >
      <H1>Welcome to the Succeed phase</H1>
      <TextContainer>
        <P>
          Here you will see your top three jobs. We encourage you to explore
          job listings in your local area.
        </P>
        <Img src={logo} alt="Succeed Logo" />
      </TextContainer>
      {
        !allFinished && (
          <p>We recommend picking jobs once you have finished all your assessments.</p>
        )
      }
      <JobsContainer>
        {Jobs}
      </JobsContainer>
      <p>
        Below are your top three career clusters. If you wish to explore more
        jobs, feel free to click on any of these clusters.
      </p>
      <ClusterContainer>
        {Clusters}
      </ClusterContainer>
      <ShareArea
        assignedProductId={activeProduct.id}
        settingPublic={settingPublic}
        isPublic={isPublic}
        setPublic={setPublic}
      />
      <Modal
        isOpen={isOpen !== undefined && isOpen !== null && isOpen !== false}
        appElement={document.getElementById('root')}
        parentSelector={() => document.querySelector('#content')}
        style={{
          overlay: {
            display: 'flex',
            flex: '1 1 auto',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            top: '6.5rem',
            display: 'flex',
            flex: '0 1 auto',
            flexDirection: 'column',
            inset: 'unset',
          },
        }}
      >
        <H1>REMOVE JOB</H1>
        <p>Would you like to remove <i>{isOpen.jobTitle}</i> from your favorites?</p>
        <Button
          onClick={() => {
            setIsOpen(false);
            toggleFavoriteJob({ job: isOpen, favoriteJobs });
          }}
          style={{
            alignSelf: 'center',
          }}
        >
          YES
        </Button>
        <Button
          link
          onClick={() => {
            setIsOpen(false);
          }}
        >
          NO
        </Button>
      </Modal>

    </ExamineeMenu>
  );
};

export default SucceedComponent;
