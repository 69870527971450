import { connect } from 'react-redux';

import withRouter from '../../utils/withRouter';
import {
  loadJob,
  toggleFavoriteJob,
  updateFavoriteJob,
} from '../../actions/jobActions';

import JobDetailsComponent from '../components/JobDetails';

const mapStateToProps = (state, props) => {
  const {
    jobs: {
      obj: allJobs,
      favorites: allFavoriteJobs,
    },
  } = state;

  const {
    match: {
      params: {
        id,
      },
    },
  } = props;

  const job = allJobs[id];

  return {
    ...job,
    allFavoriteJobs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadJob: (job) => (dispatch(loadJob(job))),
  toggleFavorite: (params) => (dispatch(toggleFavoriteJob(params))),
  updateFavorite: (params) => (dispatch(updateFavoriteJob(params))),
});

const JobDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetailsComponent);

export default withRouter(JobDetailsContainer);
