import React, { useState } from 'react';
//import PropTypes from 'prop-types';

import FilterForm from './FilterForm';
import Table from './Table';

const FilterTable = ({
  columns = [],
  ...rest
}) => {
  const [ filters, setFilters ] = useState([]);

  return (
    <div>
      <FilterForm
        columns={columns}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        filters={filters}
        columns={columns}
        {...rest}
      />
    </div>
  );
};

export default FilterTable;
