import React from 'react';
import PropTypes from 'prop-types';

import AuthRoute from './AuthRoute';

const PrivateRouteComponent = ({
  children,
  user,
  noScroll,
  ...rest
}) => (
  <AuthRoute
    user={user}
    noScroll={noScroll}
    {...rest}
  >
    {children}
  </AuthRoute>
);

PrivateRouteComponent.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
  children: PropTypes.node,
};

PrivateRouteComponent.defaultProps = {
  user: undefined,
  children: undefined,
};

export default PrivateRouteComponent;
