import http from '../utils/httpCli';

export const QBO_REQUEST = 'QBO_REQUEST';
export const QBO_SUCCESS = 'QBO_SUCCESS';
export const QBO_ERROR = 'QBO_ERROR';

export const initQBO = () => (
  (dispatch) => {
    dispatch({ type: QBO_REQUEST });
    return http.get('/qbo').then(({ data }) => {
      dispatch({ type: QBO_SUCCESS, payload: data });
    }).catch((e) => {
      console.error(e);
      dispatch({ type: QBO_ERROR });
    });
  }
);

export const saveQBO = (params) => (
  (dispatch) => {
    dispatch({ type: QBO_REQUEST });
    return http.put('/qbo', { token: params }).then(({ data }) => {
      dispatch({ type: QBO_SUCCESS, payload: data });
    }).catch((e) => {
      console.error(e);
      dispatch({ type: QBO_ERROR });
    });
  }
);

export const deleteQBO = () => (
  (dispatch) => {
    dispatch({ type: QBO_REQUEST });
    return http.delete('/qbo').then(({ data }) => {
      dispatch({ type: QBO_SUCCESS, payload: data });
    }).catch((e) => {
      console.error(e);
      dispatch({ type: QBO_ERROR });
    });
  }
);
