/* eslint react/forbid-prop-types: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import * as Colors from '../components/Colors';

export const FilterRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  padding: 0rem 2rem;
  & > label {
    flex: 1;
    margin: 0;
    padding: 0;
  }
  & > .filterInput {
    flex: 4;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid ${Colors.grey};
    box-shadow: none;
  }
`;

const Filter = ({
  setFilter,
  column: {
    id,
    accessor,
    Header,
    Label,
  } = {},
}) => {
  const [ value, setValue ] = useState('');
  const ident = id || accessor;
  const name = `filter_${ident}`;
  const debounced = useDebouncedCallback(
    (e) => {
      setFilter(e.target.value || undefined);
    },
    500,
  );
  return (
    <FilterRow key={`row_${name}`}>
      <label key={`label_${name}`} htmlFor={name}>{Label || Header}</label>
      <input
        className="filterInput"
        key={`input_${name}`}
        id={name}
        name={name}
        placeholder=""
        value={value}
        onChange={(e) => {
          setValue(e.target.value || '');
          debounced(e);
        }}
      />
    </FilterRow>
  );
};

Filter.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOf([ PropTypes.number, PropTypes.string ]),
    accessor: PropTypes.any,
    Label: PropTypes.node,
    Header: PropTypes.node,
  }),
  setFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  column: {},
};

export default Filter;
