import { connect } from 'react-redux';
import ValuesAssessmentComponent from '../components/ValuesAssessment';
import { mapStateToProps, mapDispatchToProps } from './Assessment';
import withRouter from '../../utils/withRouter';

const ValuesAssessmentContainer = connect(
  mapStateToProps({ ipp: 7 }),
  mapDispatchToProps(),
)(ValuesAssessmentComponent);

export default withRouter(ValuesAssessmentContainer);
