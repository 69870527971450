export const FATAL_ERROR = 'FATAL_ERROR';

export const broadcastFatalError = (error) => (
  (dispatch) => {
    dispatch({
      type: FATAL_ERROR,
      payload: { error },
    });
  }
);
