import React, { useState } from 'react';
import PropTypes from 'prop-types';

let apiPath = process.env.REACT_APP_API || 'https://api.viasystem.com/v1/';
apiPath = apiPath.replace('/v1/', '');

/** Loads an image from the api server.
 *
 *  * `src` is relative to the `process.env.REACT_APP_API` varibale
 *  * default path is `https://api.viasystem.com/`
 *  * apiPath strips the `/v1/`
 *
 *  Api image can be scaled preserving the aspect ratio
 */
const ApiImage = ({
  src,
  onLoad,
  alt,
  width,
  height,
  zoom,
  ...rest
}) => {
  const [w, setWidth ] = useState(width);
  const [h, setHeight ] = useState(height);
  let path = src;
  if (src[0] === '/') {
    path = `${apiPath}${src}`;
  }
  return (
    <img
      src={path}
      onLoad={(params) => {
        setWidth(params.target.naturalWidth * zoom);
        setHeight(params.target.naturalHeight * zoom);
        onLoad(params);
      }}
      alt={alt}
      width={w}
      height={h}
      {...rest}
    />
  );
};

ApiImage.propTypes = {
  /** Image source path.
  *
  *   - Relative to `REACT_APP_API`.
  *   - default `https://api.viasystem.com/`
  *   - `/v1` is striped from the apiPath.
  */
  src: PropTypes.string.isRequired,
  /** Allows you to be notified when the image loads */
  onLoad: PropTypes.func,
  /** Scales the image preserving the aspect ratio */
  zoom: PropTypes.number,
};

ApiImage.defaultProps = {
  onLoad: () => {},
  zoom: 1,
};

export default ApiImage;
