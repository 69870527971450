import * as Yup from 'yup';

export const PasswordSchema = Yup
  .string()
  .required('Please enter a password')
  .test(
    'spaces',
    'Password cannot contain spaces',
    (password) => (password && !/\s/.test(password)),
  );

export const MatchPasswordSchema = Yup
  .string()
  .oneOf([Yup.ref('password'), null], 'Passwords do not match')
  .required('Please match your passwords');
