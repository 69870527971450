import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import {
  SmallButton as Button,
  Content,
  Colors,
} from '../../components';

const Table = styled.table`
  text-align:center;
  border: 1px solid black;
  background-color: white;
  border-radius: 15px;

  td {
    padding: .5em;
  }

  padding: 1rem;

  margin: auto;
`;

const P = styled.p`
  text-align: center;
`;

const ExamineeChangeProductComponent = ({ products, change }) => {
  const navigate = useNavigate();
  return (
    <Content
      contentStyle={`
        background-color: ${Colors.background};
        justify-content: center;
      `}
    >
      <P>
        You have multiple products assigned to you. Please select the product
        you would like to use.
      </P>
      <Table>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Group</th>
            <th>Assigned</th>
            <th>Last Used</th>
            <td />
          </tr>
        </thead>
        <tbody>
          {
            products.filter((prod) => (
              prod.values !== undefined
              || prod.interests !== undefined
              || prod.abilities !== undefined
            )).map((prod) => (
              <tr key={prod.id}>
                <td>
                  {prod.values !== undefined && (prod.language === 'spanish' ? 'Valors ' : 'Values ')}
                  {
                    prod.interests !== undefined
                      && `${
                        prod.language === 'spanish' ? 'Interés' : 'Interests'
                      }${
                        (prod.professional && (prod.language === 'spanish' ? '-Profesional' : '-Professional')) || ''
                      } `
                  }
                  {prod.abilities !== undefined && (prod.language === 'spanish' ? 'Habilidades ' : 'Abilities ')}
                </td>
                <td>
                  {prod.groupName}
                </td>
                <td>
                  {prod.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                </td>
                <td>
                  {prod.updatedAt.toLocaleString(DateTime.DATE_SHORT)}
                </td>
                <td>
                  <Button onClick={() => { change(prod); navigate('/'); }}>
                    USE
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Content>
  );
};

ExamineeChangeProductComponent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    values: PropTypes.bool,
    interests: PropTypes.bool,
    abilities: PropTypes.bool,
    groupName: PropTypes.string,
    createdAt: momentPropTypes.momentObj,
    updateAt: momentPropTypes.momentObj,
  })).isRequired,
  change: PropTypes.func.isRequired,
};

export default ExamineeChangeProductComponent;
