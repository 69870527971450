import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _, { chain, isEqual } from 'lodash';

import Select from './Select';
import Checkbox from './Checkbox';

export const RECOMMENDED = {
  per_users_view: true,
  per_users_invite: true,
  per_users_move: true,
  per_products_view: true,
  per_products_transfer: false,
  per_products_assign: true,
  per_admin_view: true,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false,
};

export const FULL = {
  per_users_view: true,
  per_users_invite: true,
  per_users_move: true,
  per_products_view: true,
  per_products_transfer: true,
  per_products_assign: true,
  per_admin_view: true,
  per_admin_invite: true,
  per_admin_remove: true,
  per_admin_edit: true,
  per_create: true,
};

export const VIEW = {
  per_users_view: true,
  per_users_invite: false,
  per_users_move: false,
  per_products_view: true,
  per_products_transfer: false,
  per_products_assign: false,
  per_admin_view: true,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false,
};

export const EMPTY = {
  per_users_view: false,
  per_users_invite: false,
  per_users_move: false,
  per_products_view: false,
  per_products_transfer: false,
  per_products_assign: false,
  per_admin_view: false,
  per_admin_invite: false,
  per_admin_remove: false,
  per_admin_edit: false,
  per_create: false,
};

export const VIEW_STRING = 'View';
export const RECOMMENDED_STRING = 'Recommended';
export const FULL_STRING = 'Full';
export const CUSTOM_STRING = 'Custom';
export const EMPTY_STRING = 'Empty';

export const cleanPermissions = (dirtyPer) => (
  _.pickBy(dirtyPer, (val, key) => (
    _.startsWith(key, 'per_')
  ))
);

export const PermissionsToString = (pers) => {
  const inPermission = chain(pers)
    .pickBy((v, k) => (
      (k.indexOf('per_') > -1)
    ))
    .reduce((acc, v, k) => {
      acc[k] = v === 1 || v === true;
      return acc;
    }, {})
    .value();
  if (isEqual(inPermission, FULL)) {
    return FULL_STRING;
  }
  if (isEqual(inPermission, RECOMMENDED)) {
    return RECOMMENDED_STRING;
  }
  if (isEqual(inPermission, VIEW)) {
    return VIEW_STRING;
  }
  if (isEqual(inPermission, EMPTY)) {
    return EMPTY_STRING;
  }
  return CUSTOM_STRING;
};

export const forceValid = (permissions) => {
  const p = { ...permissions };
  if (_.isEqual(p, EMPTY)) {
    return p;
  }
  if (!p.per_users_view && (p.per_users_invite || p.per_users_move || p.per_products_assign)) {
    p.per_users_view = true;
  }
  if (!p.per_products_view && (p.per_products_assign || p.per_products_transfer)) {
    p.per_products_view = true;
  }
  if (!p.per_admin_view && (p.per_admin_invite || p.per_admin_edit || p.per_admin_remove)) {
    p.per_admin_view = true;
  }
  return p;
};

export const isValid = (dirtyP) => {
  const p = cleanPermissions(dirtyP);
  if (!p.per_users_view && (p.per_users_invite || p.per_users_move)) {
    return false;
  }
  if (!p.per_products_view && (p.per_products_assign || p.per_products_transfer)) {
    return false;
  }
  if (!p.per_admin_view && (p.per_admin_invite || p.per_admin_edit || p.per_admin_remove)) {
    return false;
  }
  if (_.isEqual(p, EMPTY)) {
    return false;
  }
  return true;
};

const Label = styled.label`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
`;

const PermissionCheckbox = ({
  label,
  permission,
  value,
  handleChange,
}) => (
  <Label htmlFor={permission}>
    <Checkbox
      type="checkbox"
      id={permission}
      value={value[permission]}
      onChange={handleChange(permission)}
      checked={value[permission]}
    />
    {label}
  </Label>
);

/**
  * A selector for admin permissions
  */
export const PermissionSelector = ({
  permissions: inPermissions,
  onChange,
  disabled,
}) => {
  let initialString = RECOMMENDED_STRING;
  if (inPermissions) {
    initialString = PermissionsToString(inPermissions);
  }
  const [ permissionString, setPermissionString ] = useState(initialString);
  const [ permissions, setPermissions ] = useState(inPermissions || RECOMMENDED);

  // Set the permission based on the dropdown change
  useEffect(() => {
    if (permissionString === RECOMMENDED_STRING) {
      setPermissions(RECOMMENDED);
    } else if (permissionString === FULL_STRING) {
      setPermissions(FULL);
    } else if (permissionString === VIEW_STRING) {
      setPermissions(VIEW);
    }
  }, [permissionString]);

  // Set the dropdown based on the permissions
  // call onChange
  useEffect(() => {
    if (_.isEqual(permissions, RECOMMENDED)) {
      setPermissionString(RECOMMENDED_STRING);
    } else if (_.isEqual(permissions, FULL)) {
      setPermissionString(FULL_STRING);
    } else if (_.isEqual(permissions, VIEW)) {
      setPermissionString(VIEW_STRING);
    } else {
      setPermissionString(CUSTOM_STRING);
    }
    onChange(permissions);
  }, [permissions]);

  const handleChecked = (key) => (e) => {
    if (key === 'per_users_view' && !e.target.checked) {
      permissions.per_users_invite = false;
      permissions.per_users_move = false;
    }
    if (key === 'per_products_view' && !e.target.checked) {
      permissions.per_products_assign = false;
      permissions.per_products_transfer = false;
    }
    if (key === 'per_admin_view' && !e.target.checked) {
      permissions.per_admin_invite = false;
      permissions.per_admin_edit = false;
      permissions.per_admin_remove = false;
    }
    setPermissions(forceValid({
      ...permissions,
      [key]: e.target.checked,
    }));
  };

  return (
    <>
      <Select
        value={permissionString}
        disabled={disabled}
        onChange={(e) => {
          setPermissionString(e.target.value);
        }}
      >
        <option value={FULL_STRING}>{FULL_STRING}</option>
        <option value={RECOMMENDED_STRING}>{RECOMMENDED_STRING}</option>
        <option value={VIEW_STRING}>{VIEW_STRING}</option>
        <option value={CUSTOM_STRING}>{CUSTOM_STRING}</option>
      </Select>
      <Container>
        <div>
          <h4>EXAMINEES</h4>
          <PermissionCheckbox
            label="View Examinees"
            permission="per_users_view"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Invite Examinees"
            permission="per_users_invite"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Move Examinees"
            permission="per_users_move"
            value={permissions}
            handleChange={handleChecked}
          />
        </div>
        <div>
          <h4>PRODUCTS</h4>
          <PermissionCheckbox
            label="View Products"
            permission="per_products_view"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Assign Products"
            permission="per_products_assign"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Transfer Products"
            permission="per_products_transfer"
            value={permissions}
            handleChange={handleChecked}
          />
        </div>
        <div>
          <h4>ADMINISTRATORS</h4>
          <PermissionCheckbox
            label="View Administrators"
            permission="per_admin_view"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Invite Administrators"
            permission="per_admin_invite"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Edit Administrators"
            permission="per_admin_edit"
            value={permissions}
            handleChange={handleChecked}
          />
          <PermissionCheckbox
            label="Remove Administrators"
            permission="per_admin_remove"
            value={permissions}
            handleChange={handleChecked}
          />
        </div>
        <div>
          <h4>GROUPS</h4>
          <PermissionCheckbox
            label="Create New Group"
            permission="per_create"
            value={permissions}
            handleChange={handleChecked}
          />
        </div>
      </Container>
    </>
  );
};

PermissionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    per_users_view: PropTypes.bool,
    per_users_invite: PropTypes.bool,
    per_users_move: PropTypes.bool,
    per_products_view: PropTypes.bool,
    per_products_transfer: PropTypes.bool,
    per_products_assign: PropTypes.bool,
    per_admin_view: PropTypes.bool,
    per_admin_invite: PropTypes.bool,
    per_admin_remove: PropTypes.bool,
    per_admin_edit: PropTypes.bool,
    per_create: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
};

PermissionSelector.defaultProps = {
  disabled: false,
};
