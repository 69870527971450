import Directions from './DirectionsContainer';
import NaDirectionsComponent from '../components/NaDirections';

const NaDirectionsContainer = Directions({
  type: 'abilities',
  subTest: 'NA',
  component: NaDirectionsComponent,
});

export default NaDirectionsContainer;
