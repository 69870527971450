import React from 'react';

import {
  Table,
  Thead,
  Tbody,
} from './Table';

/**
 * display the accuracy of the abilities
 */
const AbilityAccuracy = ({
  abilities,
}) => {
  const content = abilities.ability.map((ability) => (
    <tr key={`accuracy_${ability.abbreviation}`}>
      <td>{ability.name}</td>
      <td>{ability.attempted}</td>
      <td>{ability.rawScore}</td>
      <td>{ability.totalItems}</td>
      <td>{ability.accuracy}%</td>
    </tr>
  ));
  return (
    <Table>
      <Thead>
        <tr>
          <th>Assessment</th>
          <th>Attempted</th>
          <th>Correct</th>
          <th>Total Items</th>
          <th>Accuracy</th>
        </tr>
      </Thead>
      <Tbody>
        {content}
      </Tbody>
    </Table>
  );
};

export default AbilityAccuracy;
